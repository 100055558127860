<template>
  <!-- FORMULIR  KESEHATAN LINGKUNGAN -->
  <div class="bg_main full_h pb-5">
    <header-formulir
      v-bind:date="$date()"
      v-bind:title="'Formulir Kesehatan Lingkungan Plus'"
    />

    <div v-if="$isMobile()">
      <v-container>
        <div class="side_toolbar">
          <div>
            <div class="item_toolbar" @click="setupForm(true)">
              <v-progress-circular
                v-if="loadTempo"
                indeterminate
                color="#00b4cc"
              ></v-progress-circular>
              <v-icon color="#00b4cc" v-else large
                >mdi-content-save-outline</v-icon
              >
            </div>
          </div>
        </div>

        <div class="d-flex justify-center">
          <div style="position: relative">
            <div class="d-flex">
              <v-tabs
                v-if="tabs == 'tabs1'"
                v-model="tabs"
                background-color="transparent"
                class="d-flex fasyankes w-100 m-0"
                hide-slider
              >
                <v-tab
                  href="#tabs1"
                  class="text-capitalize header_tab"
                  active-class="active_tab"
                >
                  <div class="number_active ml-2 mr-2">1</div>
                  <h3 class="title-tabs">Air</h3>
                </v-tab>
              </v-tabs>

              <v-tabs
                v-if="tabs == 'tabs2'"
                v-model="tabs"
                background-color="transparent"
                class="d-flex fasyankes w-100 m-0"
                hide-slider
              >
                <v-tab
                  href="#tabs2"
                  class="text-capitalize header_tab"
                  active-class="active_tab"
                >
                  <div class="number_active ml-2 mr-2">2</div>
                  <h3 class="title-tabs">Sanitasi</h3>
                </v-tab>
              </v-tabs>

              <v-tabs
                v-if="tabs == 'tabs3'"
                v-model="tabs"
                background-color="transparent"
                class="d-flex fasyankes w-100 m-0"
                hide-slider
              >
                <v-tab
                  href="#tabs3"
                  class="text-capitalize header_tab"
                  active-class="active_tab"
                >
                  <div class="number_active ml-2 mr-2">3</div>
                  <h3 class="title-tabs">Pengolahan Limbah Medis</h3>
                </v-tab>
              </v-tabs>

              <v-tabs
                v-if="tabs == 'tabs4'"
                v-model="tabs"
                background-color="transparent"
                class="d-flex fasyankes w-100 m-0"
                hide-slider
              >
                <v-tab
                  href="#tabs4"
                  class="text-capitalize header_tab"
                  active-class="active_tab"
                >
                  <div class="number_active ml-2 mr-2">4</div>
                  <h3 class="title-tabs">Kebersihan Tangan</h3>
                </v-tab>
              </v-tabs>

              <v-tabs
                v-if="tabs == 'tabs5'"
                v-model="tabs"
                background-color="transparent"
                class="d-flex fasyankes w-100 m-0"
                hide-slider
              >
                <v-tab
                  href="#tabs5"
                  class="text-capitalize header_tab"
                  active-class="active_tab"
                >
                  <div class="number_active ml-2 mr-2">5</div>
                  <h3 class="title-tabs">Kebersihan Lingkungan</h3>
                </v-tab>
              </v-tabs>

              <v-tabs
                v-if="tabs == 'tabs6'"
                v-model="tabs"
                background-color="transparent"
                class="d-flex fasyankes w-100 m-0"
                hide-slider
              >
                <v-tab
                  href="#tabs6"
                  class="text-capitalize header_tab"
                  active-class="active_tab"
                >
                  <div class="number_active ml-2 mr-2">6</div>
                  <h3 class="title-tabs">Energi dan Lingkungan</h3>
                </v-tab>
              </v-tabs>

              <v-tabs
                v-if="tabs == 'tabs7'"
                v-model="tabs"
                background-color="transparent"
                class="d-flex fasyankes w-100 m-0"
                hide-slider
              >
                <v-tab
                  href="#tabs7"
                  class="text-capitalize header_tab"
                  active-class="active_tab"
                >
                  <div class="number_active ml-2 mr-2">7</div>
                  <h3 class="title-tabs">Manajemen dan Tenaga Kerja</h3>
                </v-tab>
              </v-tabs>
            </div>

            <!-- content -->

            <v-tabs-items v-model="tabs" style="background: white" class="">
              <!-- Tab 1 -->
              <v-tab-item value="tabs1">
                <v-card class="mh-300 custom_card pa-5" v-if="air">
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Pasokan Air</b>
                    </div>
                    <QuestionCard
                      title="Pasokan Air"
                      description="Pasokan air dari sumber yang layak disalurkan ke
                        Fasyankes atau terletak di tempat"
                      :selected.sync="
                        air.Pasokan_Air[
                          'Pasokan_air_dari_sumber_yang_layak_disalurkan_ke_Fasyankes_atau_terletak_di_tempat'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[0]"
                      tooltip_desc="Peningkatan pasokan air didefinisikan oleh Program
                Pemantauan Bersama WHO/UNICEF yang berdasarkan sifat
                desain dan konstruksinya memiliki potensi untuk
                menghasilkan air yang aman. Contohnya meliputi: air
                perpipaan, keran umum; sumur gali yang terlindungi;
                sumur tabung; atau lubang bor, air hujan dan air
                kemasan atau yang dikirim. Baik hijau atau kuning
                akan dihitung sebagai layanan air dasar, jika air
                juga tersedia, yaitu W_3b kuning atau hijau)."
                      :options="[
                        {
                          label:
                            'Pasokan air dari sumber yang layak dapat diakses di tempat (di dalam gedung Fasyakes)',
                          value: '1',
                        },
                        {
                          label:
                            'Pasokan air dari sumber yang layak dapat diakses di tempat (tetapi di luar gedung Fasyankes)',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada sumber air dari sumber yang layak yang dapat diakses di lokasi',
                          value: '3',
                        },
                      ]"
                    />
                    <QuestionCard
                      title="Pasokan Air"
                      description="Fasyankes ini memiliki persediaan air perpipaan di
                        lokasi"
                      :selected.sync="
                        air.Pasokan_Air[
                          'Fasyankes_ini_memiliki_persediaan_air_perpipaan_di_lokasi'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[1]"
                      :tooltip_desc="`Untuk rumah sakit perawatan sekunder/tersier, air
                            perlu disalurkan ke Fasyankes, minimal ke bangsal
                            dan area layanan berisiko tinggi (misalnya, area
                            dekontaminasi/pemrosesan ulang dan area layanan
                            lingkungan). <br>
                            <br>
                            Di Fasyankes besar, harus ada keran fungsional yang
                            tersedia di area di setiap lantai dan setiap bangsal
                            utama atau sayap Fasyankes, untuk tujuan pembersihan
                            lingkungan. <br>
                            <br>
                            Untuk Fasyankes primer, baik hijau atau kuning akan
                            dihitung sebagai layanan air dasar tetapi untuk
                            Fasyankes sekunder atau tersier yang telah
                            meningkatkan air, tetapi tidak disalurkan, akan
                            berwarna merah. Meskipun ini dihitung sebagai
                            layanan air dasar untuk tujuan pemantauan JMP,
                            Fasyankes sekunder atau lebih tinggi tanpa air
                            perpipaan tidak dianggap memenuhi persyaratan
                            tingkat layanan minimum`"
                      :options="[
                        {
                          label:
                            'Air disalurkan ke dalam Fasyankes ke semua bangsal berisiko tinggi (bersalin, ruang operasi / OR, perawatan intensif / ICU)',
                          value: '1',
                        },
                        {
                          label:
                            'Air disalurkan ke dalam tetapi tidak ke semua bangsal berisiko tinggi',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada pasokan air perpipaan',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Pasokan Air / Leding</b>
                    </div>
                    <QuestionCard
                      title="Pasokan Air / Leding"
                      description="Semua keran terhubung ke pasokan air yang tersedia dan
                        berfungsi, tanpa kebocoran pada pipa"
                      :selected.sync="
                        air.Pasokan_Air_Leding[
                          'Semua_keran_terhubung_ke_pasokan_air_yang_tersedia_dan_berfungsi_tanpa_kebocoran_pada_pipa'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[2]"
                      :tooltip_desc="`Pipa air harus diperiksa secara teratur dan sistem
                            di tempat untuk memperbaiki kebocoran segera setelah
                            ditemukan.<br /><br />
                            Di Fasyankes besar, harus ada keran fungsional yang
                            tersedia di area konsultasi di setiap lantai dan
                            setiap bangsal utama atau sayap Fasyankes, untuk
                            tujuan pembersihan lingkungan.`"
                      :options="[
                        {
                          label: 'Semua keran terhubung dan berfungsi',
                          value: '1',
                        },
                        {
                          label:
                            'Lebih dari setengah dari semua keran terhubung dan berfungsi',
                          value: '2',
                        },
                        {
                          label:
                            'Kurang dari setengah dari semua ketukan terhubung dan berfungsi',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Ketersediaan Air</b>
                    </div>
                    <QuestionCard
                      title="Ketersediaan air"
                      description="Air tersedia selama jam pelayanan Fasyankes"
                      :selected.sync="
                        air.Ketersediaan_Air[
                          'Air_tersedia_selama_jam_pelayanan_Fasyankes'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[3]"
                      :tooltip_desc="`Air harus tersedia di Fasyankes untuk semua hari /
                            jam buka dan memberikan perawatan.`"
                      :options="[
                        {
                          label:
                            'Air tersedia 7 hari seminggu, setiap hari dan sepanjang hari',
                          value: '1',
                        },
                        {
                          label:
                            'Air tersedia 4 hari/minggu dan/atau tidak sepanjang hari',
                          value: '2',
                        },
                        {
                          label:
                            'Air tersedia kurang dari 4 hari per minggu dan/atau tidak tersedia selama lebih dari setengah hari',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Ketersediaan air"
                      description="Air tersedia pada saat penilaian WASH FIT dilakukan"
                      :selected.sync="
                        air.Ketersediaan_Air[
                          'Air_tersedia_pada_saat_penilaian_WASH_FIT_dilakukan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[4]"
                      :tooltip_desc="`Pertanyaan ini sejalan dengan pertanyaan yang direkomendasikan JMP 'Apakah air tersedia dari pasokan air utama'`"
                      :options="[
                        {
                          label: 'Air tersedia di seluruh Fasyankes',
                          value: '1',
                        },
                        {
                          label:
                            'Air tersedia dari beberapa tetapi tidak semua titik air',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada air yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Ketersediaan air"
                      description="Air tersedia sepanjang tahun (yaitu tidak terpengaruh
                        oleh musim, variabilitas cuaca/peristiwa ekstrem atau
                        kendala lainnya)"
                      :selected.sync="
                        air.Ketersediaan_Air[
                          'Air_tersedia_sepanjang_tahun_yaitu_tidak_terpengaruh_oleh_musim_variabilitas_cuaca_peristiwa_ekstrem_atau_kendala_lainnya'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label: 'Air tersedia sepanjang tahun',
                          value: '1',
                        },
                        {
                          label: 'Kekurangan air selama satu hingga dua bulan',
                          value: '2',
                        },
                        {
                          label: 'Kekurangan air selama tiga bulan atau lebih',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Ketersediaan air"
                      description="Sistem pasokan air utama berfungsi selama 3 bulan
                        terakhir tanpa kerusakan besar"
                      :selected.sync="
                        air.Ketersediaan_Air[
                          'Sistem_pasokan_air_utama_berfungsi_selama_3_bulan_terakhir_tanpa_kerusakan_besar'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[6]"
                      :tooltip_desc="`Kerusakan adalah tidak ada pengiriman air ATAU
                            sistem memberikan kurang dari 50% dari hasil air
                            desain.`"
                      :options="[
                        {
                          label:
                            'Dalam 3 bulan terakhir sistem pasokan air utama tidak mengalami kerusakan atau ada kerusakan tetapi diperbaiki dalam waktu 48 jam.',
                          value: '1',
                        },
                        {
                          label:
                            'Sistem pasokan air mengalami kerusakan tetapi diperbaiki dalam waktu satu minggu.',
                          value: '2',
                        },
                        {
                          label:
                            'Sistem pasokan air mengalami kerusakan yang membutuhkan waktu lebih dari satu minggu untuk memperbaiki ATAU tetap tidak diperbaiki',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Ketersediaan air"
                      description="Sumber air layak tambahan diidentifikasi, tersedia, dan
                        dapat diakses (dan pelihara secara memadai) jika sumber
                        utama tidak berfungsi/tersedia"
                      :selected.sync="
                        air.Ketersediaan_Air[
                          'Sumber_air_layak_tambahan_diidentifikasi_tersedia_dan_dapat_diakses_dan_pelihara_secara_memadai_jika_sumber_utama_tidak_berfungsi_tersedia'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[7]"
                      :tooltip_desc="`Pengolahan air mungkin diperlukan untuk persediaan
                            cadangan karena mungkin memiliki arsenik tinggi atau
                            masalah serupa.`"
                      :options="[
                        {
                          label:
                            'Sumber air layak tambahan diidentifikasi, tersedia, dan dapat diakses',
                          value: '1',
                        },
                        {
                          label:
                            'Sumber air tambahan diidentifikasi tetapi tidak layak atau layak tetapi tidak mudah diakses',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada sumber air tambahan yang tersedia ',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Ketersediaan air"
                      description="Memiliki air dalam jumlah yang cukup untuk semua
                        penggunaan"
                      :selected.sync="
                        air.Ketersediaan_Air[
                          'Memiliki_air_dalam_jumlah_yang_cukup_untuk_semua_penggunaan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[8]"
                      :tooltip_desc="`Kebutuhan air akan bervariasi tergantung pada jenis
                            Fasyankes dan jumlah pasien.<br /><br />
                            Untuk menghitung kebutuhan air Fasyankes, tambahkan
                            persyaratan berikut atau standar nasional yang
                            berlaku (perhatikan bahwa persyaratan dapat
                            bervariasi pada bulan yang berbeda dalam setahun
                            sehingga mendasarkan perhitungan pada bulan yang
                            membutuhkan air paling banyak): Pasien rawat jalan
                            (5 L/konsultasi) + rawat inap (40–60 L/pasien/hari)
                            + ruang operasi atau unit bersalin (100
                            L/intervensi) + pusat pemberian makanan kering atau
                            tambahan (0,5–5 L/konsultasi tergantung waktu
                            tunggu) + pusat perawatan kolera (60 L/pasien/hari).
                            Sumber: Standar lingkungan esensial dalam perawatan
                            kesehatan (WHO, 2008).<br /><br />

                            Jumlah ini memperhitungkan air yang diperlukan untuk
                            minum, pembersihan lingkungan dan binatu, kebersihan
                            tangan dan pengelolaan limbah. Catatan, air untuk
                            penggunaan medis tertentu (seperti dialisis) tidak
                            termasuk.`"
                      :options="[
                        {
                          label:
                            'Memiliki air dalam jumlah yang cukup untuk semua penggunaan di seluruh Fasyankes',
                          value: '1',
                        },
                        {
                          label:
                            'Jumlah air cukup untuk 75% kebutuhan (di semua bangsal dan penggunaan)',
                          value: '2',
                        },
                        {
                          label: 'Jumlah air kurang dari 75% cukup',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      header_title="Ketersediaan Air"
                      title="Ketersediaan Air"
                      description="Fasyankes ini memiliki tangki untuk menyimpan air jika
                        terjadi gangguan pada pasokan utama, dan tangki
                        penyimpanan air dilindungi (misalnya dari peristiwa
                        cuaca ekstrem terkait iklim) dan dikelola secara memadai
                        (misalnya diperiksa, dibersihkan / didesinfeksi secara
                        teratur), dan cukup untuk memenuhi kebutuhan Fasyankes
                        selama 2 hari"
                      :selected.sync="
                        air.Ketersediaan_Air[
                          'Fasyankes_ini_memiliki_tangki_untuk_menyimpan_air_jika_terjadi_gangguan_pada_pasokan_utama'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[9]"
                      :tooltip_desc="`Penyimpanan air harus dilindungi dari kontaminasi
                            dan mampu menahan peristiwa cuaca ekstrem.<br /><br />

                            Untuk menghitung kebutuhan penyimpanan air
                            Fasyankes, hitung persyaratan (indikator W_7) yang
                            dibutuhkan selama 24 jam dan kalikan dengan 2 untuk
                            mendapatkan total selama 48 jam. <br /><br />

                            Penyimpanan cadangan tambahan harus disediakan
                            selama periode berisiko tinggi. Jika memungkinkan,
                            penyimpanan lebih dari 2 hari harus disediakan dan
                            air diprioritaskan untuk layanan
                            penting/menyelamatkan jiwa (yaitu ruang bersalin
                            bangsal perawatan akut).`"
                      :options="[
                        {
                          label:
                            'Air memiliki jumlah yang cukup untuk semua penggunaan di seluruh Fasyankes',
                          value: '1',
                        },
                        {
                          label:
                            'Cukup untuk dua hari tetapi tidak dilindungi atau dilindungi tetapi hanya cukup untuk satu hari',
                          value: '2',
                        },
                        {
                          label:
                            'Penyimpanan tersedia kurang dari satu hari yang dibutuhkan atau tidak ada yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Ketersediaan Air"
                      description="[Di mana curah hujan cukup dan teratur] Sistem pemanenan
                        air hujan (dengan penyimpanan yang aman) berfungsi dan
                        menyimpan air dengan aman"
                      :selected.sync="
                        air.Ketersediaan_Air[
                          'Sistem_pemanenan_air_hujan__dengan_penyimpanan_yang_aman__berfungsi_dan_menyimpan_air_dengan_aman'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[10]"
                      :tooltip_desc="`Selokan dan atap yang digunakan untuk resapan air
                            hujan dan tangki penyimpanan harus dibersihkan
                            secara teratur setidaknya setiap bulan atau sesuai
                            kebutuhan selama badai besar dan curah hujan. Sistem
                            juga harus menggunakan perangkat flush pertama yang
                            dirancang untuk mengalihkan bagian pertama dari air
                            hujan yang terkontaminasi sehingga tidak masuk ke
                            tangki penyimpanan dan kotak filter.`"
                      :options="[
                        {
                          label:
                            'Pemanenan air hujan dengan penyimpanan yang aman ada dan fungsional',
                          value: '1',
                        },
                        {
                          label:
                            'Sistem pemanenan air hujan ada tetapi penyimpanan tidak aman atau cukup atau ada kebocoran',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada pemanenan air hujan yang digunakan (meskipun air hujan tersedia)',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Konservasi Air</b>
                    </div>
                    <QuestionCard
                      title="Konservasi Air"
                      description="Strategi penghematan air digunakan untuk mengurangi
                        pemborosan air."
                      :selected.sync="
                        air.Konservasi_Air[
                          'Strategi_penghematan_air_digunakan_untuk_mengurangi_pemborosan_air'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[11]"
                      :tooltip_desc="`Strategi pengurangan air termasuk penggunaan
                            wastafel efisiensi tinggi dan aliran air rendah
                            untuk mencuci tangan, mesin cuci rendah air (dan
                            strategi lain untuk mencuci dan membersihkan),
                            memastikan pipa dan perlengkapan tidak bocor (dan
                            menggunakan sistem untuk melaporkan dan memperbaiki
                            keran yang bocor pada hari yang sama), memeriksa
                            meter untuk menganalisis penggunaan air, dan
                            menggunakan greywater dan / atau air hujan jika
                            tersedia untuk menyiram toilet, membersihkan area
                            trotoar luar ruangan, tanaman air, dll.<br /><br />

                            Dalam kasus peristiwa iklim atau keadaan darurat,
                            tindakan tambahan mungkin diperlukan, misalnya air
                            diprioritaskan untuk layanan penting/menyelamatkan
                            jiwa (yaitu ruang bersalin, bangsal perawatan akut);
                            Pengguna prioritas diidentifikasi jika terjadi
                            kekurangan (pengguna prioritas mungkin termasuk ibu
                            bersalin, anak kecil, orang tua atau kurang gizi).`"
                      :options="[
                        {
                          label:
                            'Strategi penghematan air digunakan secara efektif dan pemborosan air dihindari',
                          value: '1',
                        },
                        {
                          label:
                            'Strategi pengurangan air digunakan tetapi masih ada beberapa pemborosan air yang dapat dihindari',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada strategi pengurangan air yang digunakan',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Air Minum</b>
                    </div>
                    <QuestionCard
                      title="Air Minum"
                      description="[Di mana desinfeksi klorin dilakukan] Air minum memiliki
                        residu klorin bebas yang sesuai (≥0,2 mg/L atau ≥0,5
                        mg/L dalam keadaan darurat)"
                      :selected.sync="
                        air.Air_Minum[
                          'Air_minum_memiliki_residu_klorin_bebas_yang_sesuai'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[12]"
                      :tooltip_desc="`Air minum harus memenuhi Pedoman WHO untuk Kualitas
                            Air Minum (2017) atau standar nasional:
                            https://www.who.int/water_sanitation_health/publications/drinking-water-quality-guidelines-4-including-1st-addendum/en/
                            Residu klorin harus sering diukur dan dosisnya
                            disesuaikan jika residu tidak terpenuhi (perubahan
                            pH, suhu, kandungan organik dan sumber air akan
                            mempengaruhi kemanjuran klorin). Bukti residu klorin
                            yang terdokumentasi harus tersedia dari pengujian
                            sebelumnya. Jika terjadi banjir, klorin saja tidak
                            akan mendisinfeksi air secukupnya karena airnya
                            kemungkinan terlalu keruh. Untuk desinfeksi yang
                            efektif, harus ada konsentrasi sisa klorin bebas ≥
                            0,5 mg / l setelah setidaknya 30 menit waktu kontak
                            pada pH < 8,0. Residu klorin harus dipertahankan di
                            seluruh sistem distribusi. Pada titik pengiriman,
                            konsentrasi residu minimum klorin bebas harus ≥0,2
                            mg / l. Penggunaan air yang aman (menurut pedoman
                            kualitas air minum WHO, yaitu, tidak ada E. yang
                            dapat dideteksi dalam 100 mL dan konsentrasi residu
                            klorin bebas ≥0,5 mg/l setelah setidaknya 30 menit
                            waktu kontak pada pH < 8,0) meminimalkan risiko
                            paparan patogen terkait air yang berasal dari
                            enterik dan lingkungan (misalnya, Pseudomonas,
                            Legionella) dan harus tersedia untuk semua layanan
                            klinis; minimal, itu harus disediakan untuk bangsal
                            berisiko tinggi di mana beban HAI dan AMR tinggi dan
                            di semua area ruang bersalin.`"
                      :options="[
                        {
                          label:
                            'Air minum tersedia dengan bebas sisa klorin yang sesuai',
                          value: '1',
                        },
                        {
                          label: 'Residu klorin bebas ada, tetapi <0,2mg / L',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak mengetahui residu/tidak memiliki kapasitas untuk menguji residu/tidak ada air minum yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Air Minum"
                      description="Pasokan air menimbulkan risiko rendah atau tidak
                        beresiko sama sekali terhadap kesehatan masyarakat,
                        sebagaimana diukur dengan tidak adanya Ecoli per 100 mL
                        dan/atau yang diukur dengan skor risiko inspeksi
                        sanitasi."
                      :selected.sync="
                        air.Air_Minum[
                          'Pasokan_air_menimbulkan_risiko_rendah_atau_tidak_beresiko_sama_sekali_terhadap_kesehatan_masyarakat'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[13]"
                      :tooltip_desc="`Drinking-water should meet WHO Guidelines for
                            Drinking-water Quality (2017) or national standards:
                            https://www.who.int/water_sanitation_health/publications/drinking-water-quality-guidelines-4-including-1st-addendum/en/
                            If the E. Coli reading is anything above 10/100ml,
                            you should re-test. <br /><br />

                            WASH FIT includes Sanitary Inspection (SI) forms for
                            4 technologies: tubewell with hand pump, deep
                            borehole with motorized pump, piped distribution and
                            storage, rain water harvesting. Select one or more
                            SI forms according to the facility's water
                            supply(ies).`"
                      :options="[
                        {
                          label:
                            'Air minum bebas dari Ecoli ATAU risiko rendah menurut form inspeksi sanitasi ',
                          value: '1',
                        },
                        {
                          label:
                            'Air minum memiliki E.≤10 /100ml ATAU risiko sedang sesuai dengan bentuk SI',
                          value: '2',
                        },
                        {
                          label:
                            'E.lebih dari >10/100ml /Tidak tahu apakah E.hadir /tidak memiliki kapasitas untuk menguji /tidak ada air minum yang tersedia ATAU berisiko tinggi menurut bentuk SI',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Kualitas Air</b>
                    </div>
                    <QuestionCard
                      title="Kualitas Air"
                      description="Air perpipaan diolah dengan pengelolaan air yang aman
                        oleh penyedia layanan/otoritas kota atau air diolah
                        secara teratur di tempat"
                      :selected.sync="
                        air.Kualitas_Air[
                          'Air_perpipaan_diolah_dengan_pengelolaan_air_yang_aman_oleh_penyedia_layanan_otoritas_kota_atau_air_diolah_secara_teratur_di_tempat'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[14]"
                      :tooltip_desc="`Drinking-water should meet WHO Guidelines for
                            Drinking-water Quality (2017) or national standards:
                            https://www.who.int/water_sanitation_health/publications/drinking-water-quality-guidelines-4-including-1st-addendum/en/
                            If the E. Coli reading is anything above 10/100ml,
                            you should re-test. <br /><br />

                            WASH FIT includes Sanitary Inspection (SI) forms for
                            4 technologies: tubewell with hand pump, deep
                            borehole with motorized pump, piped distribution and
                            storage, rain water harvesting. Select one or more
                            SI forms according to the facility's water
                            supply(ies).`"
                      :options="[
                        {
                          label:
                            'Air diolah dan diatur melalui layanan perpipaan atau air diolah secara teratur dengan teknologi yang telah terbukti handal',
                          value: '1',
                        },
                        {
                          label:
                            'Air diolah dengan teknologi yang terbukti tetapi tidak secara teratur',
                          value: '2',
                        },
                        {
                          label:
                            'Air tidak diolah atau diolah dengan teknologi yang tidak memenuhi standar WHO',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Kualitas Air"
                      description="Kualitas air dari semua pasokan air (persediaan primer,
                        cadangan dan tambahan) secara rutin diuji oleh staf /
                        dan atau otoritas independen (misalnya lembaga
                        pengawasan) sesuai dengan standar nasional"
                      :selected.sync="
                        air.Kualitas_Air[
                          'Kualitas_air_dari_semua_pasokan_air__persediaan_primer_cadangan_dan_tambahan__secara_rutin_diuji_oleh_staf'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[15]"
                      :tooltip_desc="`Routine water quality testing should be carried
                            out. Verification may be carried out by a member of
                            staff from the facility or an external body.
                            Drinking water should meet WHO Guidelines for
                            Drinking-water Quality (2017) or national standards:
                            https://www.who.int/publications/i/item/9789241549950`"
                      :options="[
                        {
                          label:
                            'Water quality routinely and regularly tested according to national standards',
                          value: '1',
                        },
                        {
                          label:
                            'Water quality tested but not routinely or regularly',
                          value: '2',
                        },
                        {
                          label:
                            'No testing takes place or no national standards exist',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Air Minum</b>
                    </div>
                    <QuestionCard
                      title="Air Minum"
                      description="Dispenser ( station) air minum dengan air minum yang
                        aman tersedia dan berfungsi setiap saat di ruang tunggu
                        utama dan / atau pintu masuk ke setiap bangsal dan di
                        semua kamar tempat pasien menginap atau menerima
                        perawatan"
                      :selected.sync="
                        air.Air_Minum[
                          'Dispenser_air_minum_dengan_air_minum_yang_aman_tersedia_dan_berfungsi_setiap_saat_di_ruang_tunggu_utama'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[16]"
                      :tooltip_desc="`Air minum yang aman, sebagaimana didefinisikan oleh
                            Pedoman WHO, tidak mewakili risiko signifikan
                            terhadap kesehatan selama konsumsi seumur hidup,
                            termasuk sensitivitas berbeda yang mungkin terjadi
                            di antara tahap kehidupan.<br /><br />

                            Tidak adanya kontaminasi tinja dapat ditunjukkan
                            dengan tidak adanya organisme indikator seperti E.
                            (atau coliform feses termotoleran). Jika ini tidak
                            dapat diuji, keberadaan residu klorin bebas
                            (setidaknya 0,2 mg/l) dapat digunakan sebagai
                            indikasi pengganti.<br /><br />

                            Air minum harus disimpan dengan aman dalam
                            ember/tangki bersih dengan penutup dan keran, yang
                            secara teratur dibersihkan dan didesinfeksi (kecuali
                            jika dikeluarkan dari air mancur). Air minum harus
                            dapat diakses oleh staf, pasien, dan pengasuh.<br />
                            Dalam kasus peningkatan cepat dalam pencari
                            perawatan (misalnya karena peristiwa terkait iklim),
                            stasiun pengisian staf lebih teratur, lebih banyak
                            air diperoleh, atau opsi lain (seperti perawatan
                            in-line untuk air perpipaan) digunakan. <br />
                            Air minum juga harus dapat diakses, sebagai berikut
                            <br />
                            - Jalur menuju area air minum: lebar minimal 120 cm,
                            datar dan rata, kering dan bersih dari rintangan.<br />
                            - Tanda untuk stasiun air minum memiliki kata-kata,
                            gambar dan braille dan dipajang di dinding pada
                            jarak 140 cm-160 cm dari tanah.<br />
                            - Keran stasiun air minum berjarak 75 cm dari lantai
                            dan cangkir tersedia untuk pasien. Jika cangkir
                            digunakan, mereka harus dapat digunakan kembali dan
                            dicuci dengan air hangat dan sabun dan dikeringkan.`"
                      :options="[
                        {
                          label:
                            'Air minum tersedia di semua lokasi, setiap saat',
                          value: '1',
                        },
                        {
                          label:
                            'Air minum tersedia tetapi hanya di beberapa tempat, hanya kadang-kadang',
                          value: '2',
                        },
                        {
                          label: 'Air minum tidak tersedia',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Shower</b>
                    </div>
                    <QuestionCard
                      title="Shower"
                      description="[Fasyankes dengan layanan rawat inap] Setidaknya satu
                        pancuran atau area pemandian tersedia per 40 pasien
                        rawat inap atau per bangsal (mana yang lebih rendah) dan
                        berfungsi serta dapat diakses"
                      :selected.sync="
                        air.Shower[
                          'Setidaknya_satu_pancuran_atau_area_pemandian_tersedia_per_40_pasien_rawat_inap_atau_per_bangsal__mana_yang_lebih_rendah__dan_berfungsi_serta_dapat_diakses'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[17]"
                      :tooltip_desc="`Air minum yang aman, sebagaimana didefinisikan oleh
                            Pedoman WHO, tidak mewakili risiko signifikan
                            terhadap kesehatan selama konsumsi seumur hidup,
                            termasuk sensitivitas berbeda yang mungkin terjadi
                            di antara tahap kehidupan.<br /><br />

                            Tidak adanya kontaminasi tinja dapat ditunjukkan
                            dengan tidak adanya organisme indikator seperti E.
                            (atau coliform feses termotoleran). Jika ini tidak
                            dapat diuji, keberadaan residu klorin bebas
                            (setidaknya 0,2 mg/l) dapat digunakan sebagai
                            indikasi pengganti.<br /><br />

                            Air minum harus disimpan dengan aman dalam
                            ember/tangki bersih dengan penutup dan keran, yang
                            secara teratur dibersihkan dan didesinfeksi (kecuali
                            jika dikeluarkan dari air mancur). Air minum harus
                            dapat diakses oleh staf, pasien, dan pengasuh.<br />
                            Dalam kasus peningkatan cepat dalam pencari
                            perawatan (misalnya karena peristiwa terkait iklim),
                            stasiun pengisian staf lebih teratur, lebih banyak
                            air diperoleh, atau opsi lain (seperti perawatan
                            in-line untuk air perpipaan) digunakan. <br />
                            Air minum juga harus dapat diakses, sebagai berikut
                            <br />
                            - Jalur menuju area air minum: lebar minimal 120 cm,
                            datar dan rata, kering dan bersih dari rintangan.<br />
                            - Tanda untuk stasiun air minum memiliki kata-kata,
                            gambar dan braille dan dipajang di dinding pada
                            jarak 140 cm-160 cm dari tanah.<br />
                            - Keran stasiun air minum berjarak 75 cm dari lantai
                            dan cangkir tersedia untuk pasien. Jika cangkir
                            digunakan, mereka harus dapat digunakan kembali dan
                            dicuci dengan air hangat dan sabun dan dikeringkan.`"
                      :options="[
                        {
                          label:
                            'Kamar mandi tersedia per bangsal atau per 40 pasien dan fungsional serta dapat diakses',
                          value: '1',
                        },
                        {
                          label:
                            'Kamar mandi tersedia, tetapi kurang dari 1 pancuran yang berfungsi dan dapat diakses per 40 pasien / per bangsal',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada pancuran yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Shower"
                      description="Kamar mandi berfungsi untuk wanita yang privasi dan
                        dapat dikunci tersedia di area persalinan dan persalinan"
                      :selected.sync="
                        air.Shower[
                          'Kamar_mandi_berfungsi_untuk_wanita_yang_privasi_dan_dapat_dikunci_tersedia_di_area_persalinan_dan_persalinan'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Ya, pancuran fungsional atau ruang untuk mencuci tersedia di area persalinan dan persalinan',
                          value: '1',
                        },
                        {
                          label:
                            'Shower/tempat cuci fungsional tersedia tetapi tidak di area persalinan dan pengiriman, atau di area yang benar tetapi tidak berfungsi',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada shower / tempat cuci yang tersedia untuk wanita',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>
                </v-card>
              </v-tab-item>

              <!-- Tab 2 -->
              <v-tab-item value="tabs2">
                <v-card class="mh-300 custom_card pa-5" v-if="toilet">
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Toilet</b>
                    </div>
                    <QuestionCard
                      title="Toilet"
                      description="Fasyakes memiliki jumlah toilet yang cukup untuk pasien"
                      :selected.sync="
                        toilet.Toilet[
                          'Fasyakes_memiliki_jumlah_toilet_yang_cukup_untuk_pasien'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[18]"
                      :tooltip_desc="`Fasilitas sanitasi yang ditingkatkan</strong
                            >: termasuk toilet siram ke saluran pembuangan atau
                            septic tank yang dikelola dan lubang perendaman,
                            jamban VIP, jamban lubang dengan toilet lempengan
                            dan pengomposan.<br /><br />

                            <strong>Nomor</strong>: Lebih banyak jamban mungkin
                            diperlukan tergantung pada ukuran fasilitas. Untuk
                            fasilitas yang lebih besar dengan beberapa bangsal,
                            di mana dua toilet rawat jalan tidak mencukupi,
                            disarankan (jika memungkinkan) bahwa setiap
                            departemen rawat jalan memiliki dua toilet. Toilet
                            juga dapat digunakan oleh penjaga, pengasuh, dan
                            pengunjung. Jumlah pengunjung yang besar menambah
                            tuntutan pada infrastruktur sanitasi dan pembersihan
                            dan ini harus dipertimbangkan.`"
                      :options="[
                        {
                          label:
                            'Dua atau lebih toilet untuk pasien rawat jalan ditambah satu per 20 pengguna/rawat inap.',
                          value: '1',
                        },
                        {
                          label:
                            'Jumlah toilet dipenuhi untuk pasien rawat jalan atau rawat inap, tetapi tidak keduanya.',
                          value: '2',
                        },
                        {
                          label:
                            'Baik rawat inap maupun rawat jalan tidak memiliki jumlah toilet yang cukup atau toilet yang ada tidak diperbaiki',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Toilet"
                      description="Semua toilet pasien tersedia dan dapat digunakan"
                      :selected.sync="
                        toilet.Toilet[
                          'Semua_toilet_pasien_tersedia_dan_dapat_digunakan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[19]"
                      :tooltip_desc="`<strong>Tersedia dan dapat digunakan</strong>:
                            toilet / jamban harus memiliki pintu yang tidak
                            terkunci saat tidak digunakan (atau yang kuncinya
                            tersedia kapan saja) dan dapat dikunci dari dalam
                            selama penggunaan, tidak boleh ada lubang besar
                            dalam struktur, lubang atau lubang tidak boleh
                            diblokir, air harus tersedia untuk toilet flush /
                            pour flush dan tidak boleh ada retakan, atau
                            kebocoran pada struktur toilet. Itu harus berada di
                            dalam halaman fasilitas dan harus bersih seperti
                            yang dicatat dengan tidak adanya limbah, kotoran dan
                            kotoran yang terlihat dan serangga.<br /><br />

                            <strong
                              >Kriteria tambahan yang dapat digunakan</strong
                            >
                            untuk tingkat layanan yang lebih tinggi: <br />
                            Toilet harus <br />
                            • Terletak di area fasilitas yang kurang rawan
                            banjir, erosi, dll.<br />
                            • Secara teratur diperiksa untuk kerusakan<br />
                            • Dibersihkan lebih teratur ketika pengguna
                            meningkat <br />
                            • Diangkat dan/atau merupakan toilet tertutup
                            sementara yang dapat dikosongkan secara teratur
                            <br />
                            • Lubang dikosongkan secara teratur<br />
                            • Kotoran diolah dengan aman di tempat (misalnya
                            melalui tangki septik/bidang pelindian yang
                            berfungsi) atau dibuang ke sistem saluran pembuangan
                            yang berfungsi, atau diangkut dengan aman ke luar
                            lokasi ke area perawatan terpusat.<br />
                            • Toilet dengan lubang terbuka atau soakaway harus
                            terletak setidaknya 30m dari sumber air dan minimal
                            1,5m di atas meja air. Lihat catatan di bawah S_8
                            Penahanan. <br />
                            • Di daerah yang rawan air atau banjir, toilet
                            efisiensi tinggi (desain toilet rendah atau tanpa
                            air misalnya toilet kering konversi urin) harus
                            digunakan.<br />`"
                      :options="[
                        {
                          label:
                            'Semua toilet pasien tersedia dan dapat digunakan',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa tetapi tidak semua toilet pasien tersedia dan dapat digunakan',
                          value: '2',
                        },
                        {
                          label:
                            'Beberapa tetapi tidak semua toilet pasien tersedia dan tidak dapat digunakan',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Toilet"
                      description="Semua toilet memiliki tempat cuci tangan yang berfungsi
                        dalam jarak 5 meter"
                      :selected.sync="
                        toilet.Toilet[
                          'Semua_toilet_memiliki_tempat_cuci_tangan_yang_berfungsi_dalam_jarak_5_meter'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[20]"
                      :tooltip_desc="`<strong
                              >Functioning hand hygiene station at
                              toilets</strong
                            >: must include soap and water. Alcohol-based hand
                            rub is not sufficient to remove faecal matter from
                            hands.<br />
                            This indicator is used to calculate basic hand
                            hygiene but is included in the sanitation section
                            for ease of data collection flow`"
                      :options="[
                        {
                          label:
                            'Semua toilet memiliki tempat cuci tangan fungsional dalam jarak 5 meter',
                          value: '1',
                        },
                        {
                          label:
                            'At least 50% of toilets have functioning hand washing stations within 5 metres ',
                          value: '2',
                        },
                        {
                          label:
                            'Fewer than 50% of toilets have functioning hand washing stations within 5 metres',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Toilet"
                      description="Setidaknya satu toilet yang layak tersedia untuk staf,
                        dan toilet dipisahkan atau diberi label dengan jelas"
                      :selected.sync="
                        toilet.Toilet[
                          'Setidaknya_satu_toilet_yang_layak_tersedia_untuk_staf_dan_toilet_dipisahkan_atau_diberi_label_dengan_jelas'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[21]"
                      :tooltip_desc="`Toilet terpisah yang ditingkatkan untuk staf
                            diperlukan untuk memenuhi persyaratan sanitasi
                            dasar, sebagaimana diukur oleh JMP.
                          `"
                      :options="[
                        {
                          label:
                            'Setidaknya ada satu toilet berfungsi untuk digunakan staf dan dipisahkan / diberi label dengan jelas ',
                          value: '1',
                        },
                        {
                          label:
                            'Toilet ada untuk digunakan staf, tetapi toilet tidak dipisahkan / diberi label dengan jelas atau tidak berfungsi ',
                          value: '2',
                        },
                        {
                          label:
                            'No separate toilet exists for staff use, or toilets are unimproved',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Toilet"
                      description="Toilet yang layak dipisahkan / diberi label dengan jelas
                        untuk pria, wanita yang memberikan privasi"
                      :selected.sync="
                        toilet.Toilet[
                          'Toilet_yang_layak_dipisahkan_diberi_label_dengan_jelas_untuk_pria_wanita_yang_memberikan_privasi'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Toilet terpisah untuk pengguna pria / wanita ada dan diberi label dengan jelas (dan memberikan privasi bagi pengguna)',
                          value: '1',
                        },
                        {
                          label:
                            'Toilet terpisah ada tetapi tidak diberi label dengan jelas',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada toilet terpisah dan tidak ada privasi di toilet lain atau toilet yang tidak disetujui',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Toilet"
                      description="Setidaknya satu toilet yang dapat digunakan untuk
                        memenuhi kebutuhan manajemen kebersihan menstruasi"
                      :selected.sync="
                        toilet.Toilet[
                          'Setidaknya_satu_toilet_yang_dapat_digunakan_untuk_memenuhi_kebutuhan_manajemen_kebersihan_menstruasi'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[23]"
                      :tooltip_desc="`Toilet harus memiliki tempat sampah untuk
                            pembuangan limbah atau area untuk mencuci, dengan
                            air yang tersedia. Idealnya, pembalut wanita harus
                            tersedia di fasilitas atau di dekatnya untuk wanita
                            menstruasi dan pasca-kelahiran untuk dibeli.
                          `"
                      :options="[
                        {
                          label:
                            'Satu atau lebih toilet yang dapat digunakan melayani MKM',
                          value: '1',
                        },
                        {
                          label:
                            'Ada ruang bagi wanita untuk mencuci tetapi tidak ada air yang tersedia, toilet tidak bersih / rusak atau tempat sampah untuk pembuangan limbah tersedia tetapi penuh',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada fasilitas MKM yang tersedia atau fasilitas yang tersedia tetapi toilet tidak dapat digunakan atau toilet tidak dapat digunakan',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Toilet"
                      description="Setidaknya satu toilet fungsional yang ditingkatkan
                        memenuhi kebutuhan orang-orang dengan mobilitas terbatas"
                      :selected.sync="
                        toilet.Toilet[
                          'Setidaknya_satu_toilet_fungsional_yang_ditingkatkan_memenuhi_kebutuhan_orang_orang_dengan_mobilitas_terbatas'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[24]"
                      :tooltip_desc="`Toilet dapat dipertimbangkan untuk memenuhi
                            kebutuhan orang-orang dengan mobilitas yang
                            berkurang jika memenuhi kondisi berikut: dapat
                            diakses tanpa tangga atau tangga, pegangan tangan
                            untuk dukungan terpasang baik ke lantai atau dinding
                            samping, pintunya setidaknya selebar 80 cm, toilet
                            memiliki kursi yang ditinggikan (antara 40-48 cm
                            dari lantai), sandaran dan bilik memiliki ruang
                            untuk sirkulasi/ manuver (150 x 150 cm). Wastafel,
                            keran, dan air di luar juga harus dapat diakses dan
                            bagian atas wastafel 75 cm dari lantai (dengan jarak
                            selutut). Sakelar untuk lampu, jika relevan, juga
                            harus berada pada ketinggian yang dapat diakses
                            (maks 120 cm). Semua spesifikasi didasarkan pada ISO
                            21542: 2011 (Konstruksi bangunan - Aksesibilitas dan
                            kegunaan lingkungan binaan) tersedia di:
                            http://www.iso.org/iso/home/store/catalogue_tc/catalogue_detail.htm?csnumber=50498
                            Tempat cuci tangan di toilet ini juga harus dapat
                            diakses, sesuai dengan kriteria berikut: keran harus
                            memiliki pegangan tipe tuas, wastafel memiliki rel
                            pegangan di kedua sisi, dan sabun (atau gosok tangan
                            berbasis alkohol) dan tisu mudah dijangkau.
                            Ketinggian wastafel adalah 75 cm untuk pembersihan
                            lutut.
                          `"
                      :options="[
                        {
                          label:
                            'Satu atau lebih toilet fungsional memenuhi kebutuhan orang-orang dengan mobilitas terbatas',
                          value: '1',
                        },
                        {
                          label:
                            'Toilet memenuhi kebutuhan orang dengan mobilitas yang berkurang tetapi tidak fungsional atau toilet berfungsi tetapi hanya sebagian memenuhi kebutuhan orang dengan mobilitas yang berkurang',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada toilet untuk pengguna difabel atau toilet yang tidak disetujui',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Faecal sludge management</b>
                    </div>
                    <QuestionCard
                      title="Faecal sludge management"
                      :description="`Lumpur tinja sepenuhnya terkandung untuk kemudian
                        pengosongan dan perawatan di luar lokasi atau sepenuhnya
                        terkandung dan dirawat di tempat.<br>
                         Limbah cair disimpan sepenuhnya atau mengalir ke tanah
                        dari dasar wadah, atau melalui bidang leach, lubang
                        rendam atau saluran air tertutup, atau disimpan dengan
                        aman.`"
                      :selected.sync="
                        toilet.Faecal_Sludge_Management[
                          'Lumpur_tinja_sepenuhnya_terkandung_untuk_kemudian_pengosongan_dan_perawatan_di_luar_lokasi_atau_sepenuhnya_terkandung_dan_dirawat_di_tempat'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[25]"
                      :tooltip_desc="`Air limbah harus dikelola dengan aman melalui
                            penggunaan pengolahan di tempat (yaitu septic tank
                            diikuti oleh lubang drainase) atau dikirim ke sistem
                            saluran pembuangan yang berfungsi. Lihat Pedoman WHO
                            tentang Sanitasi dan Kesehatan Bab 3.3 dan formulir
                            Inspeksi Sanitasi WHO untuk sistem sanitasi di
                            tempat. Penahanan yang aman memastikan produk yang
                            dihasilkan dari toilet disimpan dalam teknologi
                            penahanan dan/atau dibuang ke lingkungan setempat
                            dengan cara yang tidak membuat siapa pun terpapar
                            bahaya atau mencemari sumber air. <br /><br />

                            Penahanan mengacu pada wadah, biasanya terletak di
                            bawah permukaan tanah, di mana toilet terhubung /
                            berada. Ini termasuk kontainer yang dirancang
                            untuk:<br />
                            • penahanan, penyimpanan, dan pengolahan lumpur
                            tinja dan limbah (misalnya septic tank, jamban
                            kering dan basah, toilet pengomposan, kubah
                            dehidrasi, tangki penyimpanan urin, dll.); atau
                            <br />
                            • penahanan dan penyimpanan (tanpa pengolahan)
                            lumpur tinja dan air limbah (misalnya tangki
                            berlapis penuh, sanitasi berbasis wadah).<br /><br />

                            Di mana lindi dari teknologi permeabel atau limbah
                            dari teknologi kedap air larut ke dalam struktur
                            bawah tanah, ada risiko bahwa air tanah dan air
                            permukaan di dekatnya dapat tercemar, berpotensi
                            mencemari sumber air lokal yang digunakan untuk
                            minum dan tugas-tugas rumah tangga. Sebagai aturan
                            umum dan tanpa penilaian risiko yang lebih rinci
                            untuk air tanah, <br />
                            • bagian bawah wadah permeabel dan lubang rendam
                            atau ladang pelindian harus tidak kurang dari 1,5 m
                            hingga 2,0 m di atas permukaan air pada tingkat
                            tertinggi sepanjang tahun, • wadah permeabel dan
                            bidang pelindian harus terletak di bawah gradien,
                            dan <br />
                            • Setidaknya 10 m jarak horizontal dari sumber air
                            minum.
                          `"
                      :options="[
                        {
                          label:
                            'Inspeksi visual wadah dan drainase menunjukkan integritas struktural, tidak ada kebocoran atau kerusakan, tidak ada kolam yang terlihat atau bau yang kuat yang menunjukkan kebocoran ke area lokal. Operator melaporkan tidak ada kebocoran dalam kondisi cuaca basah dan kering.',
                          value: '1',
                        },
                        {
                          label:
                            'Tidak dapat menentukan penahanan dari inspeksi visual dan/atau operator melaporkan kebocoran musiman.',
                          value: '2',
                        },
                        {
                          label:
                            'Inspeksi dan respons operator menunjukkan kerusakan pada wadah, ponding, pembuangan limbah cair ke saluran terbuka atau tanah terbuka.',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Wastewater management</b>
                    </div>
                    <QuestionCard
                      title="Wastewater management"
                      description="Toilets are connected without leaks to a public sewer
                        system. The sewer conveys excreta and wastewater with no
                        leaks/overflows to treatment. [Sewered systems]"
                      :selected.sync="
                        toilet.Wastewater_Management[
                          'Toilets_are_connected_without_leaks_to_a_public_sewer_system'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[26]"
                      :tooltip_desc="`Alat angkut yang aman membatasi paparan pekerja
                            yang melakukan operasi dan pemeliharaan, masyarakat
                            yang tinggal dan bekerja di sekitar pekerjaan, dan
                            masyarakat luas yang masing-masing dapat terpapar
                            patogen melalui konsumsi dan inhalasi patogen feses.
                            Lihat Pedoman WHO tentang Sanitasi dan Kesehatan Bab
                            3.4<br /><br />

                            Sistem berbasis saluran pembuangan terdiri dari
                            jaringan pipa bawah tanah. Jenis saluran pembuangan
                            meliputi:<br />
                            • Selokan gravitasi konvensional: Bawa air hitam
                            dari toilet dan greywater bersama dengan, di banyak
                            kasus, limbah industri dan air badai melalui pipa
                            berdiameter besar ke fasilitas pengolahan,
                            menggunakan gravitasi (dan pompa bila perlu)<br />
                            • Selokan yang disederhanakan: desain berbiaya lebih
                            rendah yang dipasang menggunakan pipa yang lebih
                            kecil pada kedalaman yang lebih rendah dan lebih
                            dangkal gradien dari selokan gravitasi
                            konvensional.<br />
                            • Selokan bebas padatan: desain serupa dengan
                            saluran pembuangan yang disederhanakan tetapi
                            termasuk pra-perawatan lumpur untuk hapus
                            padatan.
                          `"
                      :options="[
                        {
                          label:
                            'Denah bangunan dan laporan operator mengonfirmasi toilet fasilitas terhubung ke selokan. Tidak ada laporan luapan di lahan fasilitas atau di masyarakat setempat',
                          value: '1',
                        },
                        {
                          label: 'Tidak dapat menentukan',
                          value: '2',
                        },
                        {
                          label:
                            'Laporan seringnya terjadi kebocoran dengan alasan fasilitas dari operator utilitas saluran pembuangan sering mengalami kebocoran / luapan di masyarakat',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Wastewater management"
                      description="Lumpur tinja dari penampungan secara berkala dikosongkan
                        tanpa tumpahan oleh personel terlatih dengan peralatan
                        pelindung yang sesuai dan a) dikeluarkan di luar lokasi
                        untuk perawatan atau b) dibuang dengan aman dengan
                        mengubur di tempat [Tidak berlaku untuk lubang yang
                        tertutup dan ditutup saat penuh. Pergi ke S_10a]"
                      :selected.sync="
                        toilet.Wastewater_Management[
                          'Lumpur_tinja_dari_penampungan_secara_berkala_dikosongkan_tanpa_tumpahan_oleh_personel_terlatih_dengan_peralatan_pelindung_yang_sesuai'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[27]"
                      :tooltip_desc="`Alat angkut yang aman membatasi paparan pekerja
                            yang melakukan operasi dan pemeliharaan, masyarakat
                            yang tinggal dan bekerja di sekitar pekerjaan, dan
                            masyarakat luas yang masing-masing dapat terpapar
                            patogen melalui konsumsi dan inhalasi patogen feses.
                            Lihat Pedoman WHO tentang Sanitasi dan Kesehatan Bab
                            3.4. Lihat Pedoman WHO tentang Sanitasi dan
                            Kesehatan Bab 3.4. <br /><br />

                            Pengosongan dan pengangkutan manual dan bermotor
                            mengacu pada berbagai cara di mana lumpur tinja
                            dapat dikeluarkan dari lokasi fasilitas. <br />
                            Pengosongan lubang, brankas, dan tangki secara
                            manual dapat dilakukan dengan salah satu dari dua
                            cara; baik menggunakan ember dan sekop; atau
                            menggunakan pompa lumpur portabel yang dioperasikan
                            secara manual (meskipun ini mungkin mekanis, masih
                            memerlukan penanganan manual/fisik). <br />
                            Pengosongan manual dan bermotor dapat membawa risiko
                            kemungkinan kontak dengan bahan feses dan dalam
                            beberapa kasus pengosongan bermotor perlu
                            dikombinasikan dengan pengosongan manual untuk
                            menghilangkan bahan terpadat. Beberapa teknologi
                            penahanan hanya dapat dikosongkan secara manual.
                            Teknologi ini paling sering dikosongkan dengan sekop
                            karena bahannya padat dan tidak dapat dilepas dengan
                            ruang hampa udara atau pompa. Lumpur tinja yang
                            dikosongkan dikumpulkan dalam tong atau tas atau
                            dimasukkan ke dalam gerobak dan diangkut menjauh
                            dari lokasi.<br /><br />

                            Pengosongan dan pengangkutan bermotor (juga dikenal
                            sebagai pengosongan dan pengangkutan mekanis)
                            mengacu pada penggunaan kendaraan atau perangkat apa
                            pun yang dilengkapi dengan pompa bermotor dan tangki
                            penyimpanan untuk mengosongkan dan mengangkut lumpur
                            tinja. Orang-orang diharuskan untuk mengoperasikan
                            pompa dan manuver selang, tetapi lumpur tinja tidak
                            diangkat atau diangkut secara manual. Sistem basah
                            seperti septic tank dan fully lined tank biasanya
                            dikosongkan menggunakan pengosongan dan pengangkutan
                            bermotor.
                          `"
                      :options="[
                        {
                          label:
                            'Kontainer telah dikosongkan dalam 5 tahun terakhir (atau sesuai dengan frekuensi pengosongan yang dijadwalkan) oleh personel terlatih dengan peralatan pelindung yang sesuai dan a) dipindahkan ke luar lokasi untuk perawatan b) lumpur tinja dibuang dengan aman dengan mengubur di tempat',
                          value: '1',
                        },
                        {
                          label:
                            'Tidak dapat menentukan frekuensi pengosongan atau keamanan pembuangan',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak pernah dikosongkan atau diketahui pembuangan yang tidak aman tanpa perawatan di lingkungan setempat (misalnya di sungai atau di pertanian)',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Wastewater management</b>
                    </div>
                    <QuestionCard
                      title="Wastewater management"
                      description="Instalasi pengolahan air limbah yang dirancang dengan
                        baik dan dikelola dengan baik, dengan catatan operasi
                        yang dapat diakses publik, menyediakan setidaknya
                        pengolahan sekunder dan memenuhi standar kinerja"
                      :selected.sync="
                        toilet.Wastewater_Management[
                          'Instalasi_pengolahan_air_limbah_yang_dirancang_dengan_baik_dan_dikelola_dengan_baik'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[28]"
                      :tooltip_desc="`Desain dan pengoperasian fasilitas perawatan
                            dilakukan sesuai dengan kriteria desainnya dan
                            sesuai dengan tujuan penggunaan/pembuangan akhir
                            lokal.
                            <strong
                              >Lihat Pedoman WHO tentang Sanitasi dan Kesehatan
                              Bab 3.5</strong
                            >
                          `"
                      :options="[
                        {
                          label:
                            'IPAL yang dirancang dengan baik dengan catatan yang tersedia untuk umum yang menunjukkan bahwa IPAL tersebut memenuhi standar kinerja perawatan lokal/nasional',
                          value: '1',
                        },
                        {
                          label:
                            'IPAL yang berfungsi ada. Kinerja tidak jelas atau tidak sesuai standar',
                          value: '2',
                        },
                        {
                          label: 'IPAL tidak berfungsi atau tidak ada',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Faecal sludge management</b>
                    </div>
                    <QuestionCard
                      title="Faecal sludge management"
                      description="Pabrik pengolahan lumpur tinja yang dirancang dengan
                        baik dan dikelola dengan baik, dengan catatan operasi
                        yang tersedia untuk umum, digunakan dan memenuhi standar
                        kinerja"
                      :selected.sync="
                        toilet.Faecal_Sludge_Management[
                          'Pabrik_pengolahan_lumpur_tinja_yang_dirancang_dengan_baik_dan_dikelola_dengan_baik_dengan_catatan_operasi_yang_tersedia_untuk_umum_digunakan_dan_memenuhi_standar_kinerja'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[29]"
                      :tooltip_desc="`Desain dan pengoperasian fasilitas perawatan
                            dilakukan sesuai dengan kriteria desainnya dan
                            sesuai dengan tujuan penggunaan/pembuangan akhir
                            lokal.
                            <strong
                              >Lihat Pedoman WHO tentang Sanitasi dan Kesehatan
                              Bab 3.5</strong
                            >
                          `"
                      :options="[
                        {
                          label:
                            'Well designed and managed FSTP with publicly available record showing it meets local/national  treatment performance standards',
                          value: '1',
                        },
                        {
                          label: 'Functioning FSTP exists. Performance unclear',
                          value: '2',
                        },
                        {
                          label: 'FSTP is non-functioning or non-existent',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Stormwater management</b>
                    </div>
                    <QuestionCard
                      title="Stormwater management"
                      description="Sistem drainase air hujan (yaitu air hujan) dan
                        greywater tersedia yang mengalihkan air dari fasilitas
                        ke drainase yang aman atau area ladang larut"
                      :selected.sync="
                        toilet.Stormwater_Management[
                          'Sistem_drainase_air_hujan__yaitu_air_hujan__dan_greywater_tersedia_yang_mengalihkan_air_dari_fasilitas_ke_drainase_yang_aman_atau_area_ladang_larut'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[30]"
                      :tooltip_desc="`Mengurangi genangan air juga penting untuk
                            pengendalian vektor. Air harus mengalir jauh dari
                            area publik. Tidak ada kebocoran dari pipa atau
                            lubang perendaman, perendaman lebih dari 30 m dari
                            sumber air, dengan perangkap minyak dan tidak ada
                            genangan air yang terlihat. Wastafel utilitas atau
                            saluran pembuangan (yaitu bukan wastafel yang
                            digunakan untuk kebersihan tangan) harus tersedia di
                            dalam fasilitas di area layanan pembersihan
                            lingkungan dan area pintu air yang ditunjuk. Saluran
                            air harus mengarah ke sistem air limbah di tempat
                            (misalnya sistem perendaman) atau ke sistem saluran
                            pembuangan yang berfungsi
                          `"
                      :options="[
                        {
                          label:
                            'Sistem drainase ada, fungsional (tidak tersumbat) dan berhasil mengalihkan air dari fasilitas ke area penyaringan alami yang aman (misalnya tidak langsung ke rumah tangga atau area komunitas',
                          value: '1',
                        },
                        {
                          label:
                            'Sistem drainase di tempat tetapi tidak cukup untuk volume air limbah atau tersumbat',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada sistem drainase di tempat',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Stormwater management"
                      description="Greywater dan/atau stormwater ditangkap dan digunakan
                        kembali untuk mencuci, membersihkan, menyiram tanaman,
                        dan menyiram toilet"
                      :selected.sync="
                        toilet.Stormwater_Management[
                          'Greywater_dan_atau_stormwater_ditangkap_dan_digunakan_kembali_untuk_mencuci_membersihkan_menyiram_tanaman_dan_menyiram_toilet'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[31]"
                      :tooltip_desc="`Penggunaan kembali greywater dan/atau air badai
                            mungkin berguna sebagai bagian dari konservasi air.
                            Air tersebut tidak perlu diolah untuk digunakan
                            kembali selama tidak dicampur dengan air
                            hitam/limbah dan tidak ada potensi risiko infeksi
                            atau infiltrasi lainnya.
                          `"
                      :options="[
                        {
                          label:
                            'Sistem untuk penangkapan dan penggunaan kembali air limbah domestik dan air badai sudah ada dan beroperasi',
                          value: '1',
                        },
                        {
                          label:
                            'Sistem untuk menangkap air limbah domestikr / air badai tersedia tetapi tidak digunakan secara maksimal',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada sistem untuk menangkap Air limbah domestik atau air badai',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Greywater management</b>
                    </div>
                    <QuestionCard
                      title="Greywater management"
                      description="[Hanya jika ada sistem greywater] Greywater dari
                        wastafel dan fasilitas binatu ditangkap dengan aman dan
                        diarahkan ke saluran pembuangan, ladang pelarut, lubang
                        rendam atau saluran air tertutup tanpa koneksi silang
                        dengan pasokan air minum."
                      :selected.sync="
                        toilet.Greywater_Management[
                          'Greywater_dari_wastafel_dan_fasilitas_binatu_ditangkap_dengan_aman_dan_diarahkan_ke_saluran_pembuangan_ladang_pelarut_lubang_rendam_atau_saluran_air_tertutup_tanpa_koneksi_silang_dengan_pasokan_air_minum'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[32]"
                      :tooltip_desc="`Greywater juga dapat digunakan kembali untuk
                            menyiram tanaman dan pembilasan toilet.
                          `"
                      :options="[
                        {
                          label:
                            'Air limbah domestik ditangkap dengan aman dan memiliki pipa ledeng terpisah',
                          value: '1',
                        },
                        {
                          label:
                            'Sistem Greywater menangkap air tetapi beberapa risiko kontaminasi melalui koneksi silang',
                          value: '2',
                        },
                        {
                          label: 'Sistem air limbah domestik tidak berfungsi',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>
                </v-card>
              </v-tab-item>

              <!-- Tab 3 -->
              <v-tab-item value="tabs3">
                <v-card class="mh-300 custom_card pa-5" v-if="limbah_medis">
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Segregation</b>
                    </div>
                    <QuestionCard
                      title="Segregation"
                      description="Wadah pengumpulan limbah fungsional tersedia di dekat
                        semua titik timbulan limbah untuk limbah non-infeksius
                        (umum), limbah infeksius, dan limbah tajam"
                      :selected.sync="
                        limbah_medis.Segregation[
                          'Wadah_pengumpulan_limbah_fungsional_tersedia_di_dekat_semua_titik_timbulan_limbah_untuk_limbah_non_infeksius_umum_limbah_infeksius_dan_limbah_tajam'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[33]"
                      :tooltip_desc="`Fungsional berarti harus ada setidaknya tiga wadah
                            ('sistem 3-bin') yang tidak lebih dari tiga perempat
                            penuh, yang anti bocor dengan penutup dan semuanya
                            diberi label dengan jelas (yaitu mudah dibedakan
                            menurut warna, label atau simbol). Bin liner /
                            kantong untuk limbah infeksius direkomendasikan jika
                            sumber daya memungkinkan. Tempat sampah tambahan
                            yang tidak menular mungkin diperlukan untuk dengan
                            mudah memisahkan limbah untuk didaur ulang.
                            <br /><br />

                            Titik timbulan sampah adalah di mana saja perawatan
                            diberikan di mana limbah dihasilkan dari memberikan
                            perawatan atau kegiatan medis lainnya. <br /><br />

                            Jika memungkinkan, benda tajam/kotak pengaman kardus
                            digunakan sebagai pengganti kotak plastik, untuk
                            mengurangi emisi berbahaya saat dibakar (terutama di
                            mana pembakaran suhu rendah digunakan).
                          `"
                      :options="[
                        {
                          label:
                            'Wadah pengumpulan limbah fungsional untuk memisahkan limbah ada di semua titik timbulan limbah',
                          value: '1',
                        },
                        {
                          label:
                            'Tempat sampah fungsional di beberapa tetapi tidak semua titik timbulan limbah',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada tempat sampah atau pembuangan benda tajam terpisah',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Segregation"
                      description="Sampah dipilah dengan benar di semua titik timbulan
                        sampah"
                      :selected.sync="
                        limbah_medis.Segregation[
                          'Sampah_dipilah_dengan_benar_di_semua_titik_timbulan_sampah'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[34]"
                      :tooltip_desc="`Setidaknya tiga tempat sampah berlabel jelas atau
                            berkode warna harus ada untuk memisahkan (1) limbah
                            benda tajam18, (2) limbah infeksius19, dan (3)
                            limbah umum yang tidak menular. Tempat sampah tidak
                            boleh lebih dari tiga perempat (75%) penuh, dan
                            setiap tempat sampah tidak boleh mengandung limbah
                            selain yang sesuai dengan labelnya. Tempat sampah
                            harus sesuai dengan jenis sampah yang akan
                            dikandungnya; Wadah Sharps harus tahan tusukan dan
                            yang lainnya harus anti bocor. Tempat sampah untuk
                            limbah benda tajam dan limbah infeksius harus
                            memiliki tutup. Area konsultasi adalah ruangan atau
                            area di dalam fasilitas perawatan kesehatan tempat
                            perawatan atau perawatan diberikan. Verifikasi bahwa
                            semua tempat sampah di fasilitas memiliki limbah
                            yang benar di dalamnya.
                          `"
                      :options="[
                        {
                          label:
                            'Waste is correctly segregated Sampah dipisahkan dengan benar di semua titik timbulan sampah',
                          value: '1',
                        },
                        {
                          label:
                            'Lebih dari 75% tempat sampah memiliki limbah yang benar',
                          value: '2',
                        },
                        {
                          label:
                            'Kurang dari 75% tempat sampah digunakan untuk limbah yang benar',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Segregation"
                      description="Pengingat untuk pemilahan sampah yang benar terlihat
                        jelas di semua titik timbulan sampah"
                      :selected.sync="
                        limbah_medis.Segregation[
                          'Pengingat_untuk_pemilahan_sampah_yang_benar_terlihat_jelas_di_semua_titik_timbulan_sampah'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Pengingat terlihat jelas di semua titik timbulan sampah',
                          value: '1',
                        },
                        {
                          label:
                            'Pengingat tersedia di beberapa tetapi tidak semua titik timbulan limbah',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada pengingat yang tersedia',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== contain 1 -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Personnel</b>
                    </div>
                    <QuestionCard
                      title="Personnel"
                      description="Peralatan dan sumber daya pelindung yang sesuai untuk
                        melakukan kebersihan tangan tersedia untuk semua staf
                        yang bertanggung jawab untuk menangani limbah, dan
                        bertanggung jawab atas pengolahan dan pembuangan limbah"
                      :selected.sync="
                        limbah_medis.Personnel[
                          'Peralatan_dan_sumber_daya_pelindung_yang_sesuai_untuk_melakukan_kebersihan_tangan_tersedia_untuk_semua_staf_yang_bertanggung_jawab_untuk_menangani_limbah_dan_bertanggung_jawab_atas_pengolahan_dan_pembuangan_limbah'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[35]"
                      :tooltip_desc="`Protective equipment for people handling waste
                            management includes: face mask, thick gloves, long
                            sleeved shirt, apron, glasses/goggles and tough
                            rubber boots. <br />
                            Products (water and soap or alcohol hand rub) for
                            hand hygiene should also be available.
                          `"
                      :options="[
                        {
                          label:
                            'Sumber daya untuk kebersihan tangan dan peralatan pelindung tersedia',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa peralatan tersedia, tetapi tidak untuk semua staf, atau tersedia tetapi rusak',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada peralatan yang tersedia untuk staf',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Waste reduction</b>
                    </div>
                    <QuestionCard
                      title="Waste reduction"
                      description="Pengingat dan pelatihan tersedia untuk mempromosikan dan
                        memantau penggunaan alat pelindung diri (APD) secara
                        rasional (misalnya sarung tangan yang hanya digunakan
                        saat ditunjukkan)"
                      :selected.sync="
                        limbah_medis.Waste_Reduction[
                          'Pengingat_dan_pelatihan_tersedia_untuk_mempromosikan_dan_memantau_penggunaan_alat_pelindung_diri__APD__secara_rasional'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[36]"
                      :tooltip_desc="`Penggunaan dan penyalahgunaan APD yang berlebihan
                            dapat berkontribusi pada penyebaran organisme yang
                            berpotensi patogen, terutama dengan tidak adanya
                            kebersihan tangan. Mencegah dan mengurangi jumlah
                            limbah yang dihasilkan, melalui penggunaan APD yang
                            aman dan rasional, adalah salah satu cara paling
                            efektif untuk mengelola dan mengurangi dampak
                            manusia dan lingkungan. Mengirim sampah ke TPA harus
                            menjadi pilihan terakhir. Lihat piramida sarung
                            tangan:
                            https://www.who.int/gpsc/5may/Glove_Use_Information_Leaflet.pdf
                          `"
                      :options="[
                        {
                          label:
                            'Pengingat dan pelatihan di tempat dan APD digunakan secara rasional',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa pengingat atau pelatihan di tempat tetapi lebih banyak yang dapat dilakukan untuk mengurangi penggunaan APD',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada pengingat atau pelatihan di tempat',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Waste reduction"
                      description="Strategi untuk mengurangi jumlah limbah yang dihasilkan
                        digunakan di seluruh fasilitas, termasuk pengadaan
                        barang menggunakan lebih sedikit kemasan dan kemasan
                        yang lebih berkelanjutan"
                      :selected="
                        limbah_medis.Waste_Reduction[
                          'Strategi_untuk_mengurangi_jumlah_limbah_yang_dihasilkan_digunakan_di_seluruh_fasilitas_termasuk_pengadaan_barang_menggunakan_lebih_sedikit_kemasan_dan_kemasan_yang_lebih_berkelanjutan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[37]"
                      :tooltip_desc="`Contoh pengurangan limbah (mengatasi limbah 'di
                            sumber') termasuk manajemen penyimpanan (kedaluwarsa
                            pertama, pertama keluar), pengadaan produk dengan
                            kemasan lebih sedikit atau kemasan yang dapat didaur
                            ulang, atau mendorong penggunaan obat tablet sebagai
                            alternatif injeksi untuk mengurangi limbah benda
                            tajam. Langkah lain adalah penggunaan APD yang aman
                            dan rasional, termasuk celemek, masker, dan
                            coverall. Semua APD yang digunakan kembali harus
                            dicuci dan didesinfeksi dengan benar sesuai dengan
                            standar internasional untuk mencegah penularan
                            infeksi.
                          `"
                      :options="[
                        {
                          label:
                            'Strategi ada dan diterapkan secara konsisten di seluruh fasilitas',
                          value: '1',
                        },
                        {
                          label:
                            'Strategi ada tetapi tidak diterapkan secara konsisten atau efektif',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada strategi yang ada',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Waste reduction"
                      description="[Tidak berlaku jika tidak ada daur ulang lokal yang
                        tersedia] Limbah tidak berbahaya yang dapat didaur ulang
                        dipisahkan dan dikirim ke pabrik daur ulang kota"
                      :selected.sync="
                        limbah_medis.Waste_Reduction[
                          'Limbah_tidak_berbahaya_yang_dapat_didaur_ulang_dipisahkan_dan_dikirim_ke_pabrik_daur_ulang_kota'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[38]"
                      :tooltip_desc="`Barang-barang umum yang dihasilkan di fasilitas
                            perawatan kesehatan yang dapat didaur ulang termasuk
                            kertas, kardus, dan plastik. Bahan yang tidak dapat
                            didaur ulang adalah makanan, logam, kaca, tekstil,
                            dan kayu yang dibuang.
                          `"
                      :options="[
                        {
                          label:
                            'Ada sistem di mana barang yang dapat didaur ulang disortir dan dikirim ke pabrik daur ulang',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa daur ulang terjadi tetapi sistem dapat ditingkatkan (misalnya pemisahan yang lebih baik, jumlah daur ulang yang lebih besar)',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada daur ulang yang terjadi',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Storage</b>
                    </div>
                    <QuestionCard
                      title="Storage"
                      description="Tersedia area penyimpanan limbah khusus yang dipagari
                        dan aman, dan dengan kapasitas yang cukup, di mana
                        limbah tajam, infeksius, dan tidak menular disimpan
                        secara terpisah"
                      :selected.sync="
                        limbah_medis.Storage[
                          'Tersedia_area_penyimpanan_limbah_khusus_yang_dipagari_dan_aman_dan_dengan_kapasitas_yang_cukup_di_mana_limbah_tajam_infeksius_dan_tidak_menular_disimpan_secara_terpisah'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[39]"
                      :tooltip_desc="`Dalam kasus banjir, limbah harus disimpan dalam
                            wadah yang ditinggikan dan / atau diangkut ke luar
                            lokasi.<br />
                            Penyimpanan tambahan juga tersedia ketika limbah
                            tambahan dihasilkan selama peristiwa iklim dan /atau
                            keadaan darurat.<br /><br />

                            Area berpagar harus dilindungi dari banjir, berjajar
                            dan tertutup. Tidak ada limbah perawatan kesehatan
                            yang tidak terlindungi yang terlihat.
                          `"
                      :options="[
                        {
                          label:
                            'Tersedia area penyimpanan limbah khusus dan berpagar, dengan kapasitas yang cukup dan limbah yang disimpan secara terpisah',
                          value: '1',
                        },
                        {
                          label:
                            'Area penyimpanan limbah khusus tersedia tetapi tidak dipagari atau aman atau tidak cukup kapasitas atau semua limbah dikelompokkan bersama',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada area limbah khusus yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Storage"
                      description="Limbah infeksius disimpan tidak lebih lama dari batas
                        aman (sebagaimana ditentukan oleh iklim) sebelum
                        pengolahan/pembuangan"
                      :selected.sync="
                        limbah_medis.Storage[
                          'Limbah_infeksius_disimpan_tidak_lebih_lama_dari_batas_aman__sebagaimana_ditentukan_oleh_iklim__sebelum_pengolahan_pembuangan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[40]"
                      :tooltip_desc="`Unless a refrigerated storage room is available,
                            storage times (e.g. the time between generation and
                            treatment) should not exceed the following periods
                            for infectious waste:<br />
                            • Temperate climate: 72 hours in winter / 48 hours
                            in summer.<br />
                            • Warm climate: 48 hours during the cool season / 24
                            hours during the hot season.
                          `"
                      :options="[
                        {
                          label:
                            'Limbah infeksius disimpan tidak lebih lama dari batas waktu aman',
                          value: '1',
                        },
                        {
                          label:
                            'Limbah infeksius disimpan di luar batas waktu yang aman, tetapi kurang dari sebulan',
                          value: '2',
                        },
                        {
                          label:
                            'Limbah tidak disimpan dengan aman atau disimpan tetapi lebih dari sebulan',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Treatment</b>
                    </div>

                    <QuestionCard
                      title="Treatment"
                      description="Teknologi pengolahan limbah (incinerator atau teknologi
                        pengolahan alternatif) untuk pengolahan limbah infeksius
                        dan tajam dibangun dengan standar yang sesuai,
                        terpelihara dengan baik, fungsional dan memiliki
                        kapasitas yang cukup untuk limbah yang dihasilkan"
                      :selected.sync="
                        limbah_medis.Treatment[
                          'Teknologi_pengolahan_limbah__incinerator_atau_teknologi_pengolahan_alternatif__untuk_pengolahan_limbah_infeksius_dan_tajam_dibangun_dengan_standar_yang_sesuai_terpelihara_dengan_baik_fungsional_dan_memiliki_kapasitas_yang_cukup_untuk_limbah_yang_dihasilkan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[41]"
                      :tooltip_desc="`Untuk teknologi pengobatan alternatif (misalnya
                            autoklaf) pasokan air yang andal harus dijamin. Agar
                            dianggap fungsional, idealnya tidak ada kerusakan
                            besar dalam 6 bulan terakhir, dan masalah apa pun
                            seharusnya diperbaiki dalam waktu satu minggu.
                            <br />
                            Insinerator fungsional harus mencapai suhu minimum
                            yang diperlukan untuk pembakaran lengkap, emisi
                            dievakuasi dengan benar, segel pintu, tidak ada
                            akumulasi residu limbah di dalamnya, dll.
                            <br /><br />

                            Upaya dilakukan untuk meningkatkan efisiensi dan
                            keamanan insinerator, termasuk mencapai suhu yang
                            lebih tinggi, dengan tujuan memenuhi konvensi
                            Stockholm (lihat
                            http://chm.pops.int/Implementation/BATandBEP/BATBEPGuidelinesArticle5/tabid/187/Default.aspx
                            untuk informasi lebih lanjut). <br />
                            Insinerator (jika dirancang untuk limbah infeksius
                            dan bukan hanya limbah umum) harus mengikuti
                            persyaratan desain khusus (misalnya penggunaan batu
                            bata api/batu bata tahan api dan mortar (vs. batu
                            bata bangunan umum) yang dapat menahan suhu yang
                            diperlukan untuk insinerator ini (lebih dari 800
                            °C). Untuk pembakaran total, diperlukan insinerator
                            bilik ganda yang masing-masing mencapai suhu di atas
                            800 °C dan 1100 °C. Jika insinerator ganda tidak
                            tersedia dan ada kebutuhan mendesak untuk
                            perlindungan kesehatan masyarakat, insinerator skala
                            kecil dapat digunakan. Ini melibatkan kompromi
                            antara dampak lingkungan dari pembakaran terkendali
                            dengan kebutuhan utama untuk melindungi kesehatan
                            masyarakat jika satu-satunya alternatif adalah
                            pembuangan sembarangan. Keadaan ini ada dalam banyak
                            situasi yang berkembang dan pembakaran skala kecil
                            dapat menjadi respons realistis terhadap kebutuhan
                            mendesak. <br />
                            Limbah dapat diolah di luar lokasi. Jika demikian,
                            harus ada cara untuk mengonfirmasi bahwa itu
                            diperlakukan dengan aman setelah dikeluarkan dari
                            tempat fasilitas. <br />
                            WHO (2014) Pengelolaan limbah yang aman dari
                            kegiatan pelayanan kesehatan <br />
                            WHO (2017) Pengelolaan limbah yang aman dari
                            kegiatan perawatan kesehatan: Ringkasan <br />
                            WHO (2019) Tinjauan teknologi untuk pengobatan
                            infeksi dan limbah tajam dari fasilitas perawatan
                            kesehatan <br />
                          `"
                      :options="[
                        {
                          label:
                            'Teknologi dibangun dengan standar yang sesuai, terpelihara dengan baik, fungsional dan berkapasitas memadai',
                          value: '1',
                        },
                        {
                          label:
                            'Teknologi tidak dibangun untuk standar yang benar atau tidak memiliki kapasitas yang memadai',
                          value: '2',
                        },
                        {
                          label:
                            'Teknologi tidak berfungsi dan tidak memiliki kapasitas yang memadai',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Treatment"
                      description="Limbah dikumpulkan untuk pengolahan di luar lokasi
                        dengan aman dan teratur dan dikirim ke fasilitas
                        pengolahan limbah berlisensi yang sesuai"
                      :selected.sync="
                        limbah_medis.Treatment[
                          'Limbah_dikumpulkan_untuk_pengolahan_di_luar_lokasi_dengan_aman_dan_teratur_dan_dikirim_ke_fasilitas_pengolahan_limbah_berlisensi_yang_sesuai'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[42]"
                      :tooltip_desc="`Limbah harus diolah dan dibuang dengan aman setelah
                            meninggalkan fasilitasi. Ini harus dilakukan melalui
                            layanan berlisensi atau terakreditasi.
                          `"
                      :options="[
                        {
                          label:
                            'Sampah dikumpulkan secara teratur dan aman serta dikirim ke fasilitas pengolahan yang sesuai',
                          value: '1',
                        },
                        {
                          label:
                            'Limbah dikumpulkan secara teratur dan aman tetapi fasilitas pengolahan belum diverifikasi',
                          value: '2',
                        },
                        {
                          label:
                            'Limbah tidak dikumpulkan dengan aman atau teratur atau dikirim ke fasilitas yang sesuai atau berlisensi',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Disposal</b>
                    </div>

                    <QuestionCard
                      title="Disposal"
                      description="Lubang pemakaman fungsional, tempat pembuangan limbah
                        berpagar, atau penjemputan kota tersedia untuk
                        pembuangan limbah tidak menular (tidak berbahaya/umum)"
                      :selected.sync="
                        limbah_medis.Disposal[
                          'Lubang_pemakaman_fungsional_tempat_pembuangan_limbah_berpagar_atau_penjemputan_kota_tersedia_untuk_pembuangan_limbah_tidak_menular__tidak_berbahaya_umum_'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[43]"
                      :tooltip_desc="`Lubang limbah harus ditutup untuk menghindari
                            banjir dan tidak terlalu penuh.
                          `"
                      :options="[
                        {
                          label:
                            'Lubang fungsional, area berpagar atau penjemputan kota tersedia dan cukup untuk memenuhi permintaan',
                          value: '1',
                        },
                        {
                          label:
                            'Lubang di fasilitas tetapi dimensinya tidak mencukupi; terlalu penuh atau tidak dipagari dan dikunci; pengambilan sampah kota yang tidak teratur, dll.',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada lubang atau metode pembuangan lain yang digunakan',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Disposal"
                      description="[Di mana ada risiko banjir] Lubang limbah dibangun untuk
                        menahan peristiwa dan keadaan darurat terkait iklim
                        (misalnya banjir) dan / atau tempat penyimpanan limbah
                        cadangan tersedia"
                      :selected.sync="
                        limbah_medis.Disposal[
                          'Lubang_limbah_dibangun_untuk_menahan_peristiwa_dan_keadaan_darurat_terkait_iklim__misalnya_banjir__dan_atau_tempat_penyimpanan_limbah_cadangan_tersedia'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[44]"
                      :tooltip_desc="`Lubang limbah harus dibangun di atas tanah yang
                            ditinggikan untuk mencegah luapan selama banjir.
                            Dasar lubang harus setidaknya 1,5 meter di atas
                            permukaan air.<br />
                            Jika banjir mungkin terjadi, alternatif tersedia
                            termasuk menyimpan limbah dalam wadah yang
                            ditinggikan dan / atau mengangkut limbah ke luar
                            lokasi. <br />
                            Jika tidak mungkin membangun lubang di tanah yang
                            ditinggikan, lubang harus memiliki saluran drainase
                            yang dibangun di sekitarnya untuk mengarahkan air
                            menjauh. Lubang limbah tidak boleh diisi secara
                            berlebihan dan area di sekitarnya tetap bebas dari
                            limbah
                          `"
                      :options="[
                        {
                          label:
                            'Lubang limbah dibangun untuk menahan banjir dan alternatif tersedia pada saat keadaan darurat',
                          value: '1',
                        },
                        {
                          label:
                            'Lubang limbah dapat menahan banjir terbatas tetapi tidak ada cadangan atau alternatif yang ada',
                          value: '2',
                        },
                        {
                          label:
                            'Lubang limbah tidak tahan iklim dan tidak ada cadangan atau tidak ada lubang limbah.',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Disposal"
                      description="Di mana pembakaran digunakan] Lubang abu khusus tersedia
                        untuk membuang abu dari pembakaran"
                      :selected.sync="
                        limbah_medis.Disposal[
                          'Lubang_abu_khusus_tersedia_untuk_membuang_abu_dari_pembakaran'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[45]"
                      :tooltip_desc="`Abu dari pembakaran (yang meliputi logam berat /
                            dioksin dan furan) harus dibuang dengan aman di
                            lubang abu. <br />
                            Lubang abu: berjajar atau tidak bergaris tergantung
                            pada geologi tetapi harus mencegah pencucian ke
                            lingkungan, dengan lempengan, dasar lubang
                            setidaknya 1,5 m dari meja air tanah. Jika air masuk
                            ke lubang abu, ia dapat melarutkan polutan ke dalam
                            tanah.
                          `"
                      :options="[
                        {
                          label: 'Lubang abu ada dan berfungsi',
                          value: '1',
                        },
                        {
                          label:
                            'Ada tetapi tidakberfungsi/ terlalu penuh atau tidak dipagari dan dikunci',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada lubang abu yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Disposal"
                      description="[Di mana kelahiran terjadi] Limbah anatomi/patologis
                        dimasukkan ke dalam lubang limbah patologis khusus,
                        dibakar di krematorium atau dikubur di kuburan"
                      :selected.sync="
                        limbah_medis.Disposal[
                          'Limbah_anatomi_patologis_dimasukkan_ke_dalam_lubang_limbah_patologis_khusus_dibakar_di_krematorium_atau_dikubur_di_kuburan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[46]"
                      :tooltip_desc="`Lubang plasenta dapat dilapisi atau tidak bergaris
                            tergantung pada geologi tanah. Itu harus mencakup
                            lempengan. Lubang limbah harus ditutup (dengan
                            lempengan) untuk menghindari banjir dan tidak
                            terlalu penuh dan harus memiliki pipa ventilasi. Itu
                            harus dikunci atau dipagari untuk mencegah akses
                            yang tidak sah.
                          `"
                      :options="[
                        {
                          label:
                            'Lubang ada dan semua limbah anatomi / patologis dibuang dengan benar',
                          value: '1',
                        },
                        {
                          label:
                            'Pit ada tetapi tidak digunakan atau pit digunakan tetapi terlalu banyak diisi',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada lubang yang tersedia',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Pharmaceutical waste</b>
                    </div>

                    <QuestionCard
                      title="Pharmaceutical waste"
                      description="Limbah farmasi diolah dan dibuang dengan aman, baik di
                        fasilitas pengolahan dan pembuangan aman yang dikelola
                        secara terpusat (yaitu di luar lokasi), dengan mengirim
                        kembali ke pabrikan, atau dengan pembakaran oleh
                        industri yang menggunakan kiln suhu tinggi"
                      :selected.sync="
                        limbah_medis.Pharmaceutical_Waste[
                          'Limbah_farmasi_diolah_dan_dibuang_dengan_aman_baik_di_fasilitas_pengolahan_dan_pembuangan_aman_yang_dikelola_secara_terpusat__yaitu_di_luar_lokasi__dengan_mengirim_kembali_ke_pabrikan_atau_dengan_pembakaran_oleh_industri_yang_menggunakan_kiln_suhu_tinggi'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[47]"
                      :tooltip_desc="`Strategi nasional dan/atau regional untuk
                            mengumpulkan dan mengolah/membuang limbah kimia dan
                            farmasi dari fasilitas kesehatan juga harus tersedia
                            dan beroperasi. Tanggung Jawab Produsen yang
                            Diperpanjang (EPR) untuk membawa obat-obatan dan
                            bahan kimia yang tidak digunakan atau kedaluwarsa
                            kembali ke produsen juga harus dipekerjakan. <br />
                            Insinerator terpusat harus mematuhi persyaratan
                            Konvensi Stockholm (untuk informasi lebih lanjut
                            lihat 'Pedoman tentang BAT dan panduan sementara
                            tentang TARUHAN:
                            http://chm.pops.int/Implementation/BATandBEP/BATBEPGuidelinesArticle5/tabid/187/Default.aspx).
                            <br />
                            Pembakaran bersama oleh industri semen: Investasi
                            tambahan yang signifikan dapat diperlukan untuk
                            memodifikasi fasilitas untuk penanganan dan pemuatan
                            limbah medis yang aman, dan mesin jarang dilengkapi
                            dengan peralatan penyaringan dan pembersihan yang
                            cocok untuk polutan yang dihasilkan.
                          `"
                      :options="[
                        {
                          label:
                            'Semua limbah farmasi diolah dan dibuang dengan aman',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa tetapi tidak semua limbah farmasi dibuang dengan benar',
                          value: '2',
                        },
                        {
                          label:
                            'Limbah farmasi tidak diolah atau dibuang dengan aman',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Personnel</b>
                    </div>

                    <QuestionCard
                      title="Personnel"
                      description="Seorang staf dilatih secara memadai untuk pengelolaan
                        dan pengawasan limbah perawatan kesehatan dan
                        melaksanakan tugas mereka dengan standar profesional
                        yang sesuai"
                      :selected.sync="
                        limbah_medis.Personnel[
                          'Seorang_staf_dilatih_secara_memadai_untuk_pengelolaan_dan_pengawasan_limbah_perawatan_kesehatan_dan_melaksanakan_tugas_mereka_dengan_standar_profesional_yang_sesuai'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[48]"
                      :tooltip_desc="`Orang ini mungkin kepala perawat atau anggota
                            komite pengendalian pencegahan infeksi. Mereka harus
                            memastikan bahwa semua anggota staf bertanggung
                            jawab atas pemisahan dan pembuangan limbah yang
                            dihasilkan selama layanan mereka.
                          `"
                      :options="[
                        {
                          label:
                            'Seorang anggota staf dilatih secara memadai dan melaksanakan tugas dengan benar',
                          value: '1',
                        },
                        {
                          label:
                            'Seorang anggota staf dilatih tetapi tidak melaksanakan tugas dengan benar, atau ditunjuk tetapi tidak dilatih',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada anggota staf seperti itu yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Personnel"
                      description="Staf yang menangani atau membuang limbah dan petugas
                        perawatan kesehatan divaksinasi hepatitis B (dan
                        memiliki vaksinasi lain yang direkomendasikan, sesuai
                        dengan pedoman nasional)"
                      :selected.sync="
                        limbah_medis.Personnel[
                          'Staf_yang_menangani_atau_membuang_limbah_dan_petugas_perawatan_kesehatan_divaksinasi_hepatitis_B__dan_memiliki_vaksinasi_lain_yang_direkomendasikan_sesuai_dengan_pedoman_nasional_'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[49]"
                      :tooltip_desc="`Semua pekerja yang berisiko terpapar darah
                            (termasuk pembersih dan penjamah limbah) harus
                            menerima pra-layanan dan imunisasi berkelanjutan
                            terhadap hepatitis B (ketiga dosis) dan penyakit
                            lain yang dapat dicegah dengan vaksin di tempat
                            kerja tanpa biaya kepada anggota staf.
                          `"
                      :options="[
                        {
                          label:
                            'Semua staf telah menerima semua vaksinasi yang diperlukan',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa tetapi tidak semua staf telah divaksinasi',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada staf yang telah divaksinasi',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Emergency preparedness</b>
                    </div>

                    <QuestionCard
                      title="Emergency preparedness"
                      description="[Ketika permintaan meningkat karena wabah atau peristiwa
                        terkait iklim] Strategi untuk menangani limbah tambahan
                        digunakan ketika permintaan meningkat"
                      :selected.sync="
                        limbah_medis.Emergency_Preparedness[
                          'Strategi_untuk_menangani_limbah_tambahan_digunakan_ketika_permintaan_meningkat'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[50]"
                      :tooltip_desc="`Jumlah limbah yang lebih besar dapat dihasilkan
                            selama dan setelah peristiwa dan keadaan darurat
                            terkait iklim atau sebagai akibat dari wabah
                            (misalnya APD tambahan).<br />
                            Strategi meliputi:<br />
                            - Menyediakan tempat sampah tambahan <br />
                            - Frekuensi pengosongan kontainer yang lebih tinggi
                            <br />
                            - Area penyimpanan khusus untuk menyimpan limbah
                            ekstra yang dihasilkan
                          `"
                      :options="[
                        {
                          label:
                            'Strategi untuk menangani limbah tambahan digunakan',
                          value: '1',
                        },
                        {
                          label:
                            'Strategi ada tetapi tidak diterapkan secara efektif',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada strategi yang ada',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>
                </v-card>
              </v-tab-item>

              <!-- Tab 4 -->
              <v-tab-item value="tabs4">
                <v-card class="mh-300 custom_card pa-5" v-if="tangan_bersih">
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Availability</b>
                    </div>
                    <QuestionCard
                      title="Availability"
                      description="Sarana kebersihan tangan yang berfungsi tersedia di
                        semua titik perawatan, termasuk di ruang bersalin"
                      :selected.sync="
                        tangan_bersih.Availability[
                          'Sarana_kebersihan_tangan_yang_berfungsi_tersedia_di_semua_titik_perawatan_termasuk_di_ruang_bersalin'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[51]"
                      :tooltip_desc="`Kebersihan tangan adalah istilah umum yang mengacu
                            pada setiap tindakan pembersihan tangan, yaitu
                            tindakan melakukan kebersihan tangan untuk tujuan
                            menghilangkan kotoran, bahan organik, dan / atau
                            mikroorganisme secara fisik atau mekanis. Sumber:
                            Pedoman WHO tentang kebersihan tangan dalam
                            perawatan kesehatan. 2009
                            (https://www.who.int/gpsc/5may/tools/9789241597906/en/).
                            <br /><br />

                            Tempat kebersihan tangan fungsional dapat terdiri
                            dari sabun dan air dengan baskom/wajan untuk mencuci
                            tangan dan handuk sekali pakai atau bersih, atau
                            handrub berbasis alkohol (ABHR). <br /><br />

                            Titik perawatan adalah tempat di mana tiga elemen
                            bersatu: pasien, petugas perawatan kesehatan dan
                            perawatan atau perawatan yang melibatkan kontak
                            dengan pasien atau lingkungannya (dalam zona
                            pasien). Di beberapa fasilitas, petugas kesehatan
                            membawa ABHR berkeliling pada orang mereka saat
                            mereka bergerak di antara area layanan.
                          `"
                      :options="[
                        {
                          label:
                            'Semua titik perawatan memiliki kebersihan tangan yang berfungsi (baik air dan sabun atau larutan penggosok tangan alkohol)',
                          value: '1',
                        },
                        {
                          label:
                            'Setidaknya 75% titik perawatan memiliki Sarana kebersihan tangan yang berfungsi',
                          value: '2',
                        },
                        {
                          label:
                            'Kurang dari 75% titik perawatan memiliki Sarana kebersihan tangan yang berfungsi',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Availability"
                      description="Sarana kebersihan tangan yang berfungsi tersedia di
                        semua ruang tunggu dan area umum lainnya, dan di area
                        pembuangan limbah"
                      :selected.sync="
                        tangan_bersih.Availability[
                          'Sarana_kebersihan_tangan_yang_berfungsi_tersedia_di_semua_ruang_tunggu_dan_area_umum_lainnya_dan_di_area_pembuangan_limbah'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[52]"
                      :tooltip_desc="`Sarana kebersihan tangan fungsional: dapat terdiri
                            dari sabun dan air dengan baskom / wajan untuk
                            mencuci tangan dan handuk sekali pakai atau bersih
                            atau handrub berbasis alkohol.
                          `"
                      :options="[
                        {
                          label:
                            'Sarana kebersihan tangan yang berfungsi tersedia di semua area',
                          value: '1',
                        },
                        {
                          label:
                            'Sarana kebersihan tangan yang berfungsi tersedia di beberapa tetapi tidak semua area.',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada Sarana kebersihan tangan yang berfungsi tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Availability"
                      description="(Semua toilet memiliki tempat cuci tangan yang berfungsi
                        dalam jarak 5 meter)"
                      :selected.sync="
                        tangan_bersih.Availability[
                          '_Semua_toilet_memiliki_tempat_cuci_tangan_yang_berfungsi_dalam_jarak_5_meter_'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label: 'Refer to S_3_1',
                          value: '1',
                        },
                        {
                          label: 'Refer to S_3_2',
                          value: '2',
                        },
                        {
                          label: 'Refer to S_3_3',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== kepatuhan -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Hygiene promotion</b>
                    </div>

                    <QuestionCard
                      title="Hygiene promotion"
                      description="Materi promosi kebersihan tangan ditampilkan dan
                        terlihat jelas di semua bangsal / area perawatan"
                      :selected.sync="
                        tangan_bersih.Hygiene_promotion[
                          'Materi_promosi_kebersihan_tangan_ditampilkan_dan_terlihat_jelas_di_semua_bangsal_area_perawatan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[53]"
                      :tooltip_desc="`Materi promosi kebersihan tangan menunjukkan
                            instruksi untuk kebersihan tangan (WHO 5 Moments for
                            hand hygiene) dan teknik yang benar
                          `"
                      :options="[
                        {
                          label:
                            'Materi ditampilkan dengan jelas di semua bangsal / area perawatan',
                          value: '1',
                        },
                        {
                          label:
                            'Materi ditampilkan dengan jelas di semua bangsal / area perawatan 2',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada bahan yang tersedia',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Hygiene compliance</b>
                    </div>

                    <QuestionCard
                      title="Hygiene compliance"
                      description="Kegiatan kepatuhan kebersihan tangan dilakukan secara
                        teratur (setidaknya setiap tahun)"
                      :selected.sync="
                        tangan_bersih.Hygiene_Compliance[
                          'Kegiatan_kepatuhan_kebersihan_tangan_dilakukan_secara_teratur__setidaknya_setiap_tahun_'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[54]"
                      :tooltip_desc="`Pemantauan kebersihan tangan langsung berarti
                            pengamatan langsung terhadap kinerja sesuai dengan
                            Who 5 Moments. Pemantauan kebersihan tangan langsung
                            berarti memantau konsumsi sabun dan alkohol berbasis
                            handrub. Untuk fasilitas yang lebih canggih,
                            kerangka penilaian diri kebersihan tangan WHO dapat
                            diselesaikan setiap tahun.
                          `"
                      :options="[
                        {
                          label:
                            'Kegiatan kepatuhan rutin (setidaknya tahunan) berlangsung di seluruh fasilitas',
                          value: '1',
                        },
                        {
                          label:
                            'Kegiatan kepatuhan dalam kebijakan, tetapi tidak dilakukan dengan keteraturan apa pun',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada aktivitas kepatuhan',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Supplies (hand hygiene)</b>
                    </div>

                    <QuestionCard
                      title="Supplies (hand hygiene)"
                      description="Audit rutin (setidaknya setiap tiga bulan) berbasis
                        lingkungan dilakukan untuk menilai ketersediaan hand
                        rub, sabun, handuk sekali pakai, dan sumber daya
                        kebersihan tangan lainnya"
                      :selected.sync="
                        tangan_bersih.Supplies[
                          'Audit_rutin__setidaknya_setiap_tiga_bulan__berbasis_lingkungan_dilakukan_untuk_menilai_ketersediaan_hand_rub_sabun_handuk_sekali_pakai_dan_sumber_daya_kebersihan_tangan_lainnya'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Audit berbasis lingkungan reguler (setidaknya tahunan) dilakukan',
                          value: '1',
                        },
                        {
                          label:
                            'Audit berbasis lingkungan yang dilakukan kurang dari sekali seminggu atau audit tidak lengkap',
                          value: '2',
                        },
                        {
                          label: 'Tidak dilakukan',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>
                </v-card>
              </v-tab-item>

              <!-- Tab 5 -->
              <v-tab-item value="tabs5">
                <v-card class="mh-300 custom_card pa-5" v-if="lingkung_bersih">
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Policies</b>
                    </div>

                    <QuestionCard
                      title="Policies"
                      description="Kebijakan atau protokol kebersihan fasyankes (atau
                        lingkungan) yang jelas dan terperinci dipajang dengan
                        jelas, diterapkan dan dipantau"
                      :selected.sync="
                        lingkung_bersih.Policies[
                          'Kebijakan_atau_protokol_kebersihan_fasyankes__atau_lingkungan__yang_jelas_dan_terperinci_dipajang_dengan_jelas_diterapkan_dan_dipantau'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[55]"
                      :tooltip_desc="`Kebijakan atau protokol harus mencakup hal-hal
                            berikut: <br />
                            • garis pelaporan fungsional yang ditentukan dan
                            tanggung jawab untuk semua staf yang terlibat,
                            termasuk staf tambahan (misalnya penangan limbah dan
                            pembersih);<br />
                            • jadwal pembersihan untuk setiap area perawatan
                            pasien dan peralatan perawatan pasien nonkritis,
                            menentukan frekuensi, metode, dan staf yang
                            bertanggung jawab;<br />
                            • rencana kontingensi dan prosedur pembersihan yang
                            diperlukan untuk manajemen wabah;<br />
                            • Persyaratan pelatihan dan standar kinerja untuk
                            staf kebersihan<br />
                            • metode pemantauan, frekuensi, dan staf yang
                            bertanggung jawab;<br />
                            • daftar produk pembersih, persediaan, dan peralatan
                            yang disetujui serta spesifikasi yang diperlukan
                            untuk penggunaannya; dan<br />
                            • daftar APD yang diperlukan dan kapan tindakan
                            kebersihan tangan direkomendasikan untuk staf
                            kebersihan.<br />
                            Bangsal khusus (misalnya ICU, unit ketergantungan
                            tinggi, ruang operasi) mungkin memerlukan protokol
                            khusus. <br /><br />

                            EC_1 sejalan dengan pertanyaan JMP G-C1. Mencoba
                            menilai apakah area layanan dianggap 'bersih' sangat
                            subjektif, dan terlihat bersih mungkin sangat
                            berbeda dari bersih secara mikrobiologis. Demikian
                            pula, frekuensi pembersihan sulit diukur karena
                            tidak dapat diamati oleh enumerator dalam satu hari
                            dan respons cenderung tunduk pada bias responden.
                            Seberapa sering fasilitas perlu dibersihkan terkait
                            dengan beban pasien, oleh karena itu jadwal
                            pembersihan sangat bervariasi dari satu fasilitas ke
                            fasilitas lainnya. Menanyakan tentang adanya
                            protokol pembersihan menjadi indikasi pentingnya
                            sebuah fasilitas terhadap kebersihan lingkungan.
                            Untuk pembersih melek huruf rendah atau buta huruf,
                            protokol harus disesuaikan dan disederhanakan sesuai
                            dengan gambar dan ilustrasi yang dapat dikenali. .
                          `"
                      :options="[
                        {
                          label:
                            'Kebijakan atau protokol kebersihan ada, dilaksanakan dan dipantau',
                          value: '1',
                        },
                        {
                          label:
                            'Kebijakan atau protokol kebersihan ada tetapi tidak diterapkan atau dipantau',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada kebijakan atau protokol kebersihan',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== kepatuhan -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Monitoring</b>
                    </div>

                    <QuestionCard
                      title="Monitoring"
                      description="Catatan pembersihan tersedia untuk area perawatan
                        pasien, bangsal umum atau seluruh fasilitas dan
                        ditandatangani oleh petugas kebersihan yang relevan
                        setiap hari"
                      :selected.sync="
                        lingkung_bersih.Monitoring[
                          'Catatan_pembersihan_tersedia_untuk_area_perawatan_pasien_bangsal_umum_atau_seluruh_fasilitas_dan_ditandatangani_oleh_petugas_kebersihan_yang_relevan_setiap_hari'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[56]"
                      :tooltip_desc="`Buat catatan tersedia di lokasi pusat atau di mana
                            tugas pembersihan terjadi sehingga staf pengawas
                            dapat mengelolanya setiap hari, bersama dengan staf
                            (misalnya focal person IPC) yang bertanggung jawab
                            untuk kegiatan pemantauan berkala. Untuk panduan
                            lebih lanjut tentang catatan pembersihan dan
                            mekanisme pemantauan lainnya, lihat Praktik Terbaik
                            CDC untuk Pembersihan Lingkungan, bagian 2.4.3.
                          `"
                      :options="[
                        {
                          label:
                            'Tersedia di setiap bangsal/area atau seluruh fasilitas',
                          value: '1',
                        },
                        {
                          label:
                            'Catatan ada, tetapi tidak untuk setiap lingkungan atau tidak untuk setiap hari atau sudah usang',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada catatan pembersihan yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Monitoring"
                      description="Toilet dibersihkan setidaknya sekali setiap hari, dan
                        catatan pembersihan ditandatangani oleh petugas
                        kebersihan dan ditampilkan secara nyata"
                      :selected.sync="
                        lingkung_bersih.Monitoring[
                          'Toilet_dibersihkan_setidaknya_sekali_setiap_hari_dan_catatan_pembersihan_ditandatangani_oleh_petugas_kebersihan_dan_ditampilkan_secara_nyata'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[57]"
                      :tooltip_desc="`Toilet pribadi harus dibersihkan dan didesinfeksi
                            setidaknya sekali sehari (misalnya per periode 24
                            jam) atau ketika terlihat kotor, setelah pembersihan
                            rutin area perawatan pasien. <br />
                            Toilet umum atau bersama harus dibersihkan dan
                            didesinfeksi dua kali sehari, atau ketika terlihat
                            kotor.<br />
                            Untuk pembersih melek huruf rendah atau buta huruf,
                            catatan pembersihan harus disesuaikan dan
                            disederhanakan sesuai dengan gambar dan ilustrasi
                            yang dapat dikenali.
                          `"
                      :options="[
                        {
                          label:
                            'Toilet dibersihkan setiap hari dan catatan yang ditandatangani terlihat',
                          value: '1',
                        },
                        {
                          label:
                            'Toilet dibersihkan tetapi kurang dari sekali sehari dengan atau tanpa catatan',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada catatan yang tersedia dan toilet dibersihkan kurang dari sekali sehari',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Personnel</b>
                    </div>

                    <QuestionCard
                      title="Personnel"
                      description="Jumlah staf kebersihan yang diperlukan atau staf dengan
                        tanggung jawab pembersihan tersedia di bangsal /
                        fasilitas setiap hari atau ketika pembersihan diperlukan
                        dan memiliki waktu yang didedikasikan untuk melakukan
                        kegiatan pembersihan"
                      :selected.sync="
                        lingkung_bersih.Personnel[
                          'Jumlah_staf_kebersihan_yang_diperlukan_atau_staf_dengan_tanggung_jawab_pembersihan_tersedia_di_bangsal_fasilitas_setiap_hari_atau_ketika_pembersihan_diperlukan_dan_memiliki_waktu_yang_didedikasikan_untuk_melakukan_kegiatan_pembersihan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[58]"
                      :tooltip_desc="`Pembersih (juga dikenal sebagai staf kebersihan
                            lingkungan atau teknisi jasa lingkungan) adalah
                            individu yang bertanggung jawab untuk melakukan
                            pembersihan lingkungan di fasilitas perawatan
                            kesehatan yang memainkan peran kunci dalam menjaga
                            kebersihan dan / atau higienis lingkungan yang
                            memfasilitasi praktik yang terkait dengan pencegahan
                            dan pengendalian infeksi terkait perawatan
                            kesehatan.<br /><br />

                            Staf harus tersedia secara teratur (misalnya setiap
                            hari), cukup untuk semua bangsal dan dengan waktu
                            khusus untuk melakukan kegiatan pembersihan. Jumlah
                            staf kebersihan yang diperlukan akan bervariasi
                            berdasarkan beberapa faktor, termasuk: jumlah tempat
                            tidur pasien, tingkat hunian, jenis pembersihan
                            (misalnya, rutin atau terminal), jenis area
                            perawatan pasien (misalnya, area perawatan khusus
                            seperti ICU dan OR). Staf dapat paruh waktu atau
                            penuh waktu.
                          `"
                      :options="[
                        {
                          label:
                            'Jumlah staf yang diperlukan tersedia setiap saat saat saat dibutuhkan dan memiliki waktu khusus untuk melakukan kegiatan pembersihan',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa staf tersedia tetapi jumlahnya tidak mencukupi, tidak setiap saat bila diperlukan, atau tidak di semua lingkungan',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada staf kebersihan yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Personnel"
                      description="Semua staf yang bertanggung jawab untuk kebersihan telah
                        menerima pelatihan tentang kebersihan"
                      :selected.sync="
                        lingkung_bersih.Personnel[
                          'Semua_staf_yang_bertanggung_jawab_untuk_kebersihan_telah_menerima_pelatihan_tentang_kebersihan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[59]"
                      :tooltip_desc="`'Staf yang bertanggung jawab untuk pembersihan'
                            mengacu pada penyedia layanan non-kesehatan seperti
                            pembersih, mantri atau staf tambahan, serta penyedia
                            layanan kesehatan yang, di samping tugas perawatan
                            klinis dan pasien mereka, melakukan tugas
                            pembersihan sebagai bagian dari peran mereka.<br />
                            Pelatihan mengacu pada rencana atau program
                            pelatihan terstruktur yang dipimpin oleh pelatih
                            atau supervisor yang berkualifikasi tepat.<br />
                            Staf harus menerima pelatihan penyegaran pra-layanan
                            dan tahunan, atau sebelum pengenalan persediaan atau
                            peralatan pembersih lingkungan baru. .
                          `"
                      :options="[
                        {
                          label:
                            'Semua staf yang bertanggung jawab untuk pembersihan telah menerima pelatihan',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa tetapi tidak semua staf telah menerima pelatihan',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada staf yang menerima pelatihan',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Personnel"
                      description="Kebijakan dan praktik untuk meningkatkan keselamatan
                        kerja petugas kebersihan dan teknisi limbah perawatan
                        kesehatan tersedia dan diterapkan"
                      :selected.sync="
                        lingkung_bersih.Personnel[
                          'Kebijakan_dan_praktik_untuk_meningkatkan_keselamatan_kerja_petugas_kebersihan_dan_teknisi_limbah_perawatan_kesehatan_tersedia_dan_diterapkan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[60]"
                      :tooltip_desc="`'Untuk informasi lebih lanjut tentang kesehatan
                            kerja, lihat: WHO/ILO (2014) HealthWISE -
                            Peningkatan Kerja dalam Layanan Kesehatan - Action
                            Manual,
                            https://www.ilo.org/sector/Resources/training-materials/WCMS_237276/lang--en/index.htm
                          `"
                      :options="[
                        {
                          label: 'Kebijakan tersedia dan diimplementasikan',
                          value: '1',
                        },
                        {
                          label:
                            'Kebijakan tersedia tetapi tidak cukup diimplementasikan',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada kebijakan yang tersedia',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Supplies</b>
                    </div>

                    <QuestionCard
                      title="Supplies"
                      description="Bahan yang sesuai dan terawat dengan baik (misalnya
                        deterjen, pel, ember) untuk membersihkan berbagai area
                        dan permukaan yang berbeda tersedia dan cukup"
                      :selected.sync="
                        lingkung_bersih.Supplies[
                          'Bahan_yang_sesuai_dan_terawat_dengan_baik_misalnya_deterjen_pel_ember_untuk_membersihkan_berbagai_area_dan_permukaan_yang_berbeda_tersedia_dan_cukup'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[61]"
                      :tooltip_desc="`'Minimal, kain pembersih sekali pakai atau dapat
                            digunakan kembali, ember, pel, deterjen, dan
                            desinfektan tingkat rendah. Produk harus sesuai
                            dengan panduan/peraturan tingkat nasional yang ada
                            tentang produk yang sesuai dan tersedia secara lokal
                            untuk pembersihan perawatan kesehatan. <br />
                            Persediaan rutin (misalnya bulanan) dan inspeksi
                            persediaan dan peralatan harus dilakukan untuk
                            mencegah kehabisan stok, mengantisipasi kebutuhan
                            pasokan dan memastikan ketersediaan bahan tambahan
                            untuk kontinjensi seperti wabah. <br /><br />

                            Daftar utama produk berkhasiat yang disetujui
                            fasilitas harus dikembangkan yang meminimalkan
                            jumlah produk berbahaya di sekitarnya. Banyak
                            deterjen dan desinfektan tradisional mengandung
                            bahan kimia beracun yang persisten yang dapat
                            menyebabkan kanker, penyakit pernapasan, iritasi
                            mata dan kulit dan berkontribusi terhadap pencemaran
                            lingkungan selama pembuatan, penggunaan, dan
                            pembuangan. Fasilitas layanan kesehatan harus
                            mendapatkan dan menggunakan produk pembersih yang
                            tidak diberi pewangi dan lebih ramah lingkungan,
                            dengan kemasan minimal dan/atau ramah lingkungan.
                          `"
                      :options="[
                        {
                          label:
                            'Semua peralatan yang diperlukan tersedia, dalam kondisi baik dan cukup',
                          value: '1',
                        },
                        {
                          label:
                            'Tersedia tetapi tidak terawat dengan baik atau di beberapa tetapi tidak semua area atau tidak cukup',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada bahan yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Supplies"
                      description="Anggaran tahunan untuk perlengkapan dan peralatan
                        kebersihan lingkungan ada dan cukup untuk semua
                        kebutuhan."
                      :selected.sync="
                        lingkung_bersih.Supplies[
                          'Anggaran_tahunan_untuk_perlengkapan_dan_peralatan_kebersihan_lingkungan_ada_dan_cukup_untuk_semua_kebutuhan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[62]"
                      :tooltip_desc="`'Anggaran tahunan untuk program pembersihan
                            lingkungan yang efektif, termasuk: <br />
                            • Personil (gaji dan tunjangan untuk staf
                            kebersihan, supervisor, dan manajer program secara
                            keseluruhan) <br />
                            • Pelatihan staf (setidaknya penyegaran pra-layanan
                            dan tahunan)<br />
                            • perlengkapan dan peralatan pembersih lingkungan,
                            termasuk APD untuk staf kebersihan<br />
                            • Biaya administrasi<br />
                            • Biaya produksi dan pencetakan untuk daftar
                            periksa, log, dan alat bantu pekerjaan lainnya<br />
                            • biaya infrastruktur/jasa, seperti layanan air
                            penunjang dan air limbah (sebagaimana berlaku)
                          `"
                      :options="[
                        {
                          label: 'Anggaran ada dan cukup untuk semua kebutuhan',
                          value: '1',
                        },
                        {
                          label:
                            'Anggaran ada tapi tidak cukup untuk semua kebutuhan',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada anggaran',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Supplies"
                      description="Area khusus untuk penyimpanan, persiapan dan perawatan
                        persediaan dan peralatan kebersihan ada ('area layanan
                        pembersihan lingkungan'), dijaga kebersihannya dan
                        dirawat dengan baik, dan digunakan sesuai dengan
                        tujuannya"
                      :selected.sync="
                        lingkung_bersih.Supplies[
                          'Area_khusus_untuk_penyimpanan_persiapan_dan_perawatan_persediaan_dan_peralatan_kebersihan_ada_area_layanan_pembersihan_lingkungan_dijaga_kebersihannya_dan_dirawat_dengan_baik_dan_digunakan_sesuai_dengan_tujuannya'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[63]"
                      :tooltip_desc="`'Area layanan pembersihan lingkungan adalah ruang
                            khusus untuk mempersiapkan, memproses ulang, dan
                            menyimpan persediaan dan peralatan pembersih
                            lingkungan yang bersih atau baru, termasuk produk
                            pembersih dan APD. Kamar-kamar ini telah membatasi
                            akses hanya untuk staf kebersihan dan personel resmi
                            lainnya. Itu harus tersedia di dalam fasilitas,
                            terlepas dari apakah program pembersihan dikelola
                            sendiri atau oleh perusahaan eksternal. Area
                            tersebut harus berventilasi baik, diterangi,
                            memiliki pasokan air yang sesuai (akses air panas
                            dan dingin, jika memungkinkan), memiliki wastafel
                            cuci tangan khusus, APD tersedia, bebas dari
                            kekacauan, dan berukuran tepat. Untuk panduan lebih
                            lanjut, lihat pedoman Pembersihan Lingkungan CDC.
                          `"
                      :options="[
                        {
                          label:
                            'Area khusus ada, terawat dengan baik, dijaga kebersihannya dan digunakan sesuai dengan tujuannya',
                          value: '1',
                        },
                        {
                          label:
                            'Suatu area ada tetapi berisi barang-barang lain atau tidak bersih',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada area penyimpanan khusus',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">PPE</b>
                    </div>

                    <QuestionCard
                      title="PPE"
                      description="APD yang memadai tersedia setiap saat dan dalam jumlah
                        yang cukup untuk semua staf kebersihan"
                      :selected.sync="
                        lingkung_bersih.Ppe[
                          'APD_yang_memadai_tersedia_setiap_saat_dan_dalam_jumlah_yang_cukup_untuk_semua_staf_kebersihan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[64]"
                      :tooltip_desc="`'APD minimum yang dibutuhkan untuk petugas
                            kebersihan di semua fasilitas adalah: 1. Gaun
                            dan/atau celemek plastik, 2. Sarung tangan karet
                            yang dapat digunakan kembali dan tahan lama, 3.
                            Masker wajah, 4. kacamata atau pelindung wajah.
                            Semua APD (dapat digunakan kembali dan sekali pakai)
                            harus dalam persediaan yang baik, terawat dengan
                            baik (kualitas baik, stok disimpan dengan tepat),
                            dibersihkan sebelum digunakan, dan dalam perbaikan
                            yang baik. Semua APD yang dapat digunakan kembali
                            harus diproses ulang (yaitu, dibersihkan dan
                            didesinfeksi) setidaknya sekali sehari. Sumber daya
                            kebersihan tangan juga harus tersedia untuk staf
                            yang menggunakan APD.
                          `"
                      :options="[
                        {
                          label:
                            'Semua anggota staf kebersihan memiliki APD yang memadai',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa tetapi tidak semua staf memiliki APD atau APD lengkap yang tersedia tetapi dalam kondisi buruk',
                          value: '2',
                        },
                        {
                          label: 'Tidak tersedia',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Emergency Preparedness</b>
                    </div>

                    <QuestionCard
                      title="Emergency Preparedness"
                      description="[Jika beban pasien meningkat] Staf tambahan (misalnya
                        daftar nama) dan perlengkapan kebersihan tambahan
                        tersedia untuk ditempatkan di fasilitas jika beban
                        pasien meningkat"
                      :selected.sync="
                        lingkung_bersih.Emergency_Preparedness[
                          'Staf_tambahan__misalnya_daftar_nama__dan_perlengkapan_kebersihan_tambahan_tersedia_untuk_ditempatkan_di_fasilitas_jika_beban_pasien_meningkat'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[65]"
                      :tooltip_desc="`'Lihat CDC Praktik terbaik untuk pembersihan
                            lingkungan dalam pengaturan terbatas sumber daya
                            untuk panduan lebih lanjut:
                            https://www.cdc.gov/hai/pdfs/resource-limited/environmental-cleaning-RLS-H.pdf
                          `"
                      :options="[
                        {
                          label:
                            'Staf tambahan dan persediaan keduanya tersedia',
                          value: '1',
                        },
                        {
                          label:
                            'Baik staf atau persediaan tidak cukup untuk memenuhi kebutuhan tambahan',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada staf atau persediaan tambahan yang tersedia.',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Laundry</b>
                    </div>

                    <QuestionCard
                      title="Laundry"
                      description="Semua tempat tidur/kasur memiliki penutup tahan air yang
                        tanpa tanda-tanda kerusakan (robek, sobek atau lubang)"
                      :selected.sync="
                        lingkung_bersih.Laundry[
                          'Semua_tempat_tidur_kasur_memiliki_penutup_tahan_air_yang_tanpa_tanda_tanda_kerusakan_robek_sobek_atau_lubang'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[66]"
                      :tooltip_desc="`'Untuk perawatan rawat inap jangka panjang, penutup
                            tahan air harus dapat dilepas dan bernapas.
                          `"
                      :options="[
                        {
                          label:
                            'Semua tempat tidur/kasur memiliki penutup tahan air tanpa tanda-tanda kerusakan',
                          value: '1',
                        },
                        {
                          label:
                            'Tempat tidur / kasur memiliki penutup tahan air tetapi beberapa atau semua rusak',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada penutup tahan air',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Laundry"
                      description="Fasilitas binatu bersih, terawat dengan baik dan mampu
                        memenuhi permintaan (misalnya untuk mencuci linen dari
                        tempat tidur pasien di antara setiap pasien)"
                      :selected.sync="
                        lingkung_bersih.Laundry[
                          'Fasilitas_binatu_bersih_terawat_dengan_baik_dan_mampu_memenuhi_permintaan__misalnya_untuk_mencuci_linen_dari_tempat_tidur_pasien_di_antara_setiap_pasien_'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[67]"
                      :tooltip_desc="`'Area binatu harus memiliki drainase yang efektif
                            dan berendam dan harus ada area yang cukup untuk
                            mengeringkan linen dengan udara. Linen kotor harus
                            disimpan dalam kantong terpisah, tertutup, ditandai
                            untuk transportasi dan penyimpanan.
                          `"
                      :options="[
                        {
                          label:
                            'Fasilitas binatu bersih, terawat dengan baik dan dapat memenuhi permintaan',
                          value: '1',
                        },
                        {
                          label:
                            'Fasilitas ada tetapi tidak bersih, terawat dengan baik atau mampu memenuhi permintaan',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada fasilitas fungsional',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Laundry"
                      description="Layanan binatu dengan air panas (70–80°C x 10 menit)
                        untuk memproses ulang kain dan kepala pel tersedia, dan
                        kepala pel serta kain pembersih selalu dicuci secara
                        terpisah dari tekstil rumah sakit kotor lainnya."
                      :selected.sync="
                        lingkung_bersih.Laundry[
                          'Layanan_binatu_dengan_air_panas'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Fasilitas binatu dengan air panas yang tersedia dan bahan pembersih dicuci secara terpisah',
                          value: '1',
                        },
                        {
                          label:
                            'Fasilitas binatu tersedia tetapi air tidak cukup suhu atau bahan pembersih tidak dicuci secara terpisah',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada layanan seperti itu yang tersedia',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Food Hygiene</b>
                    </div>

                    <QuestionCard
                      title="Food Hygiene"
                      description="[Khusus rumah sakit] Makanan disiapkan dan ditangani
                        dengan aman (dengan tangan bersih, pada permukaan bersih
                        dan dengan peralatan bersih)"
                      :selected.sync="
                        lingkung_bersih.Food_Hygiene[
                          'Makanan_disiapkan_dan_ditangani_dengan_aman__dengan_tangan_bersih_pada_permukaan_bersih_dan_dengan_peralatan_bersih_'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[68]"
                      :tooltip_desc="`'Permukaan yang digunakan untuk persiapan makanan
                            harus dicuci dengan deterjen dan air yang aman dan
                            kemudian dibilas, atau diseka dengan kain bersih
                            yang sering dicuci. Sisa-sisa makanan harus dibuang
                            dengan cepat, karena merupakan reservoir potensial
                            bagi bakteri dan dapat menarik serangga dan hewan
                            pengerat. Menolak harus disimpan di tempat sampah
                            tertutup dan dibuang dengan cepat dan aman. <br />
                            Peralatan makan harus dicuci segera setelah
                            digunakan dengan air panas dan deterjen, dan
                            dikeringkan dengan udara. <br />
                            Untuk panduan lebih lanjut, lihat WHO (2008) Standar
                            dan
                            https://www.who.int/foodsafety/publications/all/en/
                            kesehatan lingkungan yang penting
                          `"
                      :options="[
                        {
                          label: 'Makanan disiapkan dan ditangani dengan aman',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa tetapi tidak semua langkah keamanan pangan diikuti (lihat catatan)',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada langkah-langkah keamanan pangan yang diikuti / keamanan pangan sangat buruk',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Food Hygiene"
                      description=" [Khusus rumah sakit] Toko dapur dan makanan siap saji
                        dilindungi dari lalat, serangga dan tikus lainnya"
                      :selected.sync="
                        lingkung_bersih.Food_Hygiene[
                          'Toko_dapur_dan_makanan_siap_saji_dilindungi_dari_lalat_serangga_dan_tikus_lainnya'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[69]"
                      :tooltip_desc="`'Area persiapan makanan harus dijaga kebersihannya
                            dan dilindungi dari hewan pengerat dan serangga.
                            Penyimpanan makanan harus ditutup untuk mencegah
                            kontaminasi oleh tikus dan lalat.
                          `"
                      :options="[
                        {
                          label:
                            'Tidak ada lalat, serangga, atau tikus yang dapat mengakses toko makanan dan dapur',
                          value: '1',
                        },
                        {
                          label:
                            'Toko makanan dan makanan sebagian dilindungi tetapi dapat ditingkatkan',
                          value: '2',
                        },
                        {
                          label:
                            'Toko makanan dan makanan tidak memiliki perlindungan dari lalat, serangga, atau tikus',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>
                </v-card>
              </v-tab-item>

              <!-- Tab 6 -->
              <v-tab-item value="tabs6">
                <v-card class="mh-300 custom_card pa-5" v-if="energi">
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Energy</b>
                    </div>
                    <QuestionCard
                      title="Energy"
                      description="Fasyankes memiliki sumber listrik yang fungsional dan
                        terawat dengan baik (misalnya jaringan listrik, surya)"
                      :selected.sync="
                        energi.Energy[
                          'Fasyankes_memiliki_sumber_listrik_yang_fungsional_dan_terawat_dengan_baik__misalnya_jaringan_listrik_surya_'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[70]"
                      :tooltip_desc="`'Dalam pengaturan rawat inap yang terisolasi
                            (seperti rumah sakit pedesaan) dan dalam struktur
                            sementara (seperti pusat perawatan kolera),
                            generator atau panel surya dan baterai kemungkinan
                            akan diperlukan dan penyediaan untuk ini harus
                            dilakukan. Minimal, jenis minyak tanah atau lentera
                            gas yang aman dan obor tangan yang kuat harus
                            tersedia.
                          `"
                      :options="[
                        {
                          label:
                            'Sumber listrik ada, fungsional dan terawat dengan baik',
                          value: '1',
                        },
                        {
                          label: 'Ya, ada tetapi saat ini tidak berfungsi',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada listrik',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Energy"
                      description="Energi cukup untuk semua kebutuhan listrik fasilitas,
                        termasuk untuk penerangan dan perangkat yang berdiri
                        sendiri (misalnya Program yang Diperluas pada rantai
                        dingin Imunisasi)"
                      :selected.sync="
                        energi.Energy[
                          'Energi_cukup_untuk_semua_kebutuhan_listrik_fasilitas_termasuk_untuk_penerangan_dan_perangkat_yang_berdiri_sendiri__misalnya_Program_yang_Diperluas_pada_rantai_dingin_Imunisasi_'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[71]"
                      :tooltip_desc="`'Semua kebutuhan mencakup pencahayaan, komunikasi,
                            peralatan/peralatan medis, dan perumahan staf.
                          `"
                      :options="[
                        {
                          label: 'Energi dalam jumlah yang cukup setiap saat',
                          value: '1',
                        },
                        {
                          label:
                            'Energi cukup untuk memenuhi beberapa tetapi tidak semua permintaan',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada energi yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Energy"
                      description="[Di mana air dipompa] Energi yang cukup tersedia untuk
                        memompa air"
                      :selected.sync="
                        energi.Energy[
                          'Energi_yang_cukup_tersedia_untuk_memompa_air'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[72]"
                      :tooltip_desc="`'Jika peningkatan ke sistem pemompaan diperlukan,
                            ini harus mempertimbangkan penggunaan energi
                            terbarukan (misalnya matahari)
                          `"
                      :options="[
                        {
                          label: 'Energi dalam jumlah yang cukup setiap saat',
                          value: '1',
                        },
                        {
                          label:
                            'Energi cukup untuk memenuhi beberapa tetapi tidak semua permintaan',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada energi yang tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Energy"
                      description="[Di mana air dipanaskan] Energi yang cukup tersedia
                        untuk memanaskan air"
                      :selected.sync="
                        energi.Energy[
                          'Energi_yang_cukup_tersedia_untuk_memanaskan_air'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[73]"
                      :tooltip_desc="`'Jika peningkatan ke sistem pemanas diperlukan, ini
                            harus mempertimbangkan penggunaan energi terbarukan
                            (misalnya matahari)
                          `"
                      :options="[
                        {
                          label: 'Energi dalam jumlah yang cukup setiap saat',
                          value: '1',
                        },
                        {
                          label:
                            'Energi cukup untuk memenuhi beberapa tetapi tidak semua permintaan',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada energi yang tersedia',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ==============================================================================================================  -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Backup</b>
                    </div>
                    <QuestionCard
                      title="Backup"
                      description="Sumber energi cadangan fungsional (misalnya generator
                        dengan bahan bakar yang memadai), ada jika sumber utama
                        gagal"
                      :selected.sync="
                        energi.Backup[
                          'Sumber_energi_cadangan_fungsional__misalnya_generator_dengan_bahan_bakar_yang_memadai__ada_jika_sumber_utama_gagal'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[74]"
                      :tooltip_desc="`'Sumber cadangan mungkin diperlukan untuk perangkat
                            medis, lemari es, penerangan, dan memompa air. Ini
                            harus menyala secara otomatis jika sumber daya rutin
                            terputus. Anggaran yang cukup harus tersedia untuk
                            bahan bakar untuk generator cadangan listrik. .
                          `"
                      :options="[
                        {
                          label:
                            'Ada sumber cadangan, dengan bahan bakar yang memadai',
                          value: '1',
                        },
                        {
                          label:
                            'Sumber cadangan ada tetapi tidak berfungsi atau bahan bakar tidak mencukupi Beberapa tetapi tidak semua pencahayaan hemat energi',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada sumber cadangan',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Efficiency</b>
                    </div>
                    <QuestionCard
                      title="Efficiency"
                      description="Pencahayaan hemat energi digunakan dengan kontrol
                        pencahayaan yang ditingkatkan dan lampu hemat energi"
                      :selected.sync="
                        energi.Efficiency[
                          'Pencahayaan_hemat_energi_digunakan_dengan_kontrol_pencahayaan_yang_ditingkatkan_dan_lampu_hemat_energi'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[75]"
                      :tooltip_desc="`'Cahaya alami mungkin cukup di siang hari dan harus
                            digunakan jika memungkinkan untuk mengurangi
                            konsumsi energi.
                          `"
                      :options="[
                        {
                          label: 'Semua pencahayaan hemat energi',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa tetapi tidak semua pencahayaan hemat energi',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada pencahayaan hemat energi yang tersedia atau statusnya tidak diketahui',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Adequacy</b>
                    </div>
                    <QuestionCard
                      title="Adequacy"
                      description="Ruang bersalin cukup terang, termasuk di malam hari"
                      :selected.sync="
                        energi.Adequacy[
                          'Ruang_bersalin_cukup_terang_termasuk_di_malam_hari'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Ruang bersalin memiliki pencahayaan yang berfungsi',
                          value: '1',
                        },
                        {
                          label:
                            'Infrastruktur penerangan ada, tetapi tidak berfungsi',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak cukup penerangan atau tidak ada infrastruktur pencahayaan',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Adequacy"
                      description="Kamar mandi cukup terang, termasuk di malam hari"
                      :selected.sync="
                        energi.Adequacy[
                          'Kamar_mandi_cukup_terang_termasuk_di_malam_hari'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[76]"
                      :tooltip_desc="`'Penerangan untuk mandi diperlukan di semua
                            fasilitas di mana layanan malam hari disediakan dan
                            di mana tidak ada cahaya alami yang cukup untuk
                            menggunakan pancuran dengan aman di siang hari.
                          `"
                      :options="[
                        {
                          label:
                            'Semua kamar mandi  memiliki pencahayaan yang berfungsi',
                          value: '1',
                        },
                        {
                          label: 'Alat penerangan ada, tetapi tidak berfungsi',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak cukup penerangan atau tidak ada alat penerangan',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Adequacy"
                      description="Jamban cukup terang, termasuk di malam hari"
                      :selected.sync="
                        energi.Adequacy[
                          'Jamban_cukup_terang_termasuk_di_malam_hari'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[77]"
                      :tooltip_desc="`'Pencahayaan untuk jamban diperlukan di semua
                            fasilitas di mana layanan malam hari disediakan dan
                            di mana tidak ada cahaya alami yang cukup untuk
                            menggunakan jamban dengan aman di siang hari.
                          `"
                      :options="[
                        {
                          label:
                            'Semua jamban memiliki pencahayaan yang berfungsi',
                          value: '1',
                        },
                        {
                          label:
                            'Infrastruktur penerangan ada, tetapi tidak berfungsi',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak cukup penerangan atau tidak ada infrastruktur pencahayaan',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Ventilation</b>
                    </div>
                    <QuestionCard
                      title="Ventilation"
                      description="Ventilasi lingkungan yang berfungsi cukup (alami atau
                        mekanis) tersedia di area perawatan pasien"
                      :selected.sync="
                        energi.Ventilation[
                          'Ventilasi_lingkungan_yang_berfungsi_cukup__alami_atau_mekanis__tersedia_di_area_perawatan_pasien'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[78]"
                      :tooltip_desc="`'Bangunan harus ditempatkan dan dibangun menggunakan
                            desain dan bahan yang menghasilkan kondisi dalam
                            ruangan terbaik (misalnya jendela yang lebih besar,
                            overhang besar untuk naungan di iklim yang lebih
                            panas), dengan mempertimbangkan iklim lokal dan
                            angin yang berlaku. Bangunan dapat ditingkatkan
                            dengan penggunaan tirai yang efektif, membuka dan
                            menutup pintu dan jendela, penanaman vegetasi yang
                            sesuai di sekitar gedung dan langkah-langkah
                            operasional lainnya untuk membantu mengoptimalkan
                            kondisi dalam ruangan. Jika iklim memungkinkan,
                            jendela terbuka besar, skylight, dan ventilasi
                            lainnya dapat digunakan untuk mengoptimalkan
                            ventilasi alami. Kipas langit-langit dan ventilator
                            portabel kecil tidak disarankan karena mengeluarkan
                            debu di sekitar ruangan (terutama untuk area
                            steril). Meningkatkan ventilasi mengurangi
                            ketergantungan pada AC. WHO 2009. Ventilasi alami
                            untuk pengendalian infeksi dalam pengaturan
                            perawatan kesehatan.
                            https://apps.who.int/iris/bitstream/handle/10665/44167/9789241547857_eng.pdf?sequence=1
                          `"
                      :options="[
                        {
                          label:
                            'Ventilasi cukup dan berfungsi di semua area pasien',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa ventilasi tersedia tetapi tidak terawat dengan baik atau tidak cukup untuk menghasilkan ventilasi udara alami',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada ventilasi',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Vector Control</b>
                    </div>

                    <QuestionCard
                      title="Vector Control"
                      description="[Di daerah endemis malaria] Tempat tidur memiliki
                        kelambu yang dirawat insektisida untuk melindungi pasien
                        dari penyakit bawaan nyamuk"
                      :selected.sync="
                        energi.Vector_Control[
                          'Tempat_tidur_memiliki_kelambu_yang_dirawat_insektisida_untuk_melindungi_pasien_dari_penyakit_bawaan_nyamuk'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[79]"
                      :tooltip_desc="`'Insecticide-treated nets should be washed and
                            re-impregnated every 6 months if used only for
                            patients with non-infectious diseases. For patients
                            with infectious diseases such as cholera, mosquito
                            nets are not advisable because the staff member
                            needs access to the patient. Other methods such as
                            indoor residual spraying or coils will be needed.
                            Nets are only provided exceptionally (patient
                            request, patient comfort, etc.) and burned after
                            use.
                          `"
                      :options="[
                        {
                          label:
                            'Semua tempat tidur rawat inap memiliki jaring',
                          value: '1',
                        },
                        {
                          label:
                            'Tersedia di beberapa, tetapi tidak semua tempat tidur, atau tersedia tetapi dalam kondisi buruk',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada kelambu yang tersedia',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Procurement</b>
                    </div>

                    <QuestionCard
                      title="Procurement"
                      description="Pengadaan berkelanjutan (menggunakan pendekatan siklus
                        hidup) diterapkan di seluruh fasilitas"
                      :selected.sync="
                        energi.Procurement[
                          'Pengadaan_berkelanjutan__menggunakan_pendekatan_siklus_hidup__diterapkan_di_seluruh_fasilitas'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[80]"
                      :tooltip_desc="`'Pengadaan berkelanjutan termasuk menggunakan produk
                            yang tersedia secara lokal yang telah disertifikasi
                            oleh lembaga sertifikasi terakreditasi dan yang
                            sesuai dengan standar internasional dipilih. (Produk
                            tersebut harus aman digunakan, memenuhi persyaratan
                            toksisitas, tahan lama, hemat energi dan sumber
                            daya).
                          `"
                      :options="[
                        {
                          label:
                            'Pengadaan berkelanjutan secara konsisten diterapkan di seluruh fasilitas',
                          value: '1',
                        },
                        {
                          label:
                            'Pendekatan pengadaan berkelanjutan ada tetapi tidak diterapkan dengan baik',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada pendekatan yang ada',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Environment</b>
                    </div>

                    <QuestionCard
                      title="Environment"
                      description="Tempat sampah umum tersedia di semua area publik, sampah
                        secara teratur dikeluarkan dari interior dan eksterior
                        fasilitas, dan upaya dilakukan untuk meningkatkan dan
                        mempertahankan penampilan estetika fasilitas melalui
                        pengecatan, lansekap (tanaman) dan memastikan bahwa
                        semua peralatan dan barang-barang lainnya disimpan
                        dengan aman"
                      :selected.sync="
                        energi.Environment[
                          'Tempat_sampah_umum_tersedia_di_semua_area_publik'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Upaya dilakukan untuk menjaga penampilan fasilitas secara keseluruhan yang rapi, bebas dari sampah dan terawat dengan baik',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa upaya dilakukan untuk menunjukkan fasilitas tetapi lebih banyak yang bisa dilakukan.',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada upaya yang dilakukan untuk mempertahankan apperance fasilitas',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>
                </v-card>
              </v-tab-item>

              <!-- Tab 7 -->
              <v-tab-item value="tabs7">
                <v-card class="mh-300 custom_card pa-5" v-if="kerja">
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Management</b>
                    </div>

                    <QuestionCard
                      title="Management"
                      description="Fasyankes memiliki tim peningkatan kualitas yang
                        berfungsi/IPC atau WASH FIT"
                      :selected.sync="
                        kerja.Management[
                          'Fasyankes_memiliki_tim_peningkatan_kualitas_yang_berfungsi_IPC_atau_WASH_FIT'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[81]"
                      :tooltip_desc="`'Mungkin ada satu tim atau entitas terpisah untuk
                            QI, IPC dan WASH. <br />
                            Setidaknya satu anggota staf kebersihan/pengawas
                            kebersihan/perusahaan pembersih kontrak harus
                            diwakili dalam tim WASH FIT, IPC atau QI dan
                            terlibat dalam pengambilan keputusan/pengembangan
                            rencana perbaikan.
                          `"
                      :options="[
                        {
                          label:
                            'Tim ada, memiliki TOR yang jelas, bertemu secara teratur dengan kepemimpinan yang baik dan keputusan dicatat dan ditindaklanjuti',
                          value: '1',
                        },
                        {
                          label:
                            'Tim bertemu tetapi secara tidak teratur, informal, tidak memiliki TOR yang jelas, dll.',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada tim seperti itu dan/atau tidak ada titik fokus yang ada',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ==============================================================================================================  -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Personnel</b>
                    </div>

                    <QuestionCard
                      title="Personnel"
                      description="Fasyankes memiliki focal point atau tenaga teknis WASH
                        khusus yang bekerja untuk program kerja yang disetujui,
                        dengan dukungan pimpinan senior"
                      :selected.sync="
                        kerja.Personnel[
                          'Fasyankes_memiliki_focal_point_atau_tenaga_teknis_WASH_khusus_yang_bekerja_untuk_program_kerja_yang_disetujui_dengan_dukungan_pimpinan_senior'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[82]"
                      :tooltip_desc="`'Titik fokus WASH/IPC harus bertanggung jawab atas
                            pemeliharaan WASH dan infrastruktur limbah perawatan
                            kesehatan. <br />
                            Di rumah sakit, juga harus ada focal person IPC
                            khusus selain focal point WASH. <br />
                            Kerangka Penilaian IPC WHO (IPCAF)
                            (https://www.who.int/infection-prevention/tools/core-components/IPCAF-facility.PDF)
                            dapat diselesaikan. IPCAF adalah alat sistematis
                            yang dapat memberikan penilaian dasar terhadap
                            program dan kegiatan IPC di dalam fasilitas
                            perawatan kesehatan, serta evaluasi berkelanjutan
                            melalui administrasi berulang untuk
                            mendokumentasikan kemajuan dari waktu ke waktu dan
                            memfasilitasi perbaikan. Menambahkan dukungan
                            kepemimpinan
                          `"
                      :options="[
                        {
                          label: 'Ada orang fokus yang berdedikasi',
                          value: '1',
                        },
                        {
                          label:
                            'Focal point ada tetapi tidak memiliki waktu, sumber daya atau motivasi yang cukup untuk melaksanakan tugas',
                          value: '2',
                        },
                        {
                          label: 'Tidak',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Management</b>
                    </div>
                    <QuestionCard
                      title="Management"
                      :description="'Kelompok perempuan, disabilitas dan masyarakat adat, serta pengguna dan staf khusus lainnya (misalnya perawat, bidan, petugas kebersihan) dikonsultasikan tentang kebutuhan WASH dan desain teknologi, dan suara-suara ini memengaruhi pilihan, penempatan, dan pemeliharaan teknologi'"
                      :selected.sync="
                        kerja.Management[
                          'Kelompok_perempuan_disabilitas_dan_masyarakat_adat_serta_pengguna_dan_staf_khusus_lainnya'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Groups are adequately consulted and voices influence improvements',
                          value: '1',
                        },
                        {
                          label:
                            'Only some groups are consulted and/or voices do not influence improvements',
                          value: '2',
                        },
                        {
                          label: 'None of the above are consulted',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Management"
                      description="Diagram terbaru dari struktur manajemen fasilitas,
                        termasuk staf kebersihan, terlihat jelas dan terbaca"
                      :selected.sync="
                        kerja.Management[
                          'Diagram_terbaru_dari_struktur_manajemen_fasilitas_termasuk_staf_kebersihan_terlihat_jelas_dan_terbaca'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Struktur manajemen fasilitas terkini ada (dan terbaca)',
                          value: '1',
                        },
                        {
                          label:
                            'Struktur manajemen ada tetapi tidak up to date atau tidak terlihat',
                          value: '2',
                        },
                        {
                          label: 'Tidak tersedia',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Management"
                      description="Semua staf tambahan, termasuk penangan limbah dan mereka
                        yang membersihkan, memiliki deskripsi pekerjaan yang
                        jelas dan tertulis, yang menguraikan tanggung jawab WASH
                        dan IPC"
                      :selected.sync="
                        kerja.Management[
                          'Semua_staf_tambahan_termasuk_penangan_limbah_dan_mereka_yang_membersihkan_memiliki_deskripsi_pekerjaan_yang_jelas_dan_tertulis_yang_menguraikan_tanggung_jawab_WASH_dan_IPC'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Semua staf memiliki deskripsi pekerjaan tertulis termasuk tanggung jawab WASH dan IPC',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa, tetapi tidak semua, staf memiliki deskripsi pekerjaan',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada deskripsi pekerjaan',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Personnel</b>
                    </div>

                    <QuestionCard
                      title="Personnel"
                      description="Semua staf tambahan baru, termasuk penangan limbah dan
                        mereka yang membersihkan, menerima pelatihan WASH dan
                        IPC yang sesuai, disesuaikan dengan fungsi pekerjaan
                        mereka"
                      :selected.sync="
                        kerja.Personnel[
                          'Semua_staf_tambahan_baru_termasuk_penangan_limbah_dan_mereka_yang_membersihkan_menerima_pelatihan_WASH_dan_IPC_yang_sesuai_disesuaikan_dengan_fungsi_pekerjaan_mereka'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Semua staf baru dilatih secara memadai, sesuai dengan fungsinya',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa tetapi tidak semua staf dilatih atau pelatihan tidak sesuai dengan fungsinya',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada pelatihan yang berlangsung',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Personnel"
                      description="Staf secara teratur (setidaknya setiap tahun) dinilai
                        berdasarkan kinerja mereka (misalnya kebersihan tangan);
                        staf berkinerja tinggi diakui dan / atau dihargai, dan
                        mereka yang tidak berkinerja baik didukung untuk
                        meningkatkan"
                      :selected.sync="
                        kerja.Personnel[
                          'Staf_secara_teratur__setidaknya_setiap_tahun__dinilai_berdasarkan_kinerja_mereka__misalnya_kebersihan_tangan_'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[83]"
                      :tooltip_desc="`'Kinerja mungkin dalam kaitannya dengan kepatuhan
                            kebersihan tangan, serta kasih sayang, mengikuti
                            prosedur medis yang benar, mendengarkan pasien, dll.
                          `"
                      :options="[
                        {
                          label:
                            'Staf dinilai secara teratur (setidaknya setiap tahun)',
                          value: '1',
                        },
                        {
                          label:
                            'Beberapa tetapi tidak semua staf dinilai atau staf tidak cukup didukung untuk meningkatkan',
                          value: '2',
                        },
                        {
                          label:
                            'Tidak ada penilaian staf yang dilakukan, yaitu tidak ada tindakan atau pengakuan staf berdasarkan kinerja',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">SOPs</b>
                    </div>
                    <QuestionCard
                      title="SOPs"
                      description="Protokol dan sistem yang efektif tersedia untuk operasi
                        dan pemeliharaan infrastruktur yang berkelanjutan dan
                        pengadaan persediaan yang diperlukan untuk operasi dan
                        pemeliharaan"
                      :selected.sync="
                        kerja.SOP[
                          'Protokol_dan_sistem_yang_efektif_tersedia_untuk_operasi_dan_pemeliharaan_infrastruktur_yang_berkelanjutan_dan_pengadaan_persediaan_yang_diperlukan_untuk_operasi_dan_pemeliharaan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[84]"
                      :tooltip_desc="`'Protokol ini mungkin terkait dengan pembersihan
                            lingkungan (disesuaikan untuk area layanan yang
                            berbeda), penanganan limbah dan operasi &
                            pemeliharaan pasokan air dan sanitasi. <br /><br />

                            Manajer program kebersihan, tim pengadaan fasilitas
                            dan/atau IPC fasilitas atau komite kebersihan harus
                            mengembangkan daftar induk persediaan dan peralatan
                            (yaitu spesifikasi terperinci dan informasi pemasok)
                            dan jumlah yang diperlukan (misalnya setiap tahun)
                            yang diperlukan. Hasil inspeksi rutin dan kegiatan
                            pemeliharaan harus menentukan jumlah persediaan dan
                            peralatan yang diperlukan. Fasilitas besar mungkin
                            memiliki toko pusat yang menerima persediaan dan
                            peralatan dan mendistribusikannya ke area layanan
                            pembersihan lingkungan yang ditunjuk di seluruh
                            fasilitas secara teratur setelah laporan inventaris.
                            Memiliki sistem seperti itu juga akan mencegah
                            kehabisan stok dan membantu memperoleh pasokan
                            tambahan sesuai kebutuhan selama keadaan darurat /
                            kontinjensi.
                          `"
                      :options="[
                        {
                          label:
                            'Sistem ada dan berfungsi (item diperoleh dan infrastruktur diperbaiki saat dan bila diperlukan)',
                          value: '1',
                        },
                        {
                          label:
                            'Sistem ada tetapi tidak berfungsi (yaitu fasilitas tidak dapat memperoleh pasokan atau infrastruktur tidak diperbaiki secara memadai)',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada sistem',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Budget</b>
                    </div>

                    <QuestionCard
                      title="Budget"
                      description="Anggaran tersedia untuk menutupi biaya pembersih dan
                        staf pemeliharaan, pelatihan IPC/WASH, bahan habis pakai
                        IPC/WASH (misalnya sabun, klorin) dan semua kegiatan
                        yang tercantum dalam protokol pengadaan."
                      :selected.sync="
                        kerja.Budget[
                          'Anggaran_tersedia_untuk_menutupi_biaya_pembersih_dan_staf_pemeliharaan'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[85]"
                      :tooltip_desc="`'Anggaran tersebut mencakup biaya modal dan
                            operasional, termasuk personel, pelatihan staf,
                            perlengkapan dan peralatan kebersihan, peralatan
                            untuk pemantauan program, biaya administrasi, biaya
                            produksi dan pencetakan untuk daftar periksa, log
                            dan alat bantu pekerjaan lainnya dan biaya
                            infrastruktur/layanan (misalnya layanan air dan air
                            limbah). Mungkin berguna untuk membagi anggaran ke
                            dalam kategori: personel; infrstruktur; peralatan;
                            Persediaan.
                          `"
                      :options="[
                        {
                          label:
                            'Anggaran ada dan ditujukan untuk staf/pelatihan dan bahan habis pakai/O&M',
                          value: '1',
                        },
                        {
                          label:
                            'Anggaran ada untuk staf tetapi tidak untuk pelatihan / atau untuk bahan habis pakai tetapi tidak untuk O&M / atau anggaran tidak cukup untuk menutupi semua biaya.',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada anggaran',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Policies</b>
                    </div>

                    <QuestionCard
                      title="Policies"
                      description="Kebijakan/piagam keselamatan pasien di seluruh Fasyankes
                        untuk meningkatkan kualitas perawatan ditulis, terkini,
                        dan operasional"
                      :selected.sync="
                        kerja.Policies[
                          'Kebijakan_piagam_keselamatan_pasien_di_seluruh_Fasyankes_untuk_meningkatkan_kualitas_perawatan_ditulis_terkini_dan_operasional'
                        ][0].dataPenyusun_desc
                      "
                      :options="[
                        {
                          label:
                            'Kebijakan tersedia, up-to-date dan operasional',
                          value: '1',
                        },
                        {
                          label:
                            'Kebijakan tidak operasional, atau perlu diperbarui/ tidak realistis',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada kebijakan',
                          value: '3',
                        },
                      ]"
                    />

                    <QuestionCard
                      title="Policies"
                      description="Kebijakan/piagam ramah lingkungan di seluruh Fasyankes
                        ditulis dan beroperasi"
                      :selected.sync="
                        kerja.Policies[
                          'Kebijakan_piagam_ramah_lingkungan_di_seluruh_Fasyankes_ditulis_dan_beroperasi'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[86]"
                      :tooltip_desc="`'Konsep ketahanan iklim harus diintegrasikan ke
                            dalam semua strategi dan rencana WASH fasilitas.
                          `"
                      :options="[
                        {
                          label: 'Kebijakan tertulis dan operasional',
                          value: '1',
                        },
                        {
                          label: 'Kebijakan tertulis tetapi tidak operasional',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada kebijakan',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Emergency preparedness</b>
                    </div>

                    <QuestionCard
                      title="Emergency preparedness"
                      description="Rencana kesiapsiagaan dan tanggap darurat sudah ada,
                        dianggarkan dan diperbarui secara berkala; Staf
                        menjalani pelatihan dan latihan untuk mempersiapkan,
                        menanggapi, dan pulih dari peristiwa terkait cuaca
                        ekstrem, terutama di mana perubahan iklim merupakan
                        faktor yang berkontribusi"
                      :selected.sync="
                        kerja.Emergency_Preparedness[
                          'Rencana_kesiapsiagaan_dan_tanggap_darurat_sudah_ada_dianggarkan_dan_diperbarui_secara_berkala'
                        ][0].dataPenyusun_desc
                      "
                      :tooltipSelected.sync="showTooltip[87]"
                      :tooltip_desc="`'Rencana darurat diperbarui secara berkala dan
                            berulang berdasarkan informasi baru & data iklim dan
                            kerentanan. Sistem tersedia untuk menindaklanjuti
                            saran dan peringatan cuaca ekstrem untuk mengurangi
                            risiko kesehatan. Rencana tersebut harus mencakup
                            langkah-langkah untuk mendapatkan pasokan ketika
                            permintaan / beban pasien meningkat, terkait dengan
                            <br />
                            - sistem air (misalnya klorin, filter atau teknologi
                            pengolahan air lainnya rapid water testing kit)
                            <br />
                            - kebersihan tangan (sabun, gosok tangan berbasis
                            alkohol, stasiun kebersihan tangan, dll.) <br />
                            - pembersihan lingkungan (bahan kimia pembersih,
                            pel, ember, dll.). <br />
                            Pertimbangan lain termasuk ketersediaan tenaga
                            kesehatan siaga saat dibutuhkan, ketersediaan APD
                            ekstra, sistem untuk mengoperasikan fasilitas
                            kesehatan 24/7 selama keadaan darurat, atau
                            persiapan untuk mendukung selama COVID-19 seperti
                            pandemi.
                          `"
                      :options="[
                        {
                          label: 'Rencana sudah ada dan staf cukup terlatih',
                          value: '1',
                        },
                        {
                          label:
                            'Rencana sudah ada tetapi bukan pelatihan yang dilakukan, atau rencana tidak realistis, atau tidak dilaksanakan',
                          value: '2',
                        },
                        {
                          label: 'Tidak ada rencana',
                          value: '3',
                        },
                      ]"
                    />
                  </v-card>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>

        <div class="paginat">
          <div class="button-pag">
            <div>
              <v-btn
                color="white"
                dark
                class="pa-3 custom_shadow rounded-lg text-capitalize"
                @click="tabKembali"
              >
                <p class="ma-0 color_default ml-2">Kembali</p>
              </v-btn>
            </div>
            <div>
              <p class="ma-0 color_default">{{ paginNow }} / 7</p>
            </div>

            <div>
              <v-btn
                v-if="tabs != 'tabs7'"
                min-width="10px"
                color="#00b4cc"
                dark
                class="pa-2 custom_shadow rounded-lg text-capitalize"
                @click="tabLanjut"
              >
                <p class="ma-0 mr-2">Lanjut</p>
              </v-btn>

              <v-btn
                v-if="tabs == 'tabs7'"
                min-width="10px"
                color="#00b4cc"
                dark
                class="pa-2 custom_shadow rounded-lg text-capitalize"
                @click="setupForm(false)"
              >
                <p class="ma-0 mr-2">Kirim</p>
              </v-btn>
            </div>
          </div>
        </div>

        <div>
          <success-alert v-bind:dialogSuccess="dialogSuccess" />
          <Alert
            v-bind:dialogAlert="dialogAlert"
            v-bind:temporary="temporary"
            v-bind:body="tobeSent"
            @close="dialogAlert = false"
            @save="savePermanent()"
          />
          <v-overlay
            :absolute="true"
            style="height: 100vh; position: fixed"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="#00b4cc"
            ></v-progress-circular>
            <b class="color_default">Loading...</b>
          </v-overlay>
        </div>
      </v-container>
    </div>

    <div v-else>
      <div class="side_toolbar">
        <div>
          <div class="item_toolbar" @click="setupForm(true)">
            <v-progress-circular
              v-if="loadTempo"
              indeterminate
              color="#00b4cc"
            ></v-progress-circular>
            <v-icon color="#00b4cc" v-else large
              >mdi-content-save-outline</v-icon
            >
          </div>
        </div>
      </div>
      <div class="d-flex justify-center">
        <div style="margin-top: 50px; width: 85%" class="pb-5">
          <v-stepper
            v-model="step"
            elevation="0"
            flat
            class="temp_stepper_header"
          >
            <div class="d-flex">
              <div
                :class="
                  step == 1
                    ? 'active_tab px-3 py-5 mr-1'
                    : step > 4
                    ? 'mini_tab px-3 py-5'
                    : 'header_tabs px-3 py-5 mr-1'
                "
              >
                <div :class="step == 1 ? 'number_active' : 'number_unactive'">
                  1
                </div>
                <p class="ma-0 small_txt text-center" v-if="step < 4">Air</p>
              </div>
              <div
                :class="
                  step == 2
                    ? 'active_tab px-3 py-5 mx-1'
                    : step > 5
                    ? 'mini_tab px-3 py-5'
                    : 'header_tabs px-3 py-5 mx-1'
                "
              >
                <div :class="step == 2 ? 'number_active' : 'number_unactive'">
                  2
                </div>
                <p class="ma-0 small_txt text-center" v-if="step < 5">
                  Sanitasi
                </p>
              </div>
              <div
                :class="
                  step == 3
                    ? 'active_tab px-3 py-5 mx-1'
                    : step > 6
                    ? 'mini_tab px-3 py-5'
                    : 'header_tabs px-3 py-5 mx-1'
                "
              >
                <div :class="step == 3 ? 'number_active' : 'number_unactive'">
                  3
                </div>
                <p class="ma-0 small_txt text-center" v-if="step < 6">
                  Pengelolaan Limbah medis
                </p>
              </div>
              <div
                :class="
                  step == 4
                    ? 'active_tab px-3 py-5 mx-1'
                    : 'header_tabs px-3 py-5 mx-1'
                "
              >
                <div :class="step == 4 ? 'number_active' : 'number_unactive'">
                  4
                </div>
                <p class="ma-0 small_txt text-center">Kebersihan Tangan</p>
              </div>
              <div
                :class="
                  step == 5
                    ? 'active_tab px-3 py-5 mx-1'
                    : step > 5
                    ? 'header_tabs px-3 py-5 mx-1'
                    : 'mini_tab px-3 py-5'
                "
              >
                <div :class="step == 5 ? 'number_active' : 'number_unactive'">
                  5
                </div>
                <p class="ma-0 small_txt text-center" v-if="step > 4">
                  Kebersihan Lingkungan
                </p>
              </div>
              <div
                :class="
                  step == 6
                    ? 'active_tab px-3 py-5 mx-1'
                    : step > 6
                    ? 'header_tabs px-3 py-5 mx-1'
                    : 'mini_tab px-3 py-5'
                "
              >
                <div :class="step == 6 ? 'number_active' : 'number_unactive'">
                  6
                </div>
                <p class="ma-0 small_txt text-center" v-if="step > 5">
                  Energi dan Lingkungan
                </p>
              </div>
              <div
                :class="
                  step == 7
                    ? 'active_tab px-3 py-5 ml-1'
                    : step > 6
                    ? 'header_tabs px-3 py-5 ml-1'
                    : 'mini_tab px-3 py-5'
                "
              >
                <div :class="step == 7 ? 'number_active' : 'number_unactive'">
                  7
                </div>
                <p class="ma-0 small_txt text-center" v-if="step > 6">
                  Manajemen dan tenaga Kerja
                </p>
              </div>
            </div>
            <v-stepper-items>
              <!-- ========================================STEP 1 ============================================= -->
              <v-stepper-content step="1" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat v-if="air">
                  <!-- Tab1Q1 ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Pasokan Air</b>
                    </div>
                    <!-- W_1a -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3">
                      <b>Pasokan Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Pasokan air dari sumber yang layak disalurkan ke
                        Fasyankes atau terletak di tempat
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[0]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Peningkatan pasokan air didefinisikan oleh Program
                            Pemantauan Bersama WHO/UNICEF yang berdasarkan sifat
                            desain dan konstruksinya memiliki potensi untuk
                            menghasilkan air yang aman. Contohnya meliputi: air
                            perpipaan, keran umum; sumur gali yang terlindungi;
                            sumur tabung; atau lubang bor, air hujan dan air
                            kemasan atau yang dikirim. Baik hijau atau kuning
                            akan dihitung sebagai layanan air dasar, jika air
                            juga tersedia, yaitu W_3b kuning atau hijau).</span
                          >
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Pasokan_Air[
                            'Pasokan_air_dari_sumber_yang_layak_disalurkan_ke_Fasyankes_atau_terletak_di_tempat'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Pasokan air dari sumber yang layak dapat diakses di tempat (di dalam gedung Fasyakes)"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Pasokan air dari sumber yang layak dapat diakses di tempat (tetapi di luar gedung Fasyankes)"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada sumber air dari sumber yang layak yang dapat diakses di lokasi"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_1b -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mt-3">
                      <b>Pasokan Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Fasyankes ini memiliki persediaan air perpipaan di
                        lokasi
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[1]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Untuk rumah sakit perawatan sekunder/tersier, air
                            perlu disalurkan ke Fasyankes, minimal ke bangsal
                            dan area layanan berisiko tinggi (misalnya, area
                            dekontaminasi/pemrosesan ulang dan area layanan
                            lingkungan). <br />
                            <br />
                            Di Fasyankes besar, harus ada keran fungsional yang
                            tersedia di area di setiap lantai dan setiap bangsal
                            utama atau sayap Fasyankes, untuk tujuan pembersihan
                            lingkungan. <br />
                            <br />
                            Untuk Fasyankes primer, baik hijau atau kuning akan
                            dihitung sebagai layanan air dasar tetapi untuk
                            Fasyankes sekunder atau tersier yang telah
                            meningkatkan air, tetapi tidak disalurkan, akan
                            berwarna merah. Meskipun ini dihitung sebagai
                            layanan air dasar untuk tujuan pemantauan JMP,
                            Fasyankes sekunder atau lebih tinggi tanpa air
                            perpipaan tidak dianggap memenuhi persyaratan
                            tingkat layanan minimum</span
                          >
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Pasokan_Air[
                            'Fasyankes_ini_memiliki_persediaan_air_perpipaan_di_lokasi'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Air disalurkan ke dalam Fasyankes ke semua bangsal berisiko tinggi (bersalin, ruang operasi / OR, perawatan intensif / ICU)"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Air disalurkan ke dalam tetapi tidak ke semua bangsal berisiko tinggi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada pasokan air perpipaan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- Tab1Q2 ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Pasokan Air / Leding</b>
                    </div>
                    <!-- W_2 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Pasokan Air / Leding</b>
                      <p class="ma-0 color_txt small_txt">
                        Semua keran terhubung ke pasokan air yang tersedia dan
                        berfungsi, tanpa kebocoran pada pipa
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[2]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Pipa air harus diperiksa secara teratur dan sistem
                            di tempat untuk memperbaiki kebocoran segera setelah
                            ditemukan.<br /><br />
                            Di Fasyankes besar, harus ada keran fungsional yang
                            tersedia di area konsultasi di setiap lantai dan
                            setiap bangsal utama atau sayap Fasyankes, untuk
                            tujuan pembersihan lingkungan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Pasokan_Air_Leding[
                            'Semua_keran_terhubung_ke_pasokan_air_yang_tersedia_dan_berfungsi_tanpa_kebocoran_pada_pipa'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua keran terhubung dan berfungsi"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Lebih dari setengah dari semua keran terhubung dan berfungsi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kurang dari setengah dari semua ketukan terhubung dan berfungsi"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- Tab1Q3 ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Ketersediaan Air</b>
                    </div>
                    <!-- W_3a -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Ketersediaan air</b>
                      <p class="ma-0 color_txt small_txt">
                        Air tersedia selama jam pelayanan Fasyankes
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[3]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Air harus tersedia di Fasyankes untuk semua hari /
                            jam buka dan memberikan perawatan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Ketersediaan_Air[
                            'Air_tersedia_selama_jam_pelayanan_Fasyankes'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Air tersedia 7 hari seminggu, setiap hari dan sepanjang hari"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Air tersedia 4 hari/minggu dan/atau tidak sepanjang hari"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Air tersedia kurang dari 4 hari per minggu dan/atau tidak tersedia selama lebih dari setengah hari"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_3b -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Ketersediaan Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Air tersedia pada saat penilaian WASH FIT dilakukan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[4]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Pertanyaan ini sejalan dengan pertanyaan yang
                            direkomendasikan JMP "Apakah air tersedia dari
                            pasokan air utama
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Ketersediaan_Air[
                            'Air_tersedia_pada_saat_penilaian_WASH_FIT_dilakukan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Air tersedia di seluruh Fasyankes"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Air tersedia dari beberapa tetapi tidak semua titik air"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada air yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_4 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Ketersediaan Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Air tersedia sepanjang tahun (yaitu tidak terpengaruh
                        oleh musim, variabilitas cuaca/peristiwa ekstrem atau
                        kendala lainnya)
                      </p>
                      <v-radio-group
                        v-model="
                          air.Ketersediaan_Air[
                            'Air_tersedia_sepanjang_tahun_yaitu_tidak_terpengaruh_oleh_musim_variabilitas_cuaca_peristiwa_ekstrem_atau_kendala_lainnya'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Air tersedia sepanjang tahun"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kekurangan air selama satu hingga dua bulan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kekurangan air selama tiga bulan atau lebih"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_5 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Ketersediaan Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Sistem pasokan air utama berfungsi selama 3 bulan
                        terakhir tanpa kerusakan besar
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[6]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Kerusakan adalah tidak ada pengiriman air ATAU
                            sistem memberikan kurang dari 50% dari hasil air
                            desain.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Ketersediaan_Air[
                            'Sistem_pasokan_air_utama_berfungsi_selama_3_bulan_terakhir_tanpa_kerusakan_besar'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Dalam 3 bulan terakhir sistem pasokan air utama tidak mengalami kerusakan atau ada kerusakan tetapi diperbaiki dalam waktu 48 jam."
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sistem pasokan air mengalami kerusakan tetapi diperbaiki dalam waktu satu minggu."
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sistem pasokan air mengalami kerusakan yang membutuhkan waktu lebih dari satu minggu untuk memperbaiki ATAU tetap tidak diperbaiki"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_6 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Ketersediaan Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Sumber air layak tambahan diidentifikasi, tersedia, dan
                        dapat diakses (dan pelihara secara memadai) jika sumber
                        utama tidak berfungsi/tersedia
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[7]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Pengolahan air mungkin diperlukan untuk persediaan
                            cadangan karena mungkin memiliki arsenik tinggi atau
                            masalah serupa.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Ketersediaan_Air[
                            'Sumber_air_layak_tambahan_diidentifikasi_tersedia_dan_dapat_diakses_dan_pelihara_secara_memadai_jika_sumber_utama_tidak_berfungsi_tersedia'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Sumber air layak tambahan diidentifikasi, tersedia, dan dapat diakses"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sumber air tambahan diidentifikasi tetapi tidak layak atau layak tetapi tidak mudah diakses"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada sumber air tambahan yang tersedia "
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_7 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Ketersediaan Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Memiliki air dalam jumlah yang cukup untuk semua
                        penggunaan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[8]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Kebutuhan air akan bervariasi tergantung pada jenis
                            Fasyankes dan jumlah pasien.<br /><br />
                            Untuk menghitung kebutuhan air Fasyankes, tambahkan
                            persyaratan berikut atau standar nasional yang
                            berlaku (perhatikan bahwa persyaratan dapat
                            bervariasi pada bulan yang berbeda dalam setahun
                            sehingga mendasarkan perhitungan pada bulan yang
                            membutuhkan air paling banyak): Pasien rawat jalan
                            (5 L/konsultasi) + rawat inap (40–60 L/pasien/hari)
                            + ruang operasi atau unit bersalin (100
                            L/intervensi) + pusat pemberian makanan kering atau
                            tambahan (0,5–5 L/konsultasi tergantung waktu
                            tunggu) + pusat perawatan kolera (60 L/pasien/hari).
                            Sumber: Standar lingkungan esensial dalam perawatan
                            kesehatan (WHO, 2008).<br /><br />

                            Jumlah ini memperhitungkan air yang diperlukan untuk
                            minum, pembersihan lingkungan dan binatu, kebersihan
                            tangan dan pengelolaan limbah. Catatan, air untuk
                            penggunaan medis tertentu (seperti dialisis) tidak
                            termasuk.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Ketersediaan_Air[
                            'Memiliki_air_dalam_jumlah_yang_cukup_untuk_semua_penggunaan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Memiliki air dalam jumlah yang cukup untuk semua penggunaan di seluruh Fasyankes"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Jumlah air cukup untuk 75% kebutuhan (di semua bangsal dan penggunaan)"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Jumlah air kurang dari 75% cukup"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_8 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Ketersediaan Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Fasyankes ini memiliki tangki untuk menyimpan air jika
                        terjadi gangguan pada pasokan utama, dan tangki
                        penyimpanan air dilindungi (misalnya dari peristiwa
                        cuaca ekstrem terkait iklim) dan dikelola secara memadai
                        (misalnya diperiksa, dibersihkan / didesinfeksi secara
                        teratur), dan cukup untuk memenuhi kebutuhan Fasyankes
                        selama 2 hari
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[9]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Penyimpanan air harus dilindungi dari kontaminasi
                            dan mampu menahan peristiwa cuaca ekstrem.<br /><br />

                            Untuk menghitung kebutuhan penyimpanan air
                            Fasyankes, hitung persyaratan (indikator W_7) yang
                            dibutuhkan selama 24 jam dan kalikan dengan 2 untuk
                            mendapatkan total selama 48 jam. <br /><br />

                            Penyimpanan cadangan tambahan harus disediakan
                            selama periode berisiko tinggi. Jika memungkinkan,
                            penyimpanan lebih dari 2 hari harus disediakan dan
                            air diprioritaskan untuk layanan
                            penting/menyelamatkan jiwa (yaitu ruang bersalin
                            bangsal perawatan akut).</span
                          >
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Ketersediaan_Air[
                            'Fasyankes_ini_memiliki_tangki_untuk_menyimpan_air_jika_terjadi_gangguan_pada_pasokan_utama'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Air memiliki jumlah yang cukup untuk semua penggunaan di seluruh Fasyankes"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Cukup untuk dua hari tetapi tidak dilindungi atau dilindungi tetapi hanya cukup untuk satu hari"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Penyimpanan tersedia kurang dari satu hari yang dibutuhkan atau tidak ada yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_9 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Ketersediaan Air</b>
                      <p class="ma-0 color_txt small_txt">
                        [Di mana curah hujan cukup dan teratur] Sistem pemanenan
                        air hujan (dengan penyimpanan yang aman) berfungsi dan
                        menyimpan air dengan aman
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[10]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Selokan dan atap yang digunakan untuk resapan air
                            hujan dan tangki penyimpanan harus dibersihkan
                            secara teratur setidaknya setiap bulan atau sesuai
                            kebutuhan selama badai besar dan curah hujan. Sistem
                            juga harus menggunakan perangkat flush pertama yang
                            dirancang untuk mengalihkan bagian pertama dari air
                            hujan yang terkontaminasi sehingga tidak masuk ke
                            tangki penyimpanan dan kotak filter.</span
                          >
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Ketersediaan_Air[
                            'Sistem_pemanenan_air_hujan__dengan_penyimpanan_yang_aman__berfungsi_dan_menyimpan_air_dengan_aman'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Pemanenan air hujan dengan penyimpanan yang aman ada dan fungsional"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sistem pemanenan air hujan ada tetapi penyimpanan tidak aman atau cukup atau ada kebocoran"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada pemanenan air hujan yang digunakan (meskipun air hujan tersedia)"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- Tab1Q4 ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Konservasi Air</b>
                    </div>
                    <!-- W_10 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Konservasi Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Strategi penghematan air digunakan untuk mengurangi
                        pemborosan air.
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[11]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Strategi pengurangan air termasuk penggunaan
                            wastafel efisiensi tinggi dan aliran air rendah
                            untuk mencuci tangan, mesin cuci rendah air (dan
                            strategi lain untuk mencuci dan membersihkan),
                            memastikan pipa dan perlengkapan tidak bocor (dan
                            menggunakan sistem untuk melaporkan dan memperbaiki
                            keran yang bocor pada hari yang sama), memeriksa
                            meter untuk menganalisis penggunaan air, dan
                            menggunakan greywater dan / atau air hujan jika
                            tersedia untuk menyiram toilet, membersihkan area
                            trotoar luar ruangan, tanaman air, dll.<br /><br />

                            Dalam kasus peristiwa iklim atau keadaan darurat,
                            tindakan tambahan mungkin diperlukan, misalnya air
                            diprioritaskan untuk layanan penting/menyelamatkan
                            jiwa (yaitu ruang bersalin, bangsal perawatan akut);
                            Pengguna prioritas diidentifikasi jika terjadi
                            kekurangan (pengguna prioritas mungkin termasuk ibu
                            bersalin, anak kecil, orang tua atau kurang gizi).
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Konservasi_Air[
                            'Strategi_penghematan_air_digunakan_untuk_mengurangi_pemborosan_air'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Strategi penghematan air digunakan secara efektif dan pemborosan air dihindari"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Strategi pengurangan air digunakan tetapi masih ada beberapa pemborosan air yang dapat dihindari"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada strategi pengurangan air yang digunakan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- Tab1Q5 ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Air Minum</b>
                    </div>
                    <!-- W_11 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Air Minum</b>
                      <p class="ma-0 color_txt small_txt">
                        [Di mana desinfeksi klorin dilakukan] Air minum memiliki
                        residu klorin bebas yang sesuai (≥0,2 mg/L atau ≥0,5
                        mg/L dalam keadaan darurat)
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[12]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Air minum harus memenuhi Pedoman WHO untuk Kualitas
                            Air Minum (2017) atau standar nasional:
                            https://www.who.int/water_sanitation_health/publications/drinking-water-quality-guidelines-4-including-1st-addendum/en/
                            Residu klorin harus sering diukur dan dosisnya
                            disesuaikan jika residu tidak terpenuhi (perubahan
                            pH, suhu, kandungan organik dan sumber air akan
                            mempengaruhi kemanjuran klorin). Bukti residu klorin
                            yang terdokumentasi harus tersedia dari pengujian
                            sebelumnya. Jika terjadi banjir, klorin saja tidak
                            akan mendisinfeksi air secukupnya karena airnya
                            kemungkinan terlalu keruh. Untuk desinfeksi yang
                            efektif, harus ada konsentrasi sisa klorin bebas ≥
                            0,5 mg / l setelah setidaknya 30 menit waktu kontak
                            pada pH &lt; 8,0. Residu klorin harus dipertahankan
                            di seluruh sistem distribusi. Pada titik pengiriman,
                            konsentrasi residu minimum klorin bebas harus ≥0,2
                            mg / l. Penggunaan air yang aman (menurut pedoman
                            kualitas air minum WHO, yaitu, tidak ada E. yang
                            dapat dideteksi dalam 100 mL dan konsentrasi residu
                            klorin bebas ≥0,5 mg/l setelah setidaknya 30 menit
                            waktu kontak pada pH &lt; 8,0) meminimalkan risiko
                            paparan patogen terkait air yang berasal dari
                            enterik dan lingkungan (misalnya, Pseudomonas,
                            Legionella) dan harus tersedia untuk semua layanan
                            klinis; minimal, itu harus disediakan untuk bangsal
                            berisiko tinggi di mana beban HAI dan AMR tinggi dan
                            di semua area ruang bersalin.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Air_Minum[
                            'Air_minum_memiliki_residu_klorin_bebas_yang_sesuai'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Air minum tersedia dengan bebas sisa klorin yang sesuai"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Residu klorin bebas ada, tetapi <0,2mg / L"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak mengetahui residu/tidak memiliki kapasitas untuk menguji residu/tidak ada air minum yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_12 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Air Minum</b>
                      <p class="ma-0 color_txt small_txt">
                        Pasokan air menimbulkan risiko rendah atau tidak
                        beresiko sama sekali terhadap kesehatan masyarakat,
                        sebagaimana diukur dengan tidak adanya Ecoli per 100 mL
                        dan/atau yang diukur dengan skor risiko inspeksi
                        sanitasi.
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[13]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Drinking-water should meet WHO Guidelines for
                            Drinking-water Quality (2017) or national standards:
                            https://www.who.int/water_sanitation_health/publications/drinking-water-quality-guidelines-4-including-1st-addendum/en/
                            If the E. Coli reading is anything above 10/100ml,
                            you should re-test. <br /><br />

                            WASH FIT includes Sanitary Inspection (SI) forms for
                            4 technologies: tubewell with hand pump, deep
                            borehole with motorized pump, piped distribution and
                            storage, rain water harvesting. Select one or more
                            SI forms according to the facility's water
                            supply(ies).
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Air_Minum[
                            'Pasokan_air_menimbulkan_risiko_rendah_atau_tidak_beresiko_sama_sekali_terhadap_kesehatan_masyarakat'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Air minum bebas dari Ecoli ATAU risiko rendah menurut form inspeksi sanitasi "
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Air minum memiliki E.≤10 /100ml ATAU risiko sedang sesuai dengan bentuk SI"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="E.lebih dari >10/100ml /Tidak tahu apakah E.hadir /tidak memiliki kapasitas untuk menguji /tidak ada air minum yang tersedia ATAU berisiko tinggi menurut bentuk SI"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- Tab1Q6 ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Kualitas Air</b>
                    </div>

                    <!-- W_13 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Kualitas Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Air perpipaan diolah dengan pengelolaan air yang aman
                        oleh penyedia layanan/otoritas kota atau air diolah
                        secara teratur di tempat
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[14]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Drinking-water should meet WHO Guidelines for
                            Drinking-water Quality (2017) or national standards:
                            https://www.who.int/water_sanitation_health/publications/drinking-water-quality-guidelines-4-including-1st-addendum/en/
                            If the E. Coli reading is anything above 10/100ml,
                            you should re-test. <br /><br />

                            WASH FIT includes Sanitary Inspection (SI) forms for
                            4 technologies: tubewell with hand pump, deep
                            borehole with motorized pump, piped distribution and
                            storage, rain water harvesting. Select one or more
                            SI forms according to the facility's water
                            supply(ies).
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Kualitas_Air[
                            'Air_perpipaan_diolah_dengan_pengelolaan_air_yang_aman_oleh_penyedia_layanan_otoritas_kota_atau_air_diolah_secara_teratur_di_tempat'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Air diolah dan diatur melalui layanan perpipaan atau air diolah secara teratur dengan teknologi yang telah terbukti handal"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Air diolah dengan teknologi yang terbukti tetapi tidak secara teratur"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Air tidak diolah atau diolah dengan teknologi yang tidak memenuhi standar WHO"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_14 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Kualitas Air</b>
                      <p class="ma-0 color_txt small_txt">
                        Kualitas air dari semua pasokan air (persediaan primer,
                        cadangan dan tambahan) secara rutin diuji oleh staf /
                        dan atau otoritas independen (misalnya lembaga
                        pengawasan) sesuai dengan standar nasional
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[15]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Routine water quality testing should be carried
                            out. Verification may be carried out by a member of
                            staff from the facility or an external body.
                            Drinking water should meet WHO Guidelines for
                            Drinking-water Quality (2017) or national standards:
                            https://www.who.int/publications/i/item/9789241549950
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Kualitas_Air[
                            'Kualitas_air_dari_semua_pasokan_air__persediaan_primer_cadangan_dan_tambahan__secara_rutin_diuji_oleh_staf'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Water quality routinely and regularly tested according to national standards"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Water quality tested but not routinely or regularly"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="No testing takes place or no national standards exist"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- Tab1Q7 ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Air Minum</b>
                    </div>
                    <!-- W_15 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Air Minum</b>
                      <p class="ma-0 color_txt small_txt">
                        Dispenser ( station) air minum dengan air minum yang
                        aman tersedia dan berfungsi setiap saat di ruang tunggu
                        utama dan / atau pintu masuk ke setiap bangsal dan di
                        semua kamar tempat pasien menginap atau menerima
                        perawatan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[16]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Air minum yang aman, sebagaimana didefinisikan oleh
                            Pedoman WHO, tidak mewakili risiko signifikan
                            terhadap kesehatan selama konsumsi seumur hidup,
                            termasuk sensitivitas berbeda yang mungkin terjadi
                            di antara tahap kehidupan.<br /><br />

                            Tidak adanya kontaminasi tinja dapat ditunjukkan
                            dengan tidak adanya organisme indikator seperti E.
                            (atau coliform feses termotoleran). Jika ini tidak
                            dapat diuji, keberadaan residu klorin bebas
                            (setidaknya 0,2 mg/l) dapat digunakan sebagai
                            indikasi pengganti.<br /><br />

                            Air minum harus disimpan dengan aman dalam
                            ember/tangki bersih dengan penutup dan keran, yang
                            secara teratur dibersihkan dan didesinfeksi (kecuali
                            jika dikeluarkan dari air mancur). Air minum harus
                            dapat diakses oleh staf, pasien, dan pengasuh.<br />
                            Dalam kasus peningkatan cepat dalam pencari
                            perawatan (misalnya karena peristiwa terkait iklim),
                            stasiun pengisian staf lebih teratur, lebih banyak
                            air diperoleh, atau opsi lain (seperti perawatan
                            in-line untuk air perpipaan) digunakan. <br />
                            Air minum juga harus dapat diakses, sebagai berikut
                            <br />
                            - Jalur menuju area air minum: lebar minimal 120 cm,
                            datar dan rata, kering dan bersih dari rintangan.<br />
                            - Tanda untuk stasiun air minum memiliki kata-kata,
                            gambar dan braille dan dipajang di dinding pada
                            jarak 140 cm-160 cm dari tanah.<br />
                            - Keran stasiun air minum berjarak 75 cm dari lantai
                            dan cangkir tersedia untuk pasien. Jika cangkir
                            digunakan, mereka harus dapat digunakan kembali dan
                            dicuci dengan air hangat dan sabun dan dikeringkan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Air_Minum[
                            'Dispenser_air_minum_dengan_air_minum_yang_aman_tersedia_dan_berfungsi_setiap_saat_di_ruang_tunggu_utama'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Air minum tersedia di semua lokasi, setiap saat"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Air minum tersedia tetapi hanya di beberapa tempat, hanya kadang-kadang"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Air minum tidak tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- Tab1Q8 ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Shower</b>
                    </div>
                    <!-- W_16 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Shower</b>
                      <p class="ma-0 color_txt small_txt">
                        [Fasyankes dengan layanan rawat inap] Setidaknya satu
                        pancuran atau area pemandian tersedia per 40 pasien
                        rawat inap atau per bangsal (mana yang lebih rendah) dan
                        berfungsi serta dapat diakses
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[17]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Air minum yang aman, sebagaimana didefinisikan oleh
                            Pedoman WHO, tidak mewakili risiko signifikan
                            terhadap kesehatan selama konsumsi seumur hidup,
                            termasuk sensitivitas berbeda yang mungkin terjadi
                            di antara tahap kehidupan.<br /><br />

                            Tidak adanya kontaminasi tinja dapat ditunjukkan
                            dengan tidak adanya organisme indikator seperti E.
                            (atau coliform feses termotoleran). Jika ini tidak
                            dapat diuji, keberadaan residu klorin bebas
                            (setidaknya 0,2 mg/l) dapat digunakan sebagai
                            indikasi pengganti.<br /><br />

                            Air minum harus disimpan dengan aman dalam
                            ember/tangki bersih dengan penutup dan keran, yang
                            secara teratur dibersihkan dan didesinfeksi (kecuali
                            jika dikeluarkan dari air mancur). Air minum harus
                            dapat diakses oleh staf, pasien, dan pengasuh.<br />
                            Dalam kasus peningkatan cepat dalam pencari
                            perawatan (misalnya karena peristiwa terkait iklim),
                            stasiun pengisian staf lebih teratur, lebih banyak
                            air diperoleh, atau opsi lain (seperti perawatan
                            in-line untuk air perpipaan) digunakan. <br />
                            Air minum juga harus dapat diakses, sebagai berikut
                            <br />
                            - Jalur menuju area air minum: lebar minimal 120 cm,
                            datar dan rata, kering dan bersih dari rintangan.<br />
                            - Tanda untuk stasiun air minum memiliki kata-kata,
                            gambar dan braille dan dipajang di dinding pada
                            jarak 140 cm-160 cm dari tanah.<br />
                            - Keran stasiun air minum berjarak 75 cm dari lantai
                            dan cangkir tersedia untuk pasien. Jika cangkir
                            digunakan, mereka harus dapat digunakan kembali dan
                            dicuci dengan air hangat dan sabun dan dikeringkan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          air.Shower[
                            'Setidaknya_satu_pancuran_atau_area_pemandian_tersedia_per_40_pasien_rawat_inap_atau_per_bangsal__mana_yang_lebih_rendah__dan_berfungsi_serta_dapat_diakses'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Kamar mandi tersedia per bangsal atau per 40 pasien dan fungsional serta dapat diakses"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kamar mandi tersedia, tetapi kurang dari 1 pancuran yang berfungsi dan dapat diakses per 40 pasien / per bangsal"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada pancuran yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- W_17 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Shower</b>
                      <p class="ma-0 color_txt small_txt">
                        Kamar mandi berfungsi untuk wanita yang privasi dan
                        dapat dikunci tersedia di area persalinan dan persalinan
                      </p>

                      <v-radio-group
                        v-model="
                          air.Shower[
                            'Kamar_mandi_berfungsi_untuk_wanita_yang_privasi_dan_dapat_dikunci_tersedia_di_area_persalinan_dan_persalinan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Ya, pancuran fungsional atau ruang untuk mencuci tersedia di area persalinan dan persalinan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Shower/tempat cuci fungsional tersedia tetapi tidak di area persalinan dan pengiriman, atau di area yang benar tetapi tidak berfungsi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada shower / tempat cuci yang tersedia untuk wanita"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- ========================================STEP 2 ============================================= -->
              <v-stepper-content step="2" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat v-if="toilet">
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Toilet</b>
                    </div>

                    <!-- S_1 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Toilet</b>
                      <p class="ma-0 color_txt small_txt">
                        Fasyakes memiliki jumlah toilet yang cukup untuk pasien
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[18]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            ><strong
                              >Fasilitas sanitasi yang ditingkatkan</strong
                            >: termasuk toilet siram ke saluran pembuangan atau
                            septic tank yang dikelola dan lubang perendaman,
                            jamban VIP, jamban lubang dengan toilet lempengan
                            dan pengomposan.<br /><br />

                            <strong>Nomor</strong>: Lebih banyak jamban mungkin
                            diperlukan tergantung pada ukuran fasilitas. Untuk
                            fasilitas yang lebih besar dengan beberapa bangsal,
                            di mana dua toilet rawat jalan tidak mencukupi,
                            disarankan (jika memungkinkan) bahwa setiap
                            departemen rawat jalan memiliki dua toilet. Toilet
                            juga dapat digunakan oleh penjaga, pengasuh, dan
                            pengunjung. Jumlah pengunjung yang besar menambah
                            tuntutan pada infrastruktur sanitasi dan pembersihan
                            dan ini harus dipertimbangkan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Toilet[
                            'Fasyakes_memiliki_jumlah_toilet_yang_cukup_untuk_pasien'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Dua atau lebih toilet untuk pasien rawat jalan ditambah satu per 20 pengguna/rawat inap."
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Jumlah toilet dipenuhi untuk pasien rawat jalan atau rawat inap, tetapi tidak keduanya."
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Baik rawat inap maupun rawat jalan tidak memiliki jumlah toilet yang cukup atau toilet yang ada tidak diperbaiki"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- S_2 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Toilet</b>
                      <p class="ma-0 color_txt small_txt">
                        Semua toilet pasien tersedia dan dapat digunakan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[19]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span>
                            <strong>Tersedia dan dapat digunakan</strong>:
                            toilet / jamban harus memiliki pintu yang tidak
                            terkunci saat tidak digunakan (atau yang kuncinya
                            tersedia kapan saja) dan dapat dikunci dari dalam
                            selama penggunaan, tidak boleh ada lubang besar
                            dalam struktur, lubang atau lubang tidak boleh
                            diblokir, air harus tersedia untuk toilet flush /
                            pour flush dan tidak boleh ada retakan, atau
                            kebocoran pada struktur toilet. Itu harus berada di
                            dalam halaman fasilitas dan harus bersih seperti
                            yang dicatat dengan tidak adanya limbah, kotoran dan
                            kotoran yang terlihat dan serangga.<br /><br />

                            <strong
                              >Kriteria tambahan yang dapat digunakan</strong
                            >
                            untuk tingkat layanan yang lebih tinggi: <br />
                            Toilet harus <br />
                            • Terletak di area fasilitas yang kurang rawan
                            banjir, erosi, dll.<br />
                            • Secara teratur diperiksa untuk kerusakan<br />
                            • Dibersihkan lebih teratur ketika pengguna
                            meningkat <br />
                            • Diangkat dan/atau merupakan toilet tertutup
                            sementara yang dapat dikosongkan secara teratur
                            <br />
                            • Lubang dikosongkan secara teratur<br />
                            • Kotoran diolah dengan aman di tempat (misalnya
                            melalui tangki septik/bidang pelindian yang
                            berfungsi) atau dibuang ke sistem saluran pembuangan
                            yang berfungsi, atau diangkut dengan aman ke luar
                            lokasi ke area perawatan terpusat.<br />
                            • Toilet dengan lubang terbuka atau soakaway harus
                            terletak setidaknya 30m dari sumber air dan minimal
                            1,5m di atas meja air. Lihat catatan di bawah S_8
                            Penahanan. <br />
                            • Di daerah yang rawan air atau banjir, toilet
                            efisiensi tinggi (desain toilet rendah atau tanpa
                            air misalnya toilet kering konversi urin) harus
                            digunakan.<br />
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Toilet[
                            'Semua_toilet_pasien_tersedia_dan_dapat_digunakan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua toilet pasien tersedia dan dapat digunakan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa tetapi tidak semua toilet pasien tersedia dan dapat digunakan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa tetapi tidak semua toilet pasien tersedia dan dapat digunakan "
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- S_3 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Toilet</b>
                      <p class="ma-0 color_txt small_txt">
                        Semua toilet memiliki tempat cuci tangan yang berfungsi
                        dalam jarak 5 meter
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[20]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            ><strong
                              >Functioning hand hygiene station at
                              toilets</strong
                            >: must include soap and water. Alcohol-based hand
                            rub is not sufficient to remove faecal matter from
                            hands.<br />
                            This indicator is used to calculate basic hand
                            hygiene but is included in the sanitation section
                            for ease of data collection flow
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Toilet[
                            'Semua_toilet_memiliki_tempat_cuci_tangan_yang_berfungsi_dalam_jarak_5_meter'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua toilet memiliki tempat cuci tangan fungsional dalam jarak 5 meter"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="At least 50% of toilets have functioning hand washing stations within 5 metres "
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Fewer than 50% of toilets have functioning hand washing stations within 5 metres"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- S_4 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Toilet</b>
                      <p class="ma-0 color_txt small_txt">
                        Setidaknya satu toilet yang layak tersedia untuk staf,
                        dan toilet dipisahkan atau diberi label dengan jelas
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[21]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Toilet terpisah yang ditingkatkan untuk staf
                            diperlukan untuk memenuhi persyaratan sanitasi
                            dasar, sebagaimana diukur oleh JMP.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Toilet[
                            'Setidaknya_satu_toilet_yang_layak_tersedia_untuk_staf_dan_toilet_dipisahkan_atau_diberi_label_dengan_jelas'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Setidaknya ada satu toilet berfungsi untuk digunakan staf dan dipisahkan / diberi label dengan jelas "
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Toilet ada untuk digunakan staf, tetapi toilet tidak dipisahkan / diberi label dengan jelas atau tidak berfungsi "
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="No separate toilet exists for staff use, or toilets are unimproved"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- S_5 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Toilet</b>
                      <p class="ma-0 color_txt small_txt">
                        Toilet yang layak dipisahkan / diberi label dengan jelas
                        untuk pria, wanita yang memberikan privasi
                      </p>

                      <v-radio-group
                        v-model="
                          toilet.Toilet[
                            'Toilet_yang_layak_dipisahkan_diberi_label_dengan_jelas_untuk_pria_wanita_yang_memberikan_privasi'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Toilet terpisah untuk pengguna pria / wanita ada dan diberi label dengan jelas (dan memberikan privasi bagi pengguna)"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Toilet terpisah ada tetapi tidak diberi label dengan jelas"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada toilet terpisah dan tidak ada privasi di toilet lain atau toilet yang tidak disetujui"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- S_6 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Toilet</b>
                      <p class="ma-0 color_txt small_txt">
                        Setidaknya satu toilet yang dapat digunakan untuk
                        memenuhi kebutuhan manajemen kebersihan menstruasi
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[23]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Toilet harus memiliki tempat sampah untuk
                            pembuangan limbah atau area untuk mencuci, dengan
                            air yang tersedia. Idealnya, pembalut wanita harus
                            tersedia di fasilitas atau di dekatnya untuk wanita
                            menstruasi dan pasca-kelahiran untuk dibeli.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Toilet[
                            'Setidaknya_satu_toilet_yang_dapat_digunakan_untuk_memenuhi_kebutuhan_manajemen_kebersihan_menstruasi'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Satu atau lebih toilet yang dapat digunakan melayani MKM"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Ada ruang bagi wanita untuk mencuci tetapi tidak ada air yang tersedia, toilet tidak bersih / rusak atau tempat sampah untuk pembuangan limbah tersedia tetapi penuh"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada fasilitas MKM yang tersedia atau fasilitas yang tersedia tetapi toilet tidak dapat digunakan atau toilet tidak dapat digunakan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- S_7 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Toilet</b>
                      <p class="ma-0 color_txt small_txt">
                        Setidaknya satu toilet fungsional yang ditingkatkan
                        memenuhi kebutuhan orang-orang dengan mobilitas terbatas
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[24]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Toilet dapat dipertimbangkan untuk memenuhi
                            kebutuhan orang-orang dengan mobilitas yang
                            berkurang jika memenuhi kondisi berikut: dapat
                            diakses tanpa tangga atau tangga, pegangan tangan
                            untuk dukungan terpasang baik ke lantai atau dinding
                            samping, pintunya setidaknya selebar 80 cm, toilet
                            memiliki kursi yang ditinggikan (antara 40-48 cm
                            dari lantai), sandaran dan bilik memiliki ruang
                            untuk sirkulasi/ manuver (150 x 150 cm). Wastafel,
                            keran, dan air di luar juga harus dapat diakses dan
                            bagian atas wastafel 75 cm dari lantai (dengan jarak
                            selutut). Sakelar untuk lampu, jika relevan, juga
                            harus berada pada ketinggian yang dapat diakses
                            (maks 120 cm). Semua spesifikasi didasarkan pada ISO
                            21542: 2011 (Konstruksi bangunan - Aksesibilitas dan
                            kegunaan lingkungan binaan) tersedia di:
                            http://www.iso.org/iso/home/store/catalogue_tc/catalogue_detail.htm?csnumber=50498
                            Tempat cuci tangan di toilet ini juga harus dapat
                            diakses, sesuai dengan kriteria berikut: keran harus
                            memiliki pegangan tipe tuas, wastafel memiliki rel
                            pegangan di kedua sisi, dan sabun (atau gosok tangan
                            berbasis alkohol) dan tisu mudah dijangkau.
                            Ketinggian wastafel adalah 75 cm untuk pembersihan
                            lutut.</span
                          >
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Toilet[
                            'Setidaknya_satu_toilet_fungsional_yang_ditingkatkan_memenuhi_kebutuhan_orang_orang_dengan_mobilitas_terbatas'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Satu atau lebih toilet fungsional memenuhi kebutuhan orang-orang dengan mobilitas terbatas"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Toilet memenuhi kebutuhan orang dengan mobilitas yang berkurang tetapi tidak fungsional atau toilet berfungsi tetapi hanya sebagian memenuhi kebutuhan orang dengan mobilitas yang berkurang"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada toilet untuk pengguna difabel atau toilet yang tidak disetujui"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== contain 1 -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Faecal sludge management </b>
                    </div>
                    <!-- S_8 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3">
                      <b>Faecal sludge management</b>
                      <p class="ma-0 color_txt small_txt">
                        Lumpur tinja sepenuhnya terkandung untuk kemudian
                        pengosongan dan perawatan di luar lokasi atau sepenuhnya
                        terkandung dan dirawat di tempat.
                      </p>
                      <p class="ma-0 color_txt small_txt">
                        Limbah cair disimpan sepenuhnya atau mengalir ke tanah
                        dari dasar wadah, atau melalui bidang leach, lubang
                        rendam atau saluran air tertutup, atau disimpan dengan
                        aman.
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[25]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Air limbah harus dikelola dengan aman melalui
                            penggunaan pengolahan di tempat (yaitu septic tank
                            diikuti oleh lubang drainase) atau dikirim ke sistem
                            saluran pembuangan yang berfungsi. Lihat Pedoman WHO
                            tentang Sanitasi dan Kesehatan Bab 3.3 dan formulir
                            Inspeksi Sanitasi WHO untuk sistem sanitasi di
                            tempat. Penahanan yang aman memastikan produk yang
                            dihasilkan dari toilet disimpan dalam teknologi
                            penahanan dan/atau dibuang ke lingkungan setempat
                            dengan cara yang tidak membuat siapa pun terpapar
                            bahaya atau mencemari sumber air. <br /><br />

                            Penahanan mengacu pada wadah, biasanya terletak di
                            bawah permukaan tanah, di mana toilet terhubung /
                            berada. Ini termasuk kontainer yang dirancang
                            untuk:<br />
                            • penahanan, penyimpanan, dan pengolahan lumpur
                            tinja dan limbah (misalnya septic tank, jamban
                            kering dan basah, toilet pengomposan, kubah
                            dehidrasi, tangki penyimpanan urin, dll.); atau
                            <br />
                            • penahanan dan penyimpanan (tanpa pengolahan)
                            lumpur tinja dan air limbah (misalnya tangki
                            berlapis penuh, sanitasi berbasis wadah).<br /><br />

                            Di mana lindi dari teknologi permeabel atau limbah
                            dari teknologi kedap air larut ke dalam struktur
                            bawah tanah, ada risiko bahwa air tanah dan air
                            permukaan di dekatnya dapat tercemar, berpotensi
                            mencemari sumber air lokal yang digunakan untuk
                            minum dan tugas-tugas rumah tangga. Sebagai aturan
                            umum dan tanpa penilaian risiko yang lebih rinci
                            untuk air tanah, <br />
                            • bagian bawah wadah permeabel dan lubang rendam
                            atau ladang pelindian harus tidak kurang dari 1,5 m
                            hingga 2,0 m di atas permukaan air pada tingkat
                            tertinggi sepanjang tahun, • wadah permeabel dan
                            bidang pelindian harus terletak di bawah gradien,
                            dan <br />
                            • Setidaknya 10 m jarak horizontal dari sumber air
                            minum.</span
                          >
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Faecal_Sludge_Management[
                            'Lumpur_tinja_sepenuhnya_terkandung_untuk_kemudian_pengosongan_dan_perawatan_di_luar_lokasi_atau_sepenuhnya_terkandung_dan_dirawat_di_tempat'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Inspeksi visual wadah dan drainase menunjukkan integritas struktural, tidak ada kebocoran atau kerusakan, tidak ada kolam yang terlihat atau bau yang kuat yang menunjukkan kebocoran ke area lokal. Operator melaporkan tidak ada kebocoran dalam kondisi cuaca basah dan kering."
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak dapat menentukan penahanan dari inspeksi visual dan/atau operator melaporkan kebocoran musiman. "
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Inspeksi dan respons operator menunjukkan kerusakan pada wadah, ponding, pembuangan limbah cair ke saluran terbuka atau tanah terbuka."
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Wastewater management</b>
                    </div>
                    <!-- S_9a -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Wastewater management</b>
                      <p class="ma-0 color_txt small_txt">
                        Toilets are connected without leaks to a public sewer
                        system. The sewer conveys excreta and wastewater with no
                        leaks/overflows to treatment. [Sewered systems]
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[26]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Alat angkut yang aman membatasi paparan pekerja
                            yang melakukan operasi dan pemeliharaan, masyarakat
                            yang tinggal dan bekerja di sekitar pekerjaan, dan
                            masyarakat luas yang masing-masing dapat terpapar
                            patogen melalui konsumsi dan inhalasi patogen feses.
                            Lihat Pedoman WHO tentang Sanitasi dan Kesehatan Bab
                            3.4<br /><br />

                            Sistem berbasis saluran pembuangan terdiri dari
                            jaringan pipa bawah tanah. Jenis saluran pembuangan
                            meliputi:<br />
                            • Selokan gravitasi konvensional: Bawa air hitam
                            dari toilet dan greywater bersama dengan, di banyak
                            kasus, limbah industri dan air badai melalui pipa
                            berdiameter besar ke fasilitas pengolahan,
                            menggunakan gravitasi (dan pompa bila perlu)<br />
                            • Selokan yang disederhanakan: desain berbiaya lebih
                            rendah yang dipasang menggunakan pipa yang lebih
                            kecil pada kedalaman yang lebih rendah dan lebih
                            dangkal gradien dari selokan gravitasi
                            konvensional.<br />
                            • Selokan bebas padatan: desain serupa dengan
                            saluran pembuangan yang disederhanakan tetapi
                            termasuk pra-perawatan lumpur untuk hapus
                            padatan.</span
                          >
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Wastewater_Management[
                            'Toilets_are_connected_without_leaks_to_a_public_sewer_system'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Denah bangunan dan laporan operator mengonfirmasi toilet fasilitas terhubung ke selokan. Tidak ada laporan luapan di lahan fasilitas atau di masyarakat setempat"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak dapat menentukan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Laporan seringnya terjadi kebocoran dengan alasan fasilitas dari operator utilitas saluran pembuangan sering mengalami kebocoran / luapan di masyarakat"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- S_9b -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Wastewater management</b>
                      <p class="ma-0 color_txt small_txt">
                        Lumpur tinja dari penampungan secara berkala dikosongkan
                        tanpa tumpahan oleh personel terlatih dengan peralatan
                        pelindung yang sesuai dan a) dikeluarkan di luar lokasi
                        untuk perawatan atau b) dibuang dengan aman dengan
                        mengubur di tempat [Tidak berlaku untuk lubang yang
                        tertutup dan ditutup saat penuh. Pergi ke S_10a]
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[27]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Alat angkut yang aman membatasi paparan pekerja
                            yang melakukan operasi dan pemeliharaan, masyarakat
                            yang tinggal dan bekerja di sekitar pekerjaan, dan
                            masyarakat luas yang masing-masing dapat terpapar
                            patogen melalui konsumsi dan inhalasi patogen feses.
                            Lihat Pedoman WHO tentang Sanitasi dan Kesehatan Bab
                            3.4. Lihat Pedoman WHO tentang Sanitasi dan
                            Kesehatan Bab 3.4. <br /><br />

                            Pengosongan dan pengangkutan manual dan bermotor
                            mengacu pada berbagai cara di mana lumpur tinja
                            dapat dikeluarkan dari lokasi fasilitas. <br />
                            Pengosongan lubang, brankas, dan tangki secara
                            manual dapat dilakukan dengan salah satu dari dua
                            cara; baik menggunakan ember dan sekop; atau
                            menggunakan pompa lumpur portabel yang dioperasikan
                            secara manual (meskipun ini mungkin mekanis, masih
                            memerlukan penanganan manual/fisik). <br />
                            Pengosongan manual dan bermotor dapat membawa risiko
                            kemungkinan kontak dengan bahan feses dan dalam
                            beberapa kasus pengosongan bermotor perlu
                            dikombinasikan dengan pengosongan manual untuk
                            menghilangkan bahan terpadat. Beberapa teknologi
                            penahanan hanya dapat dikosongkan secara manual.
                            Teknologi ini paling sering dikosongkan dengan sekop
                            karena bahannya padat dan tidak dapat dilepas dengan
                            ruang hampa udara atau pompa. Lumpur tinja yang
                            dikosongkan dikumpulkan dalam tong atau tas atau
                            dimasukkan ke dalam gerobak dan diangkut menjauh
                            dari lokasi.<br /><br />

                            Pengosongan dan pengangkutan bermotor (juga dikenal
                            sebagai pengosongan dan pengangkutan mekanis)
                            mengacu pada penggunaan kendaraan atau perangkat apa
                            pun yang dilengkapi dengan pompa bermotor dan tangki
                            penyimpanan untuk mengosongkan dan mengangkut lumpur
                            tinja. Orang-orang diharuskan untuk mengoperasikan
                            pompa dan manuver selang, tetapi lumpur tinja tidak
                            diangkat atau diangkut secara manual. Sistem basah
                            seperti septic tank dan fully lined tank biasanya
                            dikosongkan menggunakan pengosongan dan pengangkutan
                            bermotor.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Wastewater_Management[
                            'Lumpur_tinja_dari_penampungan_secara_berkala_dikosongkan_tanpa_tumpahan_oleh_personel_terlatih_dengan_peralatan_pelindung_yang_sesuai'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Kontainer telah dikosongkan dalam 5 tahun terakhir (atau sesuai dengan frekuensi pengosongan yang dijadwalkan) oleh personel terlatih dengan peralatan pelindung yang sesuai dan a) dipindahkan ke luar lokasi untuk perawatan b) lumpur tinja dibuang dengan aman dengan mengubur di tempat"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak dapat menentukan frekuensi pengosongan atau keamanan pembuangan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak pernah dikosongkan atau diketahui pembuangan yang tidak aman tanpa perawatan di lingkungan setempat (misalnya di sungai atau di pertanian)"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Wastewater management</b>
                    </div>
                    <!-- S_10a -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Wastewater management</b>
                      <p class="ma-0 color_txt small_txt">
                        Instalasi pengolahan air limbah yang dirancang dengan
                        baik dan dikelola dengan baik, dengan catatan operasi
                        yang dapat diakses publik, menyediakan setidaknya
                        pengolahan sekunder dan memenuhi standar kinerja
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[28]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Desain dan pengoperasian fasilitas perawatan
                            dilakukan sesuai dengan kriteria desainnya dan
                            sesuai dengan tujuan penggunaan/pembuangan akhir
                            lokal.
                            <strong
                              >Lihat Pedoman WHO tentang Sanitasi dan Kesehatan
                              Bab 3.5</strong
                            >
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Wastewater_Management[
                            'Instalasi_pengolahan_air_limbah_yang_dirancang_dengan_baik_dan_dikelola_dengan_baik'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="IPAL yang dirancang dengan baik dengan catatan yang tersedia untuk umum yang menunjukkan bahwa IPAL tersebut memenuhi standar kinerja perawatan lokal/nasional"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="IPAL yang berfungsi ada. Kinerja tidak jelas atau tidak sesuai standar"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="IPAL tidak berfungsi atau tidak ada"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Faecal sludge management</b>
                    </div>
                    <!-- S_10b -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Faecal sludge management</b>
                      <p class="ma-0 color_txt small_txt">
                        Pabrik pengolahan lumpur tinja yang dirancang dengan
                        baik dan dikelola dengan baik, dengan catatan operasi
                        yang tersedia untuk umum, digunakan dan memenuhi standar
                        kinerja
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[29]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Desain dan pengoperasian fasilitas perawatan
                            dilakukan sesuai dengan kriteria desainnya dan
                            sesuai dengan tujuan penggunaan/pembuangan akhir
                            lokal.
                            <strong
                              >Lihat Pedoman WHO tentang Sanitasi dan Kesehatan
                              Bab 3.5</strong
                            >
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Faecal_Sludge_Management[
                            'Pabrik_pengolahan_lumpur_tinja_yang_dirancang_dengan_baik_dan_dikelola_dengan_baik_dengan_catatan_operasi_yang_tersedia_untuk_umum_digunakan_dan_memenuhi_standar_kinerja'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Well designed and managed FSTP with publicly available record showing it meets local/national  treatment performance standards"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Functioning FSTP exists. Performance unclear"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="FSTP is non-functioning or non-existent"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Stormwater management</b>
                    </div>
                    <!-- S_11 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Stormwater management</b>
                      <p class="ma-0 color_txt small_txt">
                        Sistem drainase air hujan (yaitu air hujan) dan
                        greywater tersedia yang mengalihkan air dari fasilitas
                        ke drainase yang aman atau area ladang larut
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[30]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Mengurangi genangan air juga penting untuk
                            pengendalian vektor. Air harus mengalir jauh dari
                            area publik. Tidak ada kebocoran dari pipa atau
                            lubang perendaman, perendaman lebih dari 30 m dari
                            sumber air, dengan perangkap minyak dan tidak ada
                            genangan air yang terlihat. Wastafel utilitas atau
                            saluran pembuangan (yaitu bukan wastafel yang
                            digunakan untuk kebersihan tangan) harus tersedia di
                            dalam fasilitas di area layanan pembersihan
                            lingkungan dan area pintu air yang ditunjuk. Saluran
                            air harus mengarah ke sistem air limbah di tempat
                            (misalnya sistem perendaman) atau ke sistem saluran
                            pembuangan yang berfungsi
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Stormwater_Management[
                            'Sistem_drainase_air_hujan__yaitu_air_hujan__dan_greywater_tersedia_yang_mengalihkan_air_dari_fasilitas_ke_drainase_yang_aman_atau_area_ladang_larut'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Sistem drainase ada, fungsional (tidak tersumbat) dan berhasil mengalihkan air dari fasilitas ke area penyaringan alami yang aman (misalnya tidak langsung ke rumah tangga atau area komunitas"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sistem drainase di tempat tetapi tidak cukup untuk volume air limbah atau tersumbat"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada sistem drainase di tempat"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <!-- S_12 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Stormwater management</b>
                      <p class="ma-0 color_txt small_txt">
                        Greywater dan/atau stormwater ditangkap dan digunakan
                        kembali untuk mencuci, membersihkan, menyiram tanaman,
                        dan menyiram toilet
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[31]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Penggunaan kembali greywater dan/atau air badai
                            mungkin berguna sebagai bagian dari konservasi air.
                            Air tersebut tidak perlu diolah untuk digunakan
                            kembali selama tidak dicampur dengan air
                            hitam/limbah dan tidak ada potensi risiko infeksi
                            atau infiltrasi lainnya.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Stormwater_Management[
                            'Greywater_dan_atau_stormwater_ditangkap_dan_digunakan_kembali_untuk_mencuci_membersihkan_menyiram_tanaman_dan_menyiram_toilet'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Sistem untuk penangkapan dan penggunaan kembali air limbah domestik dan air badai sudah ada dan beroperasi"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sistem untuk menangkap air limbah domestikr / air badai tersedia tetapi tidak digunakan secara maksimal"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada sistem untuk menangkap Air limbah domestik atau air badai"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Greywater management</b>
                    </div>

                    <!-- S_13 -->
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Greywater management</b>
                      <p class="ma-0 color_txt small_txt">
                        [Hanya jika ada sistem greywater] Greywater dari
                        wastafel dan fasilitas binatu ditangkap dengan aman dan
                        diarahkan ke saluran pembuangan, ladang pelarut, lubang
                        rendam atau saluran air tertutup tanpa koneksi silang
                        dengan pasokan air minum.
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[32]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Greywater juga dapat digunakan kembali untuk
                            menyiram tanaman dan pembilasan toilet.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          toilet.Greywater_Management[
                            'Greywater_dari_wastafel_dan_fasilitas_binatu_ditangkap_dengan_aman_dan_diarahkan_ke_saluran_pembuangan_ladang_pelarut_lubang_rendam_atau_saluran_air_tertutup_tanpa_koneksi_silang_dengan_pasokan_air_minum'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Air limbah domestik ditangkap dengan aman dan memiliki pipa ledeng terpisah"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sistem Greywater menangkap air tetapi beberapa risiko kontaminasi melalui koneksi silang"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sistem air limbah domestik tidak berfungsi"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- ========================================STEP 3 ============================================= -->
              <v-stepper-content step="3" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="limbah_medis"
                >
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Segregation</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Segregation</b>
                      <p class="ma-0 color_txt small_txt">
                        Wadah pengumpulan limbah fungsional tersedia di dekat
                        semua titik timbulan limbah untuk limbah non-infeksius
                        (umum), limbah infeksius, dan limbah tajam
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[33]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Fungsional berarti harus ada setidaknya tiga wadah
                            ("sistem 3-bin") yang tidak lebih dari tiga perempat
                            penuh, yang anti bocor dengan penutup dan semuanya
                            diberi label dengan jelas (yaitu mudah dibedakan
                            menurut warna, label atau simbol). Bin liner /
                            kantong untuk limbah infeksius direkomendasikan jika
                            sumber daya memungkinkan. Tempat sampah tambahan
                            yang tidak menular mungkin diperlukan untuk dengan
                            mudah memisahkan limbah untuk didaur ulang.
                            <br /><br />

                            Titik timbulan sampah adalah di mana saja perawatan
                            diberikan di mana limbah dihasilkan dari memberikan
                            perawatan atau kegiatan medis lainnya. <br /><br />

                            Jika memungkinkan, benda tajam/kotak pengaman kardus
                            digunakan sebagai pengganti kotak plastik, untuk
                            mengurangi emisi berbahaya saat dibakar (terutama di
                            mana pembakaran suhu rendah digunakan).
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Segregation[
                            'Wadah_pengumpulan_limbah_fungsional_tersedia_di_dekat_semua_titik_timbulan_limbah_untuk_limbah_non_infeksius_umum_limbah_infeksius_dan_limbah_tajam'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Wadah pengumpulan limbah fungsional untuk memisahkan limbah ada di semua titik timbulan limbah"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tempat sampah fungsional di beberapa tetapi tidak semua titik timbulan limbah"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada tempat sampah atau pembuangan benda tajam terpisah"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Segregation</b>
                      <p class="ma-0 color_txt small_txt">
                        Sampah dipilah dengan benar di semua titik timbulan
                        sampah
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[34]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Setidaknya tiga tempat sampah berlabel jelas atau
                            berkode warna harus ada untuk memisahkan (1) limbah
                            benda tajam18, (2) limbah infeksius19, dan (3)
                            limbah umum yang tidak menular. Tempat sampah tidak
                            boleh lebih dari tiga perempat (75%) penuh, dan
                            setiap tempat sampah tidak boleh mengandung limbah
                            selain yang sesuai dengan labelnya. Tempat sampah
                            harus sesuai dengan jenis sampah yang akan
                            dikandungnya; Wadah Sharps harus tahan tusukan dan
                            yang lainnya harus anti bocor. Tempat sampah untuk
                            limbah benda tajam dan limbah infeksius harus
                            memiliki tutup. Area konsultasi adalah ruangan atau
                            area di dalam fasilitas perawatan kesehatan tempat
                            perawatan atau perawatan diberikan. Verifikasi bahwa
                            semua tempat sampah di fasilitas memiliki limbah
                            yang benar di dalamnya.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Segregation[
                            'Sampah_dipilah_dengan_benar_di_semua_titik_timbulan_sampah'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Waste is correctly segregated Sampah dipisahkan dengan benar di semua titik timbulan sampah"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Lebih dari 75% tempat sampah memiliki limbah yang benar"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kurang dari 75% tempat sampah digunakan untuk limbah yang benar"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Segregation</b>
                      <p class="ma-0 color_txt small_txt">
                        Pengingat untuk pemilahan sampah yang benar terlihat
                        jelas di semua titik timbulan sampah
                      </p>

                      <v-radio-group
                        v-model="
                          limbah_medis.Segregation[
                            'Pengingat_untuk_pemilahan_sampah_yang_benar_terlihat_jelas_di_semua_titik_timbulan_sampah'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Pengingat terlihat jelas di semua titik timbulan sampah"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Pengingat tersedia di beberapa tetapi tidak semua titik timbulan limbah"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada pengingat yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== contain 1 -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Personnel</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Personnel</b>
                      <p class="ma-0 color_txt small_txt">
                        Peralatan dan sumber daya pelindung yang sesuai untuk
                        melakukan kebersihan tangan tersedia untuk semua staf
                        yang bertanggung jawab untuk menangani limbah, dan
                        bertanggung jawab atas pengolahan dan pembuangan limbah
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[35]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Protective equipment for people handling waste
                            management includes: face mask, thick gloves, long
                            sleeved shirt, apron, glasses/goggles and tough
                            rubber boots. <br />
                            Products (water and soap or alcohol hand rub) for
                            hand hygiene should also be available.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Personnel[
                            'Peralatan_dan_sumber_daya_pelindung_yang_sesuai_untuk_melakukan_kebersihan_tangan_tersedia_untuk_semua_staf_yang_bertanggung_jawab_untuk_menangani_limbah_dan_bertanggung_jawab_atas_pengolahan_dan_pembuangan_limbah'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Sumber daya untuk kebersihan tangan dan peralatan pelindung tersedia"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa peralatan tersedia, tetapi tidak untuk semua staf, atau tersedia tetapi rusak"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada peralatan yang tersedia untuk staf"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Waste reduction</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Waste reduction</b>
                      <p class="ma-0 color_txt small_txt">
                        Pengingat dan pelatihan tersedia untuk mempromosikan dan
                        memantau penggunaan alat pelindung diri (APD) secara
                        rasional (misalnya sarung tangan yang hanya digunakan
                        saat ditunjukkan)
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[36]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Penggunaan dan penyalahgunaan APD yang berlebihan
                            dapat berkontribusi pada penyebaran organisme yang
                            berpotensi patogen, terutama dengan tidak adanya
                            kebersihan tangan. Mencegah dan mengurangi jumlah
                            limbah yang dihasilkan, melalui penggunaan APD yang
                            aman dan rasional, adalah salah satu cara paling
                            efektif untuk mengelola dan mengurangi dampak
                            manusia dan lingkungan. Mengirim sampah ke TPA harus
                            menjadi pilihan terakhir. Lihat piramida sarung
                            tangan:
                            https://www.who.int/gpsc/5may/Glove_Use_Information_Leaflet.pdf
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Waste_Reduction[
                            'Pengingat_dan_pelatihan_tersedia_untuk_mempromosikan_dan_memantau_penggunaan_alat_pelindung_diri__APD__secara_rasional'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Pengingat dan pelatihan di tempat dan APD digunakan secara rasional"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa pengingat atau pelatihan di tempat tetapi lebih banyak yang dapat dilakukan untuk mengurangi penggunaan APD"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada pengingat atau pelatihan di tempat"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Waste reduction</b>
                      <p class="ma-0 color_txt small_txt">
                        Strategi untuk mengurangi jumlah limbah yang dihasilkan
                        digunakan di seluruh fasilitas, termasuk pengadaan
                        barang menggunakan lebih sedikit kemasan dan kemasan
                        yang lebih berkelanjutan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[37]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Contoh pengurangan limbah (mengatasi limbah "di
                            sumber") termasuk manajemen penyimpanan (kedaluwarsa
                            pertama, pertama keluar), pengadaan produk dengan
                            kemasan lebih sedikit atau kemasan yang dapat didaur
                            ulang, atau mendorong penggunaan obat tablet sebagai
                            alternatif injeksi untuk mengurangi limbah benda
                            tajam. Langkah lain adalah penggunaan APD yang aman
                            dan rasional, termasuk celemek, masker, dan
                            coverall. Semua APD yang digunakan kembali harus
                            dicuci dan didesinfeksi dengan benar sesuai dengan
                            standar internasional untuk mencegah penularan
                            infeksi.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Waste_Reduction[
                            'Strategi_untuk_mengurangi_jumlah_limbah_yang_dihasilkan_digunakan_di_seluruh_fasilitas_termasuk_pengadaan_barang_menggunakan_lebih_sedikit_kemasan_dan_kemasan_yang_lebih_berkelanjutan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Strategi ada dan diterapkan secara konsisten di seluruh fasilitas"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Strategi ada tetapi tidak diterapkan secara konsisten atau efektif"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada strategi yang ada"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Waste reduction</b>
                      <p class="ma-0 color_txt small_txt">
                        [Tidak berlaku jika tidak ada daur ulang lokal yang
                        tersedia] Limbah tidak berbahaya yang dapat didaur ulang
                        dipisahkan dan dikirim ke pabrik daur ulang kota
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[38]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Barang-barang umum yang dihasilkan di fasilitas
                            perawatan kesehatan yang dapat didaur ulang termasuk
                            kertas, kardus, dan plastik. Bahan yang tidak dapat
                            didaur ulang adalah makanan, logam, kaca, tekstil,
                            dan kayu yang dibuang.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Waste_Reduction[
                            'Limbah_tidak_berbahaya_yang_dapat_didaur_ulang_dipisahkan_dan_dikirim_ke_pabrik_daur_ulang_kota'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Ada sistem di mana barang yang dapat didaur ulang disortir dan dikirim ke pabrik daur ulang"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa daur ulang terjadi tetapi sistem dapat ditingkatkan (misalnya pemisahan yang lebih baik, jumlah daur ulang yang lebih besar)"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada daur ulang yang terjadi"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Storage</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Storage</b>
                      <p class="ma-0 color_txt small_txt">
                        Tersedia area penyimpanan limbah khusus yang dipagari
                        dan aman, dan dengan kapasitas yang cukup, di mana
                        limbah tajam, infeksius, dan tidak menular disimpan
                        secara terpisah
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[39]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span>
                            Dalam kasus banjir, limbah harus disimpan dalam
                            wadah yang ditinggikan dan / atau diangkut ke luar
                            lokasi.<br />
                            Penyimpanan tambahan juga tersedia ketika limbah
                            tambahan dihasilkan selama peristiwa iklim dan /atau
                            keadaan darurat.<br /><br />

                            Area berpagar harus dilindungi dari banjir, berjajar
                            dan tertutup. Tidak ada limbah perawatan kesehatan
                            yang tidak terlindungi yang terlihat.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Storage[
                            'Tersedia_area_penyimpanan_limbah_khusus_yang_dipagari_dan_aman_dan_dengan_kapasitas_yang_cukup_di_mana_limbah_tajam_infeksius_dan_tidak_menular_disimpan_secara_terpisah'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Tersedia area penyimpanan limbah khusus dan berpagar, dengan kapasitas yang cukup dan limbah yang disimpan secara terpisah"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Area penyimpanan limbah khusus tersedia tetapi tidak dipagari atau aman atau tidak cukup kapasitas atau semua limbah dikelompokkan bersama"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada area limbah khusus yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Storage</b>
                      <p class="ma-0 color_txt small_txt">
                        Limbah infeksius disimpan tidak lebih lama dari batas
                        aman (sebagaimana ditentukan oleh iklim) sebelum
                        pengolahan/pembuangan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[40]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span>
                            Unless a refrigerated storage room is available,
                            storage times (e.g. the time between generation and
                            treatment) should not exceed the following periods
                            for infectious waste:<br />
                            • Temperate climate: 72 hours in winter / 48 hours
                            in summer.<br />
                            • Warm climate: 48 hours during the cool season / 24
                            hours during the hot season.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Storage[
                            'Limbah_infeksius_disimpan_tidak_lebih_lama_dari_batas_aman__sebagaimana_ditentukan_oleh_iklim__sebelum_pengolahan_pembuangan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Limbah infeksius disimpan tidak lebih lama dari batas waktu aman"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Limbah infeksius disimpan di luar batas waktu yang aman, tetapi kurang dari sebulan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Limbah tidak disimpan dengan aman atau disimpan tetapi lebih dari sebulan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Treatment</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Treatment</b>
                      <p class="ma-0 color_txt small_txt">
                        Teknologi pengolahan limbah (incinerator atau teknologi
                        pengolahan alternatif) untuk pengolahan limbah infeksius
                        dan tajam dibangun dengan standar yang sesuai,
                        terpelihara dengan baik, fungsional dan memiliki
                        kapasitas yang cukup untuk limbah yang dihasilkan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[41]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span>
                            Untuk teknologi pengobatan alternatif (misalnya
                            autoklaf) pasokan air yang andal harus dijamin. Agar
                            dianggap fungsional, idealnya tidak ada kerusakan
                            besar dalam 6 bulan terakhir, dan masalah apa pun
                            seharusnya diperbaiki dalam waktu satu minggu.
                            <br />
                            Insinerator fungsional harus mencapai suhu minimum
                            yang diperlukan untuk pembakaran lengkap, emisi
                            dievakuasi dengan benar, segel pintu, tidak ada
                            akumulasi residu limbah di dalamnya, dll.
                            <br /><br />

                            Upaya dilakukan untuk meningkatkan efisiensi dan
                            keamanan insinerator, termasuk mencapai suhu yang
                            lebih tinggi, dengan tujuan memenuhi konvensi
                            Stockholm (lihat
                            http://chm.pops.int/Implementation/BATandBEP/BATBEPGuidelinesArticle5/tabid/187/Default.aspx
                            untuk informasi lebih lanjut). <br />
                            Insinerator (jika dirancang untuk limbah infeksius
                            dan bukan hanya limbah umum) harus mengikuti
                            persyaratan desain khusus (misalnya penggunaan batu
                            bata api/batu bata tahan api dan mortar (vs. batu
                            bata bangunan umum) yang dapat menahan suhu yang
                            diperlukan untuk insinerator ini (lebih dari 800
                            °C). Untuk pembakaran total, diperlukan insinerator
                            bilik ganda yang masing-masing mencapai suhu di atas
                            800 °C dan 1100 °C. Jika insinerator ganda tidak
                            tersedia dan ada kebutuhan mendesak untuk
                            perlindungan kesehatan masyarakat, insinerator skala
                            kecil dapat digunakan. Ini melibatkan kompromi
                            antara dampak lingkungan dari pembakaran terkendali
                            dengan kebutuhan utama untuk melindungi kesehatan
                            masyarakat jika satu-satunya alternatif adalah
                            pembuangan sembarangan. Keadaan ini ada dalam banyak
                            situasi yang berkembang dan pembakaran skala kecil
                            dapat menjadi respons realistis terhadap kebutuhan
                            mendesak. <br />
                            Limbah dapat diolah di luar lokasi. Jika demikian,
                            harus ada cara untuk mengonfirmasi bahwa itu
                            diperlakukan dengan aman setelah dikeluarkan dari
                            tempat fasilitas. <br />
                            WHO (2014) Pengelolaan limbah yang aman dari
                            kegiatan pelayanan kesehatan <br />
                            WHO (2017) Pengelolaan limbah yang aman dari
                            kegiatan perawatan kesehatan: Ringkasan <br />
                            WHO (2019) Tinjauan teknologi untuk pengobatan
                            infeksi dan limbah tajam dari fasilitas perawatan
                            kesehatan <br />
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Treatment[
                            'Teknologi_pengolahan_limbah__incinerator_atau_teknologi_pengolahan_alternatif__untuk_pengolahan_limbah_infeksius_dan_tajam_dibangun_dengan_standar_yang_sesuai_terpelihara_dengan_baik_fungsional_dan_memiliki_kapasitas_yang_cukup_untuk_limbah_yang_dihasilkan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Teknologi dibangun dengan standar yang sesuai, terpelihara dengan baik, fungsional dan berkapasitas memadai"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Teknologi tidak dibangun untuk standar yang benar atau tidak memiliki kapasitas yang memadai"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Teknologi tidak berfungsi dan tidak memiliki kapasitas yang memadai"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Treatment</b>
                      <p class="ma-0 color_txt small_txt">
                        Limbah dikumpulkan untuk pengolahan di luar lokasi
                        dengan aman dan teratur dan dikirim ke fasilitas
                        pengolahan limbah berlisensi yang sesuai
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[42]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Limbah harus diolah dan dibuang dengan aman setelah
                            meninggalkan fasilitasi. Ini harus dilakukan melalui
                            layanan berlisensi atau terakreditasi.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Treatment[
                            'Limbah_dikumpulkan_untuk_pengolahan_di_luar_lokasi_dengan_aman_dan_teratur_dan_dikirim_ke_fasilitas_pengolahan_limbah_berlisensi_yang_sesuai'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Sampah dikumpulkan secara teratur dan aman serta dikirim ke fasilitas pengolahan yang sesuai"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Limbah dikumpulkan secara teratur dan aman tetapi fasilitas pengolahan belum diverifikasi "
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Limbah tidak dikumpulkan dengan aman atau teratur atau dikirim ke fasilitas yang sesuai atau berlisensi"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Disposal</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Disposal</b>
                      <p class="ma-0 color_txt small_txt">
                        Lubang pemakaman fungsional, tempat pembuangan limbah
                        berpagar, atau penjemputan kota tersedia untuk
                        pembuangan limbah tidak menular (tidak berbahaya/umum)
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[43]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Lubang limbah harus ditutup untuk menghindari
                            banjir dan tidak terlalu penuh.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Disposal[
                            'Lubang_pemakaman_fungsional_tempat_pembuangan_limbah_berpagar_atau_penjemputan_kota_tersedia_untuk_pembuangan_limbah_tidak_menular__tidak_berbahaya_umum_'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Lubang fungsional, area berpagar atau penjemputan kota tersedia dan cukup untuk memenuhi permintaan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Lubang di fasilitas tetapi dimensinya tidak mencukupi; terlalu penuh atau tidak dipagari dan dikunci; pengambilan sampah kota yang tidak teratur, dll."
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada lubang atau metode pembuangan lain yang digunakan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Disposal</b>
                      <p class="ma-0 color_txt small_txt">
                        [Di mana ada risiko banjir] Lubang limbah dibangun untuk
                        menahan peristiwa dan keadaan darurat terkait iklim
                        (misalnya banjir) dan / atau tempat penyimpanan limbah
                        cadangan tersedia
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[44]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Lubang limbah harus dibangun di atas tanah yang
                            ditinggikan untuk mencegah luapan selama banjir.
                            Dasar lubang harus setidaknya 1,5 meter di atas
                            permukaan air.<br />
                            Jika banjir mungkin terjadi, alternatif tersedia
                            termasuk menyimpan limbah dalam wadah yang
                            ditinggikan dan / atau mengangkut limbah ke luar
                            lokasi. <br />
                            Jika tidak mungkin membangun lubang di tanah yang
                            ditinggikan, lubang harus memiliki saluran drainase
                            yang dibangun di sekitarnya untuk mengarahkan air
                            menjauh. Lubang limbah tidak boleh diisi secara
                            berlebihan dan area di sekitarnya tetap bebas dari
                            limbah
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Disposal[
                            'Lubang_limbah_dibangun_untuk_menahan_peristiwa_dan_keadaan_darurat_terkait_iklim__misalnya_banjir__dan_atau_tempat_penyimpanan_limbah_cadangan_tersedia'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Lubang limbah dibangun untuk menahan banjir dan alternatif tersedia pada saat keadaan darurat"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Lubang limbah dapat menahan banjir terbatas tetapi tidak ada cadangan atau alternatif yang ada "
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Lubang limbah tidak tahan iklim dan tidak ada cadangan atau tidak ada lubang limbah."
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Disposal</b>
                      <p class="ma-0 color_txt small_txt">
                        Di mana pembakaran digunakan] Lubang abu khusus tersedia
                        untuk membuang abu dari pembakaran
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[45]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Abu dari pembakaran (yang meliputi logam berat /
                            dioksin dan furan) harus dibuang dengan aman di
                            lubang abu. <br />
                            Lubang abu: berjajar atau tidak bergaris tergantung
                            pada geologi tetapi harus mencegah pencucian ke
                            lingkungan, dengan lempengan, dasar lubang
                            setidaknya 1,5 m dari meja air tanah. Jika air masuk
                            ke lubang abu, ia dapat melarutkan polutan ke dalam
                            tanah.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Disposal[
                            'Lubang_abu_khusus_tersedia_untuk_membuang_abu_dari_pembakaran'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Lubang abu ada dan berfungsi"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Ada tetapi tidakberfungsi/ terlalu penuh atau tidak dipagari dan dikunci"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada lubang abu yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Disposal</b>
                      <p class="ma-0 color_txt small_txt">
                        [Di mana kelahiran terjadi] Limbah anatomi/patologis
                        dimasukkan ke dalam lubang limbah patologis khusus,
                        dibakar di krematorium atau dikubur di kuburan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[46]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Lubang plasenta dapat dilapisi atau tidak bergaris
                            tergantung pada geologi tanah. Itu harus mencakup
                            lempengan. Lubang limbah harus ditutup (dengan
                            lempengan) untuk menghindari banjir dan tidak
                            terlalu penuh dan harus memiliki pipa ventilasi. Itu
                            harus dikunci atau dipagari untuk mencegah akses
                            yang tidak sah.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Disposal[
                            'Limbah_anatomi_patologis_dimasukkan_ke_dalam_lubang_limbah_patologis_khusus_dibakar_di_krematorium_atau_dikubur_di_kuburan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Lubang ada dan semua limbah anatomi / patologis dibuang dengan benar"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Pit ada tetapi tidak digunakan atau pit digunakan tetapi terlalu banyak diisi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada lubang yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Pharmaceutical waste</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Pharmaceutical waste</b>
                      <p class="ma-0 color_txt small_txt">
                        Limbah farmasi diolah dan dibuang dengan aman, baik di
                        fasilitas pengolahan dan pembuangan aman yang dikelola
                        secara terpusat (yaitu di luar lokasi), dengan mengirim
                        kembali ke pabrikan, atau dengan pembakaran oleh
                        industri yang menggunakan kiln suhu tinggi
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[47]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Strategi nasional dan/atau regional untuk
                            mengumpulkan dan mengolah/membuang limbah kimia dan
                            farmasi dari fasilitas kesehatan juga harus tersedia
                            dan beroperasi. Tanggung Jawab Produsen yang
                            Diperpanjang (EPR) untuk membawa obat-obatan dan
                            bahan kimia yang tidak digunakan atau kedaluwarsa
                            kembali ke produsen juga harus dipekerjakan. <br />
                            Insinerator terpusat harus mematuhi persyaratan
                            Konvensi Stockholm (untuk informasi lebih lanjut
                            lihat "Pedoman tentang BAT dan panduan sementara
                            tentang TARUHAN:
                            http://chm.pops.int/Implementation/BATandBEP/BATBEPGuidelinesArticle5/tabid/187/Default.aspx).
                            <br />
                            Pembakaran bersama oleh industri semen: Investasi
                            tambahan yang signifikan dapat diperlukan untuk
                            memodifikasi fasilitas untuk penanganan dan pemuatan
                            limbah medis yang aman, dan mesin jarang dilengkapi
                            dengan peralatan penyaringan dan pembersihan yang
                            cocok untuk polutan yang dihasilkan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Pharmaceutical_Waste[
                            'Limbah_farmasi_diolah_dan_dibuang_dengan_aman_baik_di_fasilitas_pengolahan_dan_pembuangan_aman_yang_dikelola_secara_terpusat__yaitu_di_luar_lokasi__dengan_mengirim_kembali_ke_pabrikan_atau_dengan_pembakaran_oleh_industri_yang_menggunakan_kiln_suhu_tinggi'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua limbah farmasi diolah dan dibuang dengan aman"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa tetapi tidak semua limbah farmasi dibuang dengan benar"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Limbah farmasi tidak diolah atau dibuang dengan aman"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Personnel</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Personnel</b>
                      <p class="ma-0 color_txt small_txt">
                        Seorang staf dilatih secara memadai untuk pengelolaan
                        dan pengawasan limbah perawatan kesehatan dan
                        melaksanakan tugas mereka dengan standar profesional
                        yang sesuai
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[48]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Orang ini mungkin kepala perawat atau anggota
                            komite pengendalian pencegahan infeksi. Mereka harus
                            memastikan bahwa semua anggota staf bertanggung
                            jawab atas pemisahan dan pembuangan limbah yang
                            dihasilkan selama layanan mereka.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Personnel[
                            'Seorang_staf_dilatih_secara_memadai_untuk_pengelolaan_dan_pengawasan_limbah_perawatan_kesehatan_dan_melaksanakan_tugas_mereka_dengan_standar_profesional_yang_sesuai'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Seorang anggota staf dilatih secara memadai dan melaksanakan tugas dengan benar"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Seorang anggota staf dilatih tetapi tidak melaksanakan tugas dengan benar, atau ditunjuk tetapi tidak dilatih"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada anggota staf seperti itu yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Personnel</b>
                      <p class="ma-0 color_txt small_txt">
                        Staf yang menangani atau membuang limbah dan petugas
                        perawatan kesehatan divaksinasi hepatitis B (dan
                        memiliki vaksinasi lain yang direkomendasikan, sesuai
                        dengan pedoman nasional)
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[49]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Semua pekerja yang berisiko terpapar darah
                            (termasuk pembersih dan penjamah limbah) harus
                            menerima pra-layanan dan imunisasi berkelanjutan
                            terhadap hepatitis B (ketiga dosis) dan penyakit
                            lain yang dapat dicegah dengan vaksin di tempat
                            kerja tanpa biaya kepada anggota staf.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Personnel[
                            'Staf_yang_menangani_atau_membuang_limbah_dan_petugas_perawatan_kesehatan_divaksinasi_hepatitis_B__dan_memiliki_vaksinasi_lain_yang_direkomendasikan_sesuai_dengan_pedoman_nasional_'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua staf telah menerima semua vaksinasi yang diperlukan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa tetapi tidak semua staf telah divaksinasi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada staf yang telah divaksinasi"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Emergency preparedness</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Emergency preparedness</b>
                      <p class="ma-0 color_txt small_txt">
                        [Ketika permintaan meningkat karena wabah atau peristiwa
                        terkait iklim] Strategi untuk menangani limbah tambahan
                        digunakan ketika permintaan meningkat
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[50]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Jumlah limbah yang lebih besar dapat dihasilkan
                            selama dan setelah peristiwa dan keadaan darurat
                            terkait iklim atau sebagai akibat dari wabah
                            (misalnya APD tambahan).<br />
                            Strategi meliputi:<br />
                            - Menyediakan tempat sampah tambahan <br />
                            - Frekuensi pengosongan kontainer yang lebih tinggi
                            <br />
                            - Area penyimpanan khusus untuk menyimpan limbah
                            ekstra yang dihasilkan
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          limbah_medis.Emergency_Preparedness[
                            'Strategi_untuk_menangani_limbah_tambahan_digunakan_ketika_permintaan_meningkat'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Strategi untuk menangani limbah tambahan digunakan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Strategi ada tetapi tidak diterapkan secara efektif"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada strategi yang ada"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- ========================================STEP 4 ============================================= -->
              <v-stepper-content step="4" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="tangan_bersih"
                >
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Availability</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Availability</b>
                      <p class="ma-0 color_txt small_txt">
                        Sarana kebersihan tangan yang berfungsi tersedia di
                        semua titik perawatan, termasuk di ruang bersalin
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[51]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Kebersihan tangan adalah istilah umum yang mengacu
                            pada setiap tindakan pembersihan tangan, yaitu
                            tindakan melakukan kebersihan tangan untuk tujuan
                            menghilangkan kotoran, bahan organik, dan / atau
                            mikroorganisme secara fisik atau mekanis. Sumber:
                            Pedoman WHO tentang kebersihan tangan dalam
                            perawatan kesehatan. 2009
                            (https://www.who.int/gpsc/5may/tools/9789241597906/en/).
                            <br /><br />

                            Tempat kebersihan tangan fungsional dapat terdiri
                            dari sabun dan air dengan baskom/wajan untuk mencuci
                            tangan dan handuk sekali pakai atau bersih, atau
                            handrub berbasis alkohol (ABHR). <br /><br />

                            Titik perawatan adalah tempat di mana tiga elemen
                            bersatu: pasien, petugas perawatan kesehatan dan
                            perawatan atau perawatan yang melibatkan kontak
                            dengan pasien atau lingkungannya (dalam zona
                            pasien). Di beberapa fasilitas, petugas kesehatan
                            membawa ABHR berkeliling pada orang mereka saat
                            mereka bergerak di antara area layanan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          tangan_bersih.Availability[
                            'Sarana_kebersihan_tangan_yang_berfungsi_tersedia_di_semua_titik_perawatan_termasuk_di_ruang_bersalin'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua titik perawatan memiliki kebersihan tangan yang berfungsi (baik air dan sabun atau larutan penggosok tangan alkohol)"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Setidaknya 75% titik perawatan memiliki Sarana kebersihan tangan yang berfungsi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kurang dari 75% titik perawatan memiliki Sarana kebersihan tangan yang berfungsi"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Availability</b>
                      <p class="ma-0 color_txt small_txt">
                        Sarana kebersihan tangan yang berfungsi tersedia di
                        semua ruang tunggu dan area umum lainnya, dan di area
                        pembuangan limbah
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[52]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Sarana kebersihan tangan fungsional: dapat terdiri
                            dari sabun dan air dengan baskom / wajan untuk
                            mencuci tangan dan handuk sekali pakai atau bersih
                            atau handrub berbasis alkohol.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          tangan_bersih.Availability[
                            'Sarana_kebersihan_tangan_yang_berfungsi_tersedia_di_semua_ruang_tunggu_dan_area_umum_lainnya_dan_di_area_pembuangan_limbah'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Sarana kebersihan tangan yang berfungsi tersedia di semua area"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sarana kebersihan tangan yang berfungsi tersedia di beberapa tetapi tidak semua area."
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada Sarana kebersihan tangan yang berfungsi tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Availability</b>
                      <p class="ma-0 color_txt small_txt">
                        (Semua toilet memiliki tempat cuci tangan yang berfungsi
                        dalam jarak 5 meter)
                      </p>

                      <v-radio-group
                        v-model="
                          tangan_bersih.Availability[
                            '_Semua_toilet_memiliki_tempat_cuci_tangan_yang_berfungsi_dalam_jarak_5_meter_'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Refer to S_3_1"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Refer to S_3_2"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Refer to S_3_3"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Hygiene promotion</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Hygiene promotion</b>
                      <p class="ma-0 color_txt small_txt">
                        Materi promosi kebersihan tangan ditampilkan dan
                        terlihat jelas di semua bangsal / area perawatan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[53]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Materi promosi kebersihan tangan menunjukkan
                            instruksi untuk kebersihan tangan (WHO 5 Moments for
                            hand hygiene) dan teknik yang benar
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          tangan_bersih.Hygiene_promotion[
                            'Materi_promosi_kebersihan_tangan_ditampilkan_dan_terlihat_jelas_di_semua_bangsal_area_perawatan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Materi ditampilkan dengan jelas di semua bangsal / area perawatan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Materi ditampilkan dengan jelas di semua bangsal / area perawatan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada bahan yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Hygiene compliance</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Hygiene compliance</b>
                      <p class="ma-0 color_txt small_txt">
                        Kegiatan kepatuhan kebersihan tangan dilakukan secara
                        teratur (setidaknya setiap tahun)
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[54]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Pemantauan kebersihan tangan langsung berarti
                            pengamatan langsung terhadap kinerja sesuai dengan
                            Who 5 Moments. Pemantauan kebersihan tangan langsung
                            berarti memantau konsumsi sabun dan alkohol berbasis
                            handrub. Untuk fasilitas yang lebih canggih,
                            kerangka penilaian diri kebersihan tangan WHO dapat
                            diselesaikan setiap tahun.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          tangan_bersih.Hygiene_Compliance[
                            'Kegiatan_kepatuhan_kebersihan_tangan_dilakukan_secara_teratur__setidaknya_setiap_tahun_'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Kegiatan kepatuhan rutin (setidaknya tahunan) berlangsung di seluruh fasilitas"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kegiatan kepatuhan dalam kebijakan, tetapi tidak dilakukan dengan keteraturan apa pun"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada aktivitas kepatuhan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Supplies (hand hygiene)</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Supplies (hand hygiene)</b>
                      <p class="ma-0 color_txt small_txt">
                        Audit rutin (setidaknya setiap tiga bulan) berbasis
                        lingkungan dilakukan untuk menilai ketersediaan hand
                        rub, sabun, handuk sekali pakai, dan sumber daya
                        kebersihan tangan lainnya
                      </p>

                      <v-radio-group
                        v-model="
                          tangan_bersih.Supplies[
                            'Audit_rutin__setidaknya_setiap_tiga_bulan__berbasis_lingkungan_dilakukan_untuk_menilai_ketersediaan_hand_rub_sabun_handuk_sekali_pakai_dan_sumber_daya_kebersihan_tangan_lainnya'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Audit berbasis lingkungan reguler (setidaknya tahunan) dilakukan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Audit berbasis lingkungan yang dilakukan kurang dari sekali seminggu atau audit tidak lengkap"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak dilakukan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- ========================================STEP 5 ============================================= -->
              <v-stepper-content step="5" class="pa-0">
                <v-card
                  class="pa-5"
                  min-height="500px"
                  flat
                  v-if="lingkung_bersih"
                >
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Policies</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Policies</b>
                      <p class="ma-0 color_txt small_txt">
                        Kebijakan atau protokol kebersihan fasyankes (atau
                        lingkungan) yang jelas dan terperinci dipajang dengan
                        jelas, diterapkan dan dipantau
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[55]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Kebijakan atau protokol harus mencakup hal-hal
                            berikut: <br />
                            • garis pelaporan fungsional yang ditentukan dan
                            tanggung jawab untuk semua staf yang terlibat,
                            termasuk staf tambahan (misalnya penangan limbah dan
                            pembersih);<br />
                            • jadwal pembersihan untuk setiap area perawatan
                            pasien dan peralatan perawatan pasien nonkritis,
                            menentukan frekuensi, metode, dan staf yang
                            bertanggung jawab;<br />
                            • rencana kontingensi dan prosedur pembersihan yang
                            diperlukan untuk manajemen wabah;<br />
                            • Persyaratan pelatihan dan standar kinerja untuk
                            staf kebersihan<br />
                            • metode pemantauan, frekuensi, dan staf yang
                            bertanggung jawab;<br />
                            • daftar produk pembersih, persediaan, dan peralatan
                            yang disetujui serta spesifikasi yang diperlukan
                            untuk penggunaannya; dan<br />
                            • daftar APD yang diperlukan dan kapan tindakan
                            kebersihan tangan direkomendasikan untuk staf
                            kebersihan.<br />
                            Bangsal khusus (misalnya ICU, unit ketergantungan
                            tinggi, ruang operasi) mungkin memerlukan protokol
                            khusus. <br /><br />

                            EC_1 sejalan dengan pertanyaan JMP G-C1. Mencoba
                            menilai apakah area layanan dianggap "bersih" sangat
                            subjektif, dan terlihat bersih mungkin sangat
                            berbeda dari bersih secara mikrobiologis. Demikian
                            pula, frekuensi pembersihan sulit diukur karena
                            tidak dapat diamati oleh enumerator dalam satu hari
                            dan respons cenderung tunduk pada bias responden.
                            Seberapa sering fasilitas perlu dibersihkan terkait
                            dengan beban pasien, oleh karena itu jadwal
                            pembersihan sangat bervariasi dari satu fasilitas ke
                            fasilitas lainnya. Menanyakan tentang adanya
                            protokol pembersihan menjadi indikasi pentingnya
                            sebuah fasilitas terhadap kebersihan lingkungan.
                            Untuk pembersih melek huruf rendah atau buta huruf,
                            protokol harus disesuaikan dan disederhanakan sesuai
                            dengan gambar dan ilustrasi yang dapat dikenali. .
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Policies[
                            'Kebijakan_atau_protokol_kebersihan_fasyankes__atau_lingkungan__yang_jelas_dan_terperinci_dipajang_dengan_jelas_diterapkan_dan_dipantau'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Kebijakan atau protokol kebersihan ada, dilaksanakan dan dipantau"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kebijakan atau protokol kebersihan ada tetapi tidak diterapkan atau dipantau"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada kebijakan atau protokol kebersihan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== kepatuhan -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Monitoring</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Monitoring</b>
                      <p class="ma-0 color_txt small_txt">
                        Catatan pembersihan tersedia untuk area perawatan
                        pasien, bangsal umum atau seluruh fasilitas dan
                        ditandatangani oleh petugas kebersihan yang relevan
                        setiap hari
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[56]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Buat catatan tersedia di lokasi pusat atau di mana
                            tugas pembersihan terjadi sehingga staf pengawas
                            dapat mengelolanya setiap hari, bersama dengan staf
                            (misalnya focal person IPC) yang bertanggung jawab
                            untuk kegiatan pemantauan berkala. Untuk panduan
                            lebih lanjut tentang catatan pembersihan dan
                            mekanisme pemantauan lainnya, lihat Praktik Terbaik
                            CDC untuk Pembersihan Lingkungan, bagian 2.4.3.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Monitoring[
                            'Catatan_pembersihan_tersedia_untuk_area_perawatan_pasien_bangsal_umum_atau_seluruh_fasilitas_dan_ditandatangani_oleh_petugas_kebersihan_yang_relevan_setiap_hari'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Tersedia di setiap bangsal/area atau seluruh fasilitas"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Catatan ada, tetapi tidak untuk setiap lingkungan atau tidak untuk setiap hari atau sudah usang"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada catatan pembersihan yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Monitoring</b>
                      <p class="ma-0 color_txt small_txt">
                        Toilet dibersihkan setidaknya sekali setiap hari, dan
                        catatan pembersihan ditandatangani oleh petugas
                        kebersihan dan ditampilkan secara nyata
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[57]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Toilet pribadi harus dibersihkan dan didesinfeksi
                            setidaknya sekali sehari (misalnya per periode 24
                            jam) atau ketika terlihat kotor, setelah pembersihan
                            rutin area perawatan pasien. <br />
                            Toilet umum atau bersama harus dibersihkan dan
                            didesinfeksi dua kali sehari, atau ketika terlihat
                            kotor.<br />
                            Untuk pembersih melek huruf rendah atau buta huruf,
                            catatan pembersihan harus disesuaikan dan
                            disederhanakan sesuai dengan gambar dan ilustrasi
                            yang dapat dikenali.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Monitoring[
                            'Toilet_dibersihkan_setidaknya_sekali_setiap_hari_dan_catatan_pembersihan_ditandatangani_oleh_petugas_kebersihan_dan_ditampilkan_secara_nyata'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Toilet dibersihkan setiap hari dan catatan yang ditandatangani terlihat"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Toilet dibersihkan tetapi kurang dari sekali sehari dengan atau tanpa catatan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada catatan yang tersedia dan toilet dibersihkan kurang dari sekali sehari"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Personnel</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Personnel</b>
                      <p class="ma-0 color_txt small_txt">
                        Jumlah staf kebersihan yang diperlukan atau staf dengan
                        tanggung jawab pembersihan tersedia di bangsal /
                        fasilitas setiap hari atau ketika pembersihan diperlukan
                        dan memiliki waktu yang didedikasikan untuk melakukan
                        kegiatan pembersihan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[58]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Pembersih (juga dikenal sebagai staf kebersihan
                            lingkungan atau teknisi jasa lingkungan) adalah
                            individu yang bertanggung jawab untuk melakukan
                            pembersihan lingkungan di fasilitas perawatan
                            kesehatan yang memainkan peran kunci dalam menjaga
                            kebersihan dan / atau higienis lingkungan yang
                            memfasilitasi praktik yang terkait dengan pencegahan
                            dan pengendalian infeksi terkait perawatan
                            kesehatan.<br /><br />

                            Staf harus tersedia secara teratur (misalnya setiap
                            hari), cukup untuk semua bangsal dan dengan waktu
                            khusus untuk melakukan kegiatan pembersihan. Jumlah
                            staf kebersihan yang diperlukan akan bervariasi
                            berdasarkan beberapa faktor, termasuk: jumlah tempat
                            tidur pasien, tingkat hunian, jenis pembersihan
                            (misalnya, rutin atau terminal), jenis area
                            perawatan pasien (misalnya, area perawatan khusus
                            seperti ICU dan OR). Staf dapat paruh waktu atau
                            penuh waktu.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Personnel[
                            'Jumlah_staf_kebersihan_yang_diperlukan_atau_staf_dengan_tanggung_jawab_pembersihan_tersedia_di_bangsal_fasilitas_setiap_hari_atau_ketika_pembersihan_diperlukan_dan_memiliki_waktu_yang_didedikasikan_untuk_melakukan_kegiatan_pembersihan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Jumlah staf yang diperlukan tersedia setiap saat saat saat dibutuhkan dan memiliki waktu khusus untuk melakukan kegiatan pembersihan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa staf tersedia tetapi jumlahnya tidak mencukupi, tidak setiap saat bila diperlukan, atau tidak di semua lingkungan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada staf kebersihan yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Personnel</b>
                      <p class="ma-0 color_txt small_txt">
                        Semua staf yang bertanggung jawab untuk kebersihan telah
                        menerima pelatihan tentang kebersihan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[59]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >"Staf yang bertanggung jawab untuk pembersihan"
                            mengacu pada penyedia layanan non-kesehatan seperti
                            pembersih, mantri atau staf tambahan, serta penyedia
                            layanan kesehatan yang, di samping tugas perawatan
                            klinis dan pasien mereka, melakukan tugas
                            pembersihan sebagai bagian dari peran mereka.<br />
                            Pelatihan mengacu pada rencana atau program
                            pelatihan terstruktur yang dipimpin oleh pelatih
                            atau supervisor yang berkualifikasi tepat.<br />
                            Staf harus menerima pelatihan penyegaran pra-layanan
                            dan tahunan, atau sebelum pengenalan persediaan atau
                            peralatan pembersih lingkungan baru. .
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Personnel[
                            'Semua_staf_yang_bertanggung_jawab_untuk_kebersihan_telah_menerima_pelatihan_tentang_kebersihan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua staf yang bertanggung jawab untuk pembersihan telah menerima pelatihan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa tetapi tidak semua staf telah menerima pelatihan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada staf yang menerima pelatihan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Personnel</b>
                      <p class="ma-0 color_txt small_txt">
                        Kebijakan dan praktik untuk meningkatkan keselamatan
                        kerja petugas kebersihan dan teknisi limbah perawatan
                        kesehatan tersedia dan diterapkan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[60]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Untuk informasi lebih lanjut tentang kesehatan
                            kerja, lihat: WHO/ILO (2014) HealthWISE -
                            Peningkatan Kerja dalam Layanan Kesehatan - Action
                            Manual,
                            https://www.ilo.org/sector/Resources/training-materials/WCMS_237276/lang--en/index.htm
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Personnel[
                            'Kebijakan_dan_praktik_untuk_meningkatkan_keselamatan_kerja_petugas_kebersihan_dan_teknisi_limbah_perawatan_kesehatan_tersedia_dan_diterapkan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Kebijakan tersedia dan diimplementasikan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kebijakan tersedia tetapi tidak cukup diimplementasikan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada kebijakan yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Supplies</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Supplies</b>
                      <p class="ma-0 color_txt small_txt">
                        Bahan yang sesuai dan terawat dengan baik (misalnya
                        deterjen, pel, ember) untuk membersihkan berbagai area
                        dan permukaan yang berbeda tersedia dan cukup
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[61]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Minimal, kain pembersih sekali pakai atau dapat
                            digunakan kembali, ember, pel, deterjen, dan
                            desinfektan tingkat rendah. Produk harus sesuai
                            dengan panduan/peraturan tingkat nasional yang ada
                            tentang produk yang sesuai dan tersedia secara lokal
                            untuk pembersihan perawatan kesehatan. <br />
                            Persediaan rutin (misalnya bulanan) dan inspeksi
                            persediaan dan peralatan harus dilakukan untuk
                            mencegah kehabisan stok, mengantisipasi kebutuhan
                            pasokan dan memastikan ketersediaan bahan tambahan
                            untuk kontinjensi seperti wabah. <br /><br />

                            Daftar utama produk berkhasiat yang disetujui
                            fasilitas harus dikembangkan yang meminimalkan
                            jumlah produk berbahaya di sekitarnya. Banyak
                            deterjen dan desinfektan tradisional mengandung
                            bahan kimia beracun yang persisten yang dapat
                            menyebabkan kanker, penyakit pernapasan, iritasi
                            mata dan kulit dan berkontribusi terhadap pencemaran
                            lingkungan selama pembuatan, penggunaan, dan
                            pembuangan. Fasilitas layanan kesehatan harus
                            mendapatkan dan menggunakan produk pembersih yang
                            tidak diberi pewangi dan lebih ramah lingkungan,
                            dengan kemasan minimal dan/atau ramah lingkungan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Supplies[
                            'Bahan_yang_sesuai_dan_terawat_dengan_baik_misalnya_deterjen_pel_ember_untuk_membersihkan_berbagai_area_dan_permukaan_yang_berbeda_tersedia_dan_cukup'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua peralatan yang diperlukan tersedia, dalam kondisi baik dan cukup"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tersedia tetapi tidak terawat dengan baik atau di beberapa tetapi tidak semua area atau tidak cukup"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada bahan yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Supplies</b>
                      <p class="ma-0 color_txt small_txt">
                        Anggaran tahunan untuk perlengkapan dan peralatan
                        kebersihan lingkungan ada dan cukup untuk semua
                        kebutuhan.
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[62]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Anggaran tahunan untuk program pembersihan
                            lingkungan yang efektif, termasuk: <br />
                            • Personil (gaji dan tunjangan untuk staf
                            kebersihan, supervisor, dan manajer program secara
                            keseluruhan) <br />
                            • Pelatihan staf (setidaknya penyegaran pra-layanan
                            dan tahunan)<br />
                            • perlengkapan dan peralatan pembersih lingkungan,
                            termasuk APD untuk staf kebersihan<br />
                            • Biaya administrasi<br />
                            • Biaya produksi dan pencetakan untuk daftar
                            periksa, log, dan alat bantu pekerjaan lainnya<br />
                            • biaya infrastruktur/jasa, seperti layanan air
                            penunjang dan air limbah (sebagaimana berlaku)
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Supplies[
                            'Anggaran_tahunan_untuk_perlengkapan_dan_peralatan_kebersihan_lingkungan_ada_dan_cukup_untuk_semua_kebutuhan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Anggaran ada dan cukup untuk semua kebutuhan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Anggaran ada tapi tidak cukup untuk semua kebutuhan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada anggaran"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Supplies</b>
                      <p class="ma-0 color_txt small_txt">
                        Area khusus untuk penyimpanan, persiapan dan perawatan
                        persediaan dan peralatan kebersihan ada ("area layanan
                        pembersihan lingkungan"), dijaga kebersihannya dan
                        dirawat dengan baik, dan digunakan sesuai dengan
                        tujuannya
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[63]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Area layanan pembersihan lingkungan adalah ruang
                            khusus untuk mempersiapkan, memproses ulang, dan
                            menyimpan persediaan dan peralatan pembersih
                            lingkungan yang bersih atau baru, termasuk produk
                            pembersih dan APD. Kamar-kamar ini telah membatasi
                            akses hanya untuk staf kebersihan dan personel resmi
                            lainnya. Itu harus tersedia di dalam fasilitas,
                            terlepas dari apakah program pembersihan dikelola
                            sendiri atau oleh perusahaan eksternal. Area
                            tersebut harus berventilasi baik, diterangi,
                            memiliki pasokan air yang sesuai (akses air panas
                            dan dingin, jika memungkinkan), memiliki wastafel
                            cuci tangan khusus, APD tersedia, bebas dari
                            kekacauan, dan berukuran tepat. Untuk panduan lebih
                            lanjut, lihat pedoman Pembersihan Lingkungan CDC.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Supplies[
                            'Area_khusus_untuk_penyimpanan_persiapan_dan_perawatan_persediaan_dan_peralatan_kebersihan_ada_area_layanan_pembersihan_lingkungan_dijaga_kebersihannya_dan_dirawat_dengan_baik_dan_digunakan_sesuai_dengan_tujuannya'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Area khusus ada, terawat dengan baik, dijaga kebersihannya dan digunakan sesuai dengan tujuannya"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Suatu area ada tetapi berisi barang-barang lain atau tidak bersih"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada area penyimpanan khusus"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">PPE</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>PPE</b>
                      <p class="ma-0 color_txt small_txt">
                        APD yang memadai tersedia setiap saat dan dalam jumlah
                        yang cukup untuk semua staf kebersihan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[64]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >APD minimum yang dibutuhkan untuk petugas
                            kebersihan di semua fasilitas adalah: 1. Gaun
                            dan/atau celemek plastik, 2. Sarung tangan karet
                            yang dapat digunakan kembali dan tahan lama, 3.
                            Masker wajah, 4. kacamata atau pelindung wajah.
                            Semua APD (dapat digunakan kembali dan sekali pakai)
                            harus dalam persediaan yang baik, terawat dengan
                            baik (kualitas baik, stok disimpan dengan tepat),
                            dibersihkan sebelum digunakan, dan dalam perbaikan
                            yang baik. Semua APD yang dapat digunakan kembali
                            harus diproses ulang (yaitu, dibersihkan dan
                            didesinfeksi) setidaknya sekali sehari. Sumber daya
                            kebersihan tangan juga harus tersedia untuk staf
                            yang menggunakan APD.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Ppe[
                            'APD_yang_memadai_tersedia_setiap_saat_dan_dalam_jumlah_yang_cukup_untuk_semua_staf_kebersihan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua anggota staf kebersihan memiliki APD yang memadai"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa tetapi tidak semua staf memiliki APD atau APD lengkap yang tersedia tetapi dalam kondisi buruk"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Emergency Preparedness</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Emergency Preparedness</b>
                      <p class="ma-0 color_txt small_txt">
                        [Jika beban pasien meningkat] Staf tambahan (misalnya
                        daftar nama) dan perlengkapan kebersihan tambahan
                        tersedia untuk ditempatkan di fasilitas jika beban
                        pasien meningkat
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[65]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Lihat CDC Praktik terbaik untuk pembersihan
                            lingkungan dalam pengaturan terbatas sumber daya
                            untuk panduan lebih lanjut:
                            https://www.cdc.gov/hai/pdfs/resource-limited/environmental-cleaning-RLS-H.pdf
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Emergency_Preparedness[
                            'Staf_tambahan__misalnya_daftar_nama__dan_perlengkapan_kebersihan_tambahan_tersedia_untuk_ditempatkan_di_fasilitas_jika_beban_pasien_meningkat'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Staf tambahan dan persediaan keduanya tersedia"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Baik staf atau persediaan tidak cukup untuk memenuhi kebutuhan tambahan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada staf atau persediaan tambahan yang tersedia."
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Laundry</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Laundry</b>
                      <p class="ma-0 color_txt small_txt">
                        Semua tempat tidur/kasur memiliki penutup tahan air yang
                        tanpa tanda-tanda kerusakan (robek, sobek atau lubang)
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[66]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Untuk perawatan rawat inap jangka panjang, penutup
                            tahan air harus dapat dilepas dan bernapas.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Laundry[
                            'Semua_tempat_tidur_kasur_memiliki_penutup_tahan_air_yang_tanpa_tanda_tanda_kerusakan_robek_sobek_atau_lubang'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua tempat tidur/kasur memiliki penutup tahan air tanpa tanda-tanda kerusakan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tempat tidur / kasur memiliki penutup tahan air tetapi beberapa atau semua rusak"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada penutup tahan air"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Laundry</b>
                      <p class="ma-0 color_txt small_txt">
                        Fasilitas binatu bersih, terawat dengan baik dan mampu
                        memenuhi permintaan (misalnya untuk mencuci linen dari
                        tempat tidur pasien di antara setiap pasien)
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[67]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Area binatu harus memiliki drainase yang efektif
                            dan berendam dan harus ada area yang cukup untuk
                            mengeringkan linen dengan udara. Linen kotor harus
                            disimpan dalam kantong terpisah, tertutup, ditandai
                            untuk transportasi dan penyimpanan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Laundry[
                            'Fasilitas_binatu_bersih_terawat_dengan_baik_dan_mampu_memenuhi_permintaan__misalnya_untuk_mencuci_linen_dari_tempat_tidur_pasien_di_antara_setiap_pasien_'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Fasilitas binatu bersih, terawat dengan baik dan dapat memenuhi permintaan"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Fasilitas ada tetapi tidak bersih, terawat dengan baik atau mampu memenuhi permintaan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada fasilitas fungsional"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Laundry</b>
                      <p class="ma-0 color_txt small_txt">
                        Layanan binatu dengan air panas (70–80°C x 10 menit)
                        untuk memproses ulang kain dan kepala pel tersedia, dan
                        kepala pel serta kain pembersih selalu dicuci secara
                        terpisah dari tekstil rumah sakit kotor lainnya.
                      </p>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Laundry[
                            'Layanan_binatu_dengan_air_panas'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Fasilitas binatu dengan air panas yang tersedia dan bahan pembersih dicuci secara terpisah"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Fasilitas binatu tersedia tetapi air tidak cukup suhu atau bahan pembersih tidak dicuci secara terpisah"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada layanan seperti itu yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Food Hygiene</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Food Hygiene</b>
                      <p class="ma-0 color_txt small_txt">
                        [Khusus rumah sakit] Makanan disiapkan dan ditangani
                        dengan aman (dengan tangan bersih, pada permukaan bersih
                        dan dengan peralatan bersih)
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[68]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Permukaan yang digunakan untuk persiapan makanan
                            harus dicuci dengan deterjen dan air yang aman dan
                            kemudian dibilas, atau diseka dengan kain bersih
                            yang sering dicuci. Sisa-sisa makanan harus dibuang
                            dengan cepat, karena merupakan reservoir potensial
                            bagi bakteri dan dapat menarik serangga dan hewan
                            pengerat. Menolak harus disimpan di tempat sampah
                            tertutup dan dibuang dengan cepat dan aman. <br />
                            Peralatan makan harus dicuci segera setelah
                            digunakan dengan air panas dan deterjen, dan
                            dikeringkan dengan udara. <br />
                            Untuk panduan lebih lanjut, lihat WHO (2008) Standar
                            dan
                            https://www.who.int/foodsafety/publications/all/en/
                            kesehatan lingkungan yang penting
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Food_Hygiene[
                            'Makanan_disiapkan_dan_ditangani_dengan_aman__dengan_tangan_bersih_pada_permukaan_bersih_dan_dengan_peralatan_bersih_'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Makanan disiapkan dan ditangani dengan aman"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa tetapi tidak semua langkah keamanan pangan diikuti (lihat catatan)"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada langkah-langkah keamanan pangan yang diikuti / keamanan pangan sangat buruk"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Food Hygiene</b>
                      <p class="ma-0 color_txt small_txt">
                        [Khusus rumah sakit] Toko dapur dan makanan siap saji
                        dilindungi dari lalat, serangga dan tikus lainnya
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[69]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Area persiapan makanan harus dijaga kebersihannya
                            dan dilindungi dari hewan pengerat dan serangga.
                            Penyimpanan makanan harus ditutup untuk mencegah
                            kontaminasi oleh tikus dan lalat.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          lingkung_bersih.Food_Hygiene[
                            'Toko_dapur_dan_makanan_siap_saji_dilindungi_dari_lalat_serangga_dan_tikus_lainnya'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada lalat, serangga, atau tikus yang dapat mengakses toko makanan dan dapur"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Toko makanan dan makanan sebagian dilindungi tetapi dapat ditingkatkan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Toko makanan dan makanan tidak memiliki perlindungan dari lalat, serangga, atau tikus"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- ========================================STEP 6 ============================================= -->
              <v-stepper-content step="6" class="pa-0">
                <v-card class="pa-5" min-height="500px" v-if="energi" flat>
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Energy</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Energy</b>
                      <p class="ma-0 color_txt small_txt">
                        Fasyankes memiliki sumber listrik yang fungsional dan
                        terawat dengan baik (misalnya jaringan listrik, surya)
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[70]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Dalam pengaturan rawat inap yang terisolasi
                            (seperti rumah sakit pedesaan) dan dalam struktur
                            sementara (seperti pusat perawatan kolera),
                            generator atau panel surya dan baterai kemungkinan
                            akan diperlukan dan penyediaan untuk ini harus
                            dilakukan. Minimal, jenis minyak tanah atau lentera
                            gas yang aman dan obor tangan yang kuat harus
                            tersedia.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Energy[
                            'Fasyankes_memiliki_sumber_listrik_yang_fungsional_dan_terawat_dengan_baik__misalnya_jaringan_listrik_surya_'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Sumber listrik ada, fungsional dan terawat dengan baik"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Ya, ada tetapi saat ini tidak berfungsi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada listrik"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Energy</b>
                      <p class="ma-0 color_txt small_txt">
                        Energi cukup untuk semua kebutuhan listrik fasilitas,
                        termasuk untuk penerangan dan perangkat yang berdiri
                        sendiri (misalnya Program yang Diperluas pada rantai
                        dingin Imunisasi)
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[71]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Semua kebutuhan mencakup pencahayaan, komunikasi,
                            peralatan/peralatan medis, dan perumahan staf.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Energy[
                            'Energi_cukup_untuk_semua_kebutuhan_listrik_fasilitas_termasuk_untuk_penerangan_dan_perangkat_yang_berdiri_sendiri__misalnya_Program_yang_Diperluas_pada_rantai_dingin_Imunisasi_'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Energi dalam jumlah yang cukup setiap saat"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Energi cukup untuk memenuhi beberapa tetapi tidak semua permintaan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada energi yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Energy</b>
                      <p class="ma-0 color_txt small_txt">
                        [Di mana air dipompa] Energi yang cukup tersedia untuk
                        memompa air
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[72]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Jika peningkatan ke sistem pemompaan diperlukan,
                            ini harus mempertimbangkan penggunaan energi
                            terbarukan (misalnya matahari)
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Energy[
                            'Energi_yang_cukup_tersedia_untuk_memompa_air'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Energi dalam jumlah yang cukup setiap saat"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Energi cukup untuk memenuhi beberapa tetapi tidak semua permintaan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada energi yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Energy</b>
                      <p class="ma-0 color_txt small_txt">
                        [Di mana air dipanaskan] Energi yang cukup tersedia
                        untuk memanaskan air
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[73]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Jika peningkatan ke sistem pemanas diperlukan, ini
                            harus mempertimbangkan penggunaan energi terbarukan
                            (misalnya matahari)
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Energy[
                            'Energi_yang_cukup_tersedia_untuk_memanaskan_air'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Energi dalam jumlah yang cukup setiap saat"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Energi cukup untuk memenuhi beberapa tetapi tidak semua permintaan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada energi yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ==============================================================================================================  -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Backup</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Backup</b>
                      <p class="ma-0 color_txt small_txt">
                        Sumber energi cadangan fungsional (misalnya generator
                        dengan bahan bakar yang memadai), ada jika sumber utama
                        gagal
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[74]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Sumber cadangan mungkin diperlukan untuk perangkat
                            medis, lemari es, penerangan, dan memompa air. Ini
                            harus menyala secara otomatis jika sumber daya rutin
                            terputus. Anggaran yang cukup harus tersedia untuk
                            bahan bakar untuk generator cadangan listrik. .
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Backup[
                            'Sumber_energi_cadangan_fungsional__misalnya_generator_dengan_bahan_bakar_yang_memadai__ada_jika_sumber_utama_gagal'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Ada sumber cadangan, dengan bahan bakar yang memadai"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sumber cadangan ada tetapi tidak berfungsi atau bahan bakar tidak mencukupi Beberapa tetapi tidak semua pencahayaan hemat energi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada sumber cadangan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Efficiency</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Efficiency</b>
                      <p class="ma-0 color_txt small_txt">
                        Pencahayaan hemat energi digunakan dengan kontrol
                        pencahayaan yang ditingkatkan dan lampu hemat energi
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[75]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Cahaya alami mungkin cukup di siang hari dan harus
                            digunakan jika memungkinkan untuk mengurangi
                            konsumsi energi.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Efficiency[
                            'Pencahayaan_hemat_energi_digunakan_dengan_kontrol_pencahayaan_yang_ditingkatkan_dan_lampu_hemat_energi'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua pencahayaan hemat energi"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa tetapi tidak semua pencahayaan hemat energi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada pencahayaan hemat energi yang tersedia atau statusnya tidak diketahui"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Adequacy</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Adequacy</b>
                      <p class="ma-0 color_txt small_txt">
                        Ruang bersalin cukup terang, termasuk di malam hari
                      </p>

                      <v-radio-group
                        v-model="
                          energi.Adequacy[
                            'Ruang_bersalin_cukup_terang_termasuk_di_malam_hari'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Ruang bersalin memiliki pencahayaan yang berfungsi"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Infrastruktur penerangan ada, tetapi tidak berfungsi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak cukup penerangan atau tidak ada infrastruktur pencahayaan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Adequacy</b>
                      <p class="ma-0 color_txt small_txt">
                        Kamar mandi cukup terang, termasuk di malam hari
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[76]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Penerangan untuk mandi diperlukan di semua
                            fasilitas di mana layanan malam hari disediakan dan
                            di mana tidak ada cahaya alami yang cukup untuk
                            menggunakan pancuran dengan aman di siang hari.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Adequacy[
                            'Kamar_mandi_cukup_terang_termasuk_di_malam_hari'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua kamar mandi  memiliki pencahayaan yang berfungsi"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Alat penerangan ada, tetapi tidak berfungsi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak cukup penerangan atau tidak ada alat penerangan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Adequacy</b>
                      <p class="ma-0 color_txt small_txt">
                        Jamban cukup terang, termasuk di malam hari
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[77]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Pencahayaan untuk jamban diperlukan di semua
                            fasilitas di mana layanan malam hari disediakan dan
                            di mana tidak ada cahaya alami yang cukup untuk
                            menggunakan jamban dengan aman di siang hari.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Adequacy[
                            'Jamban_cukup_terang_termasuk_di_malam_hari'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua jamban memiliki pencahayaan yang berfungsi"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Infrastruktur penerangan ada, tetapi tidak berfungsi"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak cukup penerangan atau tidak ada infrastruktur pencahayaan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Ventilation</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Ventilation</b>
                      <p class="ma-0 color_txt small_txt">
                        Ventilasi lingkungan yang berfungsi cukup (alami atau
                        mekanis) tersedia di area perawatan pasien
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[78]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Bangunan harus ditempatkan dan dibangun menggunakan
                            desain dan bahan yang menghasilkan kondisi dalam
                            ruangan terbaik (misalnya jendela yang lebih besar,
                            overhang besar untuk naungan di iklim yang lebih
                            panas), dengan mempertimbangkan iklim lokal dan
                            angin yang berlaku. Bangunan dapat ditingkatkan
                            dengan penggunaan tirai yang efektif, membuka dan
                            menutup pintu dan jendela, penanaman vegetasi yang
                            sesuai di sekitar gedung dan langkah-langkah
                            operasional lainnya untuk membantu mengoptimalkan
                            kondisi dalam ruangan. Jika iklim memungkinkan,
                            jendela terbuka besar, skylight, dan ventilasi
                            lainnya dapat digunakan untuk mengoptimalkan
                            ventilasi alami. Kipas langit-langit dan ventilator
                            portabel kecil tidak disarankan karena mengeluarkan
                            debu di sekitar ruangan (terutama untuk area
                            steril). Meningkatkan ventilasi mengurangi
                            ketergantungan pada AC. WHO 2009. Ventilasi alami
                            untuk pengendalian infeksi dalam pengaturan
                            perawatan kesehatan.
                            https://apps.who.int/iris/bitstream/handle/10665/44167/9789241547857_eng.pdf?sequence=1
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Ventilation[
                            'Ventilasi_lingkungan_yang_berfungsi_cukup__alami_atau_mekanis__tersedia_di_area_perawatan_pasien'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Ventilasi cukup dan berfungsi di semua area pasien"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa ventilasi tersedia tetapi tidak terawat dengan baik atau tidak cukup untuk menghasilkan ventilasi udara alami"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada ventilasi"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Vector Control</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Vector Control</b>
                      <p class="ma-0 color_txt small_txt">
                        [Di daerah endemis malaria] Tempat tidur memiliki
                        kelambu yang dirawat insektisida untuk melindungi pasien
                        dari penyakit bawaan nyamuk
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[79]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Insecticide-treated nets should be washed and
                            re-impregnated every 6 months if used only for
                            patients with non-infectious diseases. For patients
                            with infectious diseases such as cholera, mosquito
                            nets are not advisable because the staff member
                            needs access to the patient. Other methods such as
                            indoor residual spraying or coils will be needed.
                            Nets are only provided exceptionally (patient
                            request, patient comfort, etc.) and burned after
                            use.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Vector_Control[
                            'Tempat_tidur_memiliki_kelambu_yang_dirawat_insektisida_untuk_melindungi_pasien_dari_penyakit_bawaan_nyamuk'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua tempat tidur rawat inap memiliki jaring"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tersedia di beberapa, tetapi tidak semua tempat tidur, atau tersedia tetapi dalam kondisi buruk"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada kelambu yang tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Procurement</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Procurement</b>
                      <p class="ma-0 color_txt small_txt">
                        Pengadaan berkelanjutan (menggunakan pendekatan siklus
                        hidup) diterapkan di seluruh fasilitas
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[80]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Pengadaan berkelanjutan termasuk menggunakan produk
                            yang tersedia secara lokal yang telah disertifikasi
                            oleh lembaga sertifikasi terakreditasi dan yang
                            sesuai dengan standar internasional dipilih. (Produk
                            tersebut harus aman digunakan, memenuhi persyaratan
                            toksisitas, tahan lama, hemat energi dan sumber
                            daya).
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          energi.Procurement[
                            'Pengadaan_berkelanjutan__menggunakan_pendekatan_siklus_hidup__diterapkan_di_seluruh_fasilitas'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Pengadaan berkelanjutan secara konsisten diterapkan di seluruh fasilitas"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Pendekatan pengadaan berkelanjutan ada tetapi tidak diterapkan dengan baik "
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada pendekatan yang ada"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Environment</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Environment</b>
                      <p class="ma-0 color_txt small_txt">
                        Tempat sampah umum tersedia di semua area publik, sampah
                        secara teratur dikeluarkan dari interior dan eksterior
                        fasilitas, dan upaya dilakukan untuk meningkatkan dan
                        mempertahankan penampilan estetika fasilitas melalui
                        pengecatan, lansekap (tanaman) dan memastikan bahwa
                        semua peralatan dan barang-barang lainnya disimpan
                        dengan aman
                      </p>
                      <v-radio-group
                        v-model="
                          energi.Environment[
                            'Tempat_sampah_umum_tersedia_di_semua_area_publik'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Upaya dilakukan untuk menjaga penampilan fasilitas secara keseluruhan yang rapi, bebas dari sampah dan terawat dengan baik"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa upaya dilakukan untuk menunjukkan fasilitas tetapi lebih banyak yang bisa dilakukan."
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada upaya yang dilakukan untuk mempertahankan apperance fasilitas"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>

              <!-- ========================================STEP 7 ============================================= -->
              <v-stepper-content step="7" class="pa-0">
                <v-card class="pa-5" min-height="500px" flat v-if="kerja">
                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Management</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Management</b>
                      <p class="ma-0 color_txt small_txt">
                        Fasyankes memiliki tim peningkatan kualitas yang
                        berfungsi/IPC atau WASH FIT
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[81]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Mungkin ada satu tim atau entitas terpisah untuk
                            QI, IPC dan WASH. <br />
                            Setidaknya satu anggota staf kebersihan/pengawas
                            kebersihan/perusahaan pembersih kontrak harus
                            diwakili dalam tim WASH FIT, IPC atau QI dan
                            terlibat dalam pengambilan keputusan/pengembangan
                            rencana perbaikan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          kerja.Management[
                            'Fasyankes_memiliki_tim_peningkatan_kualitas_yang_berfungsi_IPC_atau_WASH_FIT'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Tim ada, memiliki TOR yang jelas, bertemu secara teratur dengan kepemimpinan yang baik dan keputusan dicatat dan ditindaklanjuti"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tim bertemu tetapi secara tidak teratur, informal, tidak memiliki TOR yang jelas, dll."
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada tim seperti itu dan/atau tidak ada titik fokus yang ada"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ==============================================================================================================  -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Personnel</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Personnel</b>
                      <p class="ma-0 color_txt small_txt">
                        Fasyankes memiliki focal point atau tenaga teknis WASH
                        khusus yang bekerja untuk program kerja yang disetujui,
                        dengan dukungan pimpinan senior
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[82]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Titik fokus WASH/IPC harus bertanggung jawab atas
                            pemeliharaan WASH dan infrastruktur limbah perawatan
                            kesehatan. <br />
                            Di rumah sakit, juga harus ada focal person IPC
                            khusus selain focal point WASH. <br />
                            Kerangka Penilaian IPC WHO (IPCAF)
                            (https://www.who.int/infection-prevention/tools/core-components/IPCAF-facility.PDF)
                            dapat diselesaikan. IPCAF adalah alat sistematis
                            yang dapat memberikan penilaian dasar terhadap
                            program dan kegiatan IPC di dalam fasilitas
                            perawatan kesehatan, serta evaluasi berkelanjutan
                            melalui administrasi berulang untuk
                            mendokumentasikan kemajuan dari waktu ke waktu dan
                            memfasilitasi perbaikan. Menambahkan dukungan
                            kepemimpinan
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          kerja.Personnel[
                            'Fasyankes_memiliki_focal_point_atau_tenaga_teknis_WASH_khusus_yang_bekerja_untuk_program_kerja_yang_disetujui_dengan_dukungan_pimpinan_senior'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Ada orang fokus yang berdedikasi"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Focal point ada tetapi tidak memiliki waktu, sumber daya atau motivasi yang cukup untuk melaksanakan tugas"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Management</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Management</b>
                      <p class="ma-0 color_txt small_txt">
                        Kelompok perempuan, disabilitas dan masyarakat adat,
                        serta pengguna dan staf khusus lainnya (misalnya
                        perawat, bidan, petugas kebersihan) dikonsultasikan
                        tentang kebutuhan WASH dan desain teknologi, dan
                        suara-suara ini memengaruhi pilihan, penempatan, dan
                        pemeliharaan teknologi
                      </p>

                      <v-radio-group
                        v-model="
                          kerja.Management[
                            'Kelompok_perempuan_disabilitas_dan_masyarakat_adat_serta_pengguna_dan_staf_khusus_lainnya'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Groups are adequately consulted and voices influence improvements"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Only some groups are consulted and/or voices do not influence improvements"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="None of the above are consulted "
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Management</b>
                      <p class="ma-0 color_txt small_txt">
                        Diagram terbaru dari struktur manajemen fasilitas,
                        termasuk staf kebersihan, terlihat jelas dan terbaca
                      </p>
                      <v-radio-group
                        v-model="
                          kerja.Management[
                            'Diagram_terbaru_dari_struktur_manajemen_fasilitas_termasuk_staf_kebersihan_terlihat_jelas_dan_terbaca'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Struktur manajemen fasilitas terkini ada (dan terbaca)"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Struktur manajemen ada tetapi tidak up to date atau tidak terlihat"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak tersedia"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Management</b>
                      <p class="ma-0 color_txt small_txt">
                        Semua staf tambahan, termasuk penangan limbah dan mereka
                        yang membersihkan, memiliki deskripsi pekerjaan yang
                        jelas dan tertulis, yang menguraikan tanggung jawab WASH
                        dan IPC
                      </p>
                      <v-radio-group
                        v-model="
                          kerja.Management[
                            'Semua_staf_tambahan_termasuk_penangan_limbah_dan_mereka_yang_membersihkan_memiliki_deskripsi_pekerjaan_yang_jelas_dan_tertulis_yang_menguraikan_tanggung_jawab_WASH_dan_IPC'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua staf memiliki deskripsi pekerjaan tertulis termasuk tanggung jawab WASH dan IPC"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa, tetapi tidak semua, staf memiliki deskripsi pekerjaan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada deskripsi pekerjaan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Personnel</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Personnel</b>
                      <p class="ma-0 color_txt small_txt">
                        Semua staf tambahan baru, termasuk penangan limbah dan
                        mereka yang membersihkan, menerima pelatihan WASH dan
                        IPC yang sesuai, disesuaikan dengan fungsi pekerjaan
                        mereka
                      </p>
                      <v-radio-group
                        v-model="
                          kerja.Personnel[
                            'Semua_staf_tambahan_baru_termasuk_penangan_limbah_dan_mereka_yang_membersihkan_menerima_pelatihan_WASH_dan_IPC_yang_sesuai_disesuaikan_dengan_fungsi_pekerjaan_mereka'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Semua staf baru dilatih secara memadai, sesuai dengan fungsinya"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa tetapi tidak semua staf dilatih atau pelatihan tidak sesuai dengan fungsinya"
                                color="#00ccb6"
                                value="2"
                              >
                              </v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada pelatihan yang berlangsung"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Personnel</b>
                      <p class="ma-0 color_txt small_txt">
                        Staf secara teratur (setidaknya setiap tahun) dinilai
                        berdasarkan kinerja mereka (misalnya kebersihan tangan);
                        staf berkinerja tinggi diakui dan / atau dihargai, dan
                        mereka yang tidak berkinerja baik didukung untuk
                        meningkatkan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[83]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Kinerja mungkin dalam kaitannya dengan kepatuhan
                            kebersihan tangan, serta kasih sayang, mengikuti
                            prosedur medis yang benar, mendengarkan pasien, dll.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          kerja.Personnel[
                            'Staf_secara_teratur__setidaknya_setiap_tahun__dinilai_berdasarkan_kinerja_mereka__misalnya_kebersihan_tangan_'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Staf dinilai secara teratur (setidaknya setiap tahun)"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Beberapa tetapi tidak semua staf dinilai atau staf tidak cukup didukung untuk meningkatkan"
                                color="#00ccb6"
                                value="2"
                              >
                              </v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada penilaian staf yang dilakukan, yaitu tidak ada tindakan atau pengakuan staf berdasarkan kinerja"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">SOPs</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>SOPs</b>
                      <p class="ma-0 color_txt small_txt">
                        Protokol dan sistem yang efektif tersedia untuk operasi
                        dan pemeliharaan infrastruktur yang berkelanjutan dan
                        pengadaan persediaan yang diperlukan untuk operasi dan
                        pemeliharaan
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[84]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Protokol ini mungkin terkait dengan pembersihan
                            lingkungan (disesuaikan untuk area layanan yang
                            berbeda), penanganan limbah dan operasi &
                            pemeliharaan pasokan air dan sanitasi. <br /><br />

                            Manajer program kebersihan, tim pengadaan fasilitas
                            dan/atau IPC fasilitas atau komite kebersihan harus
                            mengembangkan daftar induk persediaan dan peralatan
                            (yaitu spesifikasi terperinci dan informasi pemasok)
                            dan jumlah yang diperlukan (misalnya setiap tahun)
                            yang diperlukan. Hasil inspeksi rutin dan kegiatan
                            pemeliharaan harus menentukan jumlah persediaan dan
                            peralatan yang diperlukan. Fasilitas besar mungkin
                            memiliki toko pusat yang menerima persediaan dan
                            peralatan dan mendistribusikannya ke area layanan
                            pembersihan lingkungan yang ditunjuk di seluruh
                            fasilitas secara teratur setelah laporan inventaris.
                            Memiliki sistem seperti itu juga akan mencegah
                            kehabisan stok dan membantu memperoleh pasokan
                            tambahan sesuai kebutuhan selama keadaan darurat /
                            kontinjensi.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          kerja.SOP[
                            'Protokol_dan_sistem_yang_efektif_tersedia_untuk_operasi_dan_pemeliharaan_infrastruktur_yang_berkelanjutan_dan_pengadaan_persediaan_yang_diperlukan_untuk_operasi_dan_pemeliharaan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Sistem ada dan berfungsi (item diperoleh dan infrastruktur diperbaiki saat dan bila diperlukan)"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Sistem ada tetapi tidak berfungsi (yaitu fasilitas tidak dapat memperoleh pasokan atau infrastruktur tidak diperbaiki secara memadai)"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada sistem"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Budget</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Budget</b>
                      <p class="ma-0 color_txt small_txt">
                        Anggaran tersedia untuk menutupi biaya pembersih dan
                        staf pemeliharaan, pelatihan IPC/WASH, bahan habis pakai
                        IPC/WASH (misalnya sabun, klorin) dan semua kegiatan
                        yang tercantum dalam protokol pengadaan.
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[85]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Anggaran tersebut mencakup biaya modal dan
                            operasional, termasuk personel, pelatihan staf,
                            perlengkapan dan peralatan kebersihan, peralatan
                            untuk pemantauan program, biaya administrasi, biaya
                            produksi dan pencetakan untuk daftar periksa, log
                            dan alat bantu pekerjaan lainnya dan biaya
                            infrastruktur/layanan (misalnya layanan air dan air
                            limbah). Mungkin berguna untuk membagi anggaran ke
                            dalam kategori: personel; infrstruktur; peralatan;
                            Persediaan.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          kerja.Budget[
                            'Anggaran_tersedia_untuk_menutupi_biaya_pembersih_dan_staf_pemeliharaan'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Anggaran ada dan ditujukan untuk staf/pelatihan dan bahan habis pakai/O&M"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Anggaran ada untuk staf tetapi tidak untuk pelatihan / atau untuk bahan habis pakai tetapi tidak untuk O&M 
/ atau anggaran tidak cukup untuk menutupi semua biaya."
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada anggaran"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Policies</b>
                    </div>
                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Policies</b>
                      <p class="ma-0 color_txt small_txt">
                        Kebijakan/piagam keselamatan pasien di seluruh Fasyankes
                        untuk meningkatkan kualitas perawatan ditulis, terkini,
                        dan operasional
                      </p>
                      <v-radio-group
                        v-model="
                          kerja.Policies[
                            'Kebijakan_piagam_keselamatan_pasien_di_seluruh_Fasyankes_untuk_meningkatkan_kualitas_perawatan_ditulis_terkini_dan_operasional'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Kebijakan tersedia, up-to-date dan operasional"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kebijakan tidak operasional, atau perlu diperbarui/ tidak realistis"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada kebijakan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Policies </b>
                      <p class="ma-0 color_txt small_txt">
                        Kebijakan/piagam ramah lingkungan di seluruh Fasyankes
                        ditulis dan beroperasi
                      </p>

                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[86]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Konsep ketahanan iklim harus diintegrasikan ke
                            dalam semua strategi dan rencana WASH fasilitas.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          kerja.Policies[
                            'Kebijakan_piagam_ramah_lingkungan_di_seluruh_Fasyankes_ditulis_dan_beroperasi'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Kebijakan tertulis dan operasional"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Kebijakan tertulis tetapi tidak operasional"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada kebijakan"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>

                  <!-- ============================================================================================================== -->
                  <v-card class="pa-3 mb-4 rounded-max" outlined>
                    <div class="mb-3">
                      <b class="color_default">Emergency preparedness</b>
                    </div>

                    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mb-3">
                      <b>Emergency preparedness</b>
                      <p class="ma-0 color_txt small_txt">
                        Rencana kesiapsiagaan dan tanggap darurat sudah ada,
                        dianggarkan dan diperbarui secara berkala; Staf
                        menjalani pelatihan dan latihan untuk mempersiapkan,
                        menanggapi, dan pulih dari peristiwa terkait cuaca
                        ekstrem, terutama di mana perubahan iklim merupakan
                        faktor yang berkontribusi
                      </p>
                      <v-row>
                        <v-tooltip
                          right
                          v-model="showTooltip[87]"
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on }">
                            <div class="d-flex flex-row pa-2 mt-2 ml-1">
                              <div
                                class="d-flex pa-0 align-center"
                                elevation="0"
                                min-width="0"
                                height="0"
                                v-on="on"
                                style="cursor: pointer"
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="../../../assets/img/info-icon.svg"
                                  alt=""
                                />
                              </div>
                              <span
                                class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                                >informasi</span
                              >
                            </div>
                          </template>
                          <span
                            >Rencana darurat diperbarui secara berkala dan
                            berulang berdasarkan informasi baru & data iklim dan
                            kerentanan. Sistem tersedia untuk menindaklanjuti
                            saran dan peringatan cuaca ekstrem untuk mengurangi
                            risiko kesehatan. Rencana tersebut harus mencakup
                            langkah-langkah untuk mendapatkan pasokan ketika
                            permintaan / beban pasien meningkat, terkait dengan
                            <br />
                            - sistem air (misalnya klorin, filter atau teknologi
                            pengolahan air lainnya rapid water testing kit)
                            <br />
                            - kebersihan tangan (sabun, gosok tangan berbasis
                            alkohol, stasiun kebersihan tangan, dll.) <br />
                            - pembersihan lingkungan (bahan kimia pembersih,
                            pel, ember, dll.). <br />
                            Pertimbangan lain termasuk ketersediaan tenaga
                            kesehatan siaga saat dibutuhkan, ketersediaan APD
                            ekstra, sistem untuk mengoperasikan fasilitas
                            kesehatan 24/7 selama keadaan darurat, atau
                            persiapan untuk mendukung selama COVID-19 seperti
                            pandemi.
                          </span>
                        </v-tooltip>
                      </v-row>

                      <v-radio-group
                        v-model="
                          kerja.Emergency_Preparedness[
                            'Rencana_kesiapsiagaan_dan_tanggap_darurat_sudah_ada_dianggarkan_dan_diperbarui_secara_berkala'
                          ][0].dataPenyusun_desc
                        "
                        dense
                      >
                        <v-row>
                          <v-col cols="4">
                            <v-card
                              style="background: none !important"
                              class="pa-3 rounded-max"
                              height="100%"
                              outlined
                            >
                              <v-radio
                                label="Rencana sudah ada dan staf cukup terlatih"
                                color="#00ccb6"
                                value="1"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Rencana sudah ada tetapi bukan pelatihan yang dilakukan, atau rencana tidak realistis, atau tidak dilaksanakan"
                                color="#00ccb6"
                                value="2"
                              ></v-radio>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card
                              class="pa-3 rounded-max"
                              height="100%"
                              style="background: none !important"
                              outlined
                            >
                              <v-radio
                                label="Tidak ada rencana"
                                color="#00ccb6"
                                value="3"
                              ></v-radio>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </v-card>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <div class="py-3 d-flex justify-center">
            <div class="d-flex align-center">
              <v-btn
                color="white"
                dark
                class="pa-3 custom_shadow rounded-lg text-capitalize"
                @click="switchStep('prev')"
                ><v-icon small color="#00b4cc"
                  >mdi-arrow-left-circle-outline</v-icon
                >
                <p class="ma-0 color_default ml-2">Kembali</p>
              </v-btn>
              <div class="mx-5">
                <p class="ma-0 color_default">{{ step }}/7</p>
              </div>
              <v-btn
                v-if="step != 7"
                min-width="10px"
                color="#00b4cc"
                dark
                class="pa-2 custom_shadow rounded-lg text-capitalize"
                @click="switchStep('next')"
              >
                <p class="ma-0 mr-2">Lanjut</p>
                <v-icon small>mdi-arrow-right-circle-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="step == 7"
                min-width="10px"
                color="#00b4cc"
                dark
                class="pa-2 custom_shadow rounded-lg text-capitalize"
                @click="setupForm(false)"
              >
                <p class="ma-0 mr-2">Kirim</p>
                <v-icon small>mdi-tray-arrow-up</v-icon>
              </v-btn>
            </div>
          </div>
          <div>
            <success-alert v-bind:dialogSuccess="dialogSuccess" />
            <Alert
              v-bind:dialogAlert="dialogAlert"
              v-bind:temporary="temporary"
              v-bind:body="tobeSent"
              @close="dialogAlert = false"
              @save="savePermanent()"
            />
            <v-overlay
              :absolute="true"
              style="height: 100vh; position: fixed"
              color="white"
              opacity="1"
              :value="loading"
            >
              <v-progress-circular
                indeterminate
                class="mr-2"
                color="#00b4cc"
              ></v-progress-circular>
              <b class="color_default">Loading...</b>
            </v-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderFormulir from "../../../components/Formulir/HeaderFormulir.vue";
import Alert from "../../../components/Formulir/Modal/alert.vue";
import SuccessAlert from "../../../components/Formulir/Modal/successAlert.vue";
import QuestionCard from "./Question/Card.vue";

export default {
  name: "KeslingBiasa",
  computed: {
    ...mapState({
      form_data: (state) => state.formulir.kesling,
    }),
    air() {
      let data = null;
      if (this.form_data) {
        data = this.form_data.Kesehatan_Lingkungan.Air;
      }

      return data;
    },
    energi() {
      let data = null;
      if (this.form_data) {
        data = this.form_data.Kesehatan_Lingkungan.Energi_dan_Lingkungan;
      }
      return data;
    },
    lingkung_bersih() {
      let data = null;
      if (this.form_data) {
        data = this.form_data.Kesehatan_Lingkungan.Kebersihan_Lingkungan;
      }
      return data;
    },
    tangan_bersih() {
      let data = null;
      if (this.form_data) {
        data = this.form_data.Kesehatan_Lingkungan.Kebersihan_Tangan;
      }
      return data;
    },

    limbah_medis() {
      let data = null;
      if (this.form_data) {
        data = this.form_data.Kesehatan_Lingkungan.Pengelolaan_Limbah_Medis;
      }
      return data;
    },
    toilet() {
      let data = null;
      if (this.form_data) {
        data = this.form_data.Kesehatan_Lingkungan.Toilet;
      }
      return data;
    },
    kerja() {
      let data = null;
      if (this.form_data) {
        data = this.form_data.Kesehatan_Lingkungan.Manajemen_dan_Tenaga_Kerja;
      }
      return data;
    },
  },
  components: { HeaderFormulir, Alert, SuccessAlert, QuestionCard },
  data() {
    return {
      showTooltip: Array(99).fill(false),
      tabs: "tabs1",
      paginNow: 1,
      step: 1,
      calendar1: false,
      calendar2: false,
      calendar3: false,
      date: "",
      id: null,
      dialogSuccess: false,
      dialogAlert: false,
      temporary: true,
      loading: false,
      loadTempo: false,
      date: null,
      tobeSent: null,
      isMobile: false,
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    await this.getData();
    await this.checkMobile();
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 600;
    },
    tabLanjut() {
      if (this.tabs == "tabs1") {
        this.tabs = "tabs2";
        this.paginNow++;
      } else if (this.tabs == "tabs2") {
        this.tabs = "tabs3";
        this.paginNow++;
      } else if (this.tabs == "tabs3") {
        this.tabs = "tabs4";
        this.paginNow++;
      } else if (this.tabs == "tabs4") {
        this.tabs = "tabs5";
        this.paginNow++;
      } else if (this.tabs == "tabs5") {
        this.tabs = "tabs6";
        this.paginNow++;
      } else if (this.tabs == "tabs6") {
        this.tabs = "tabs7";
        this.paginNow++;
      } else if (this.tabs == "tabs7") {
        this.tabs = "tabs8";
        this.paginNow++;
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    tabKembali() {
      if (this.tabs == "tabs4") {
        this.tabs = "tabs3";
        this.paginNow--;
      } else if (this.tabs == "tabs3") {
        this.tabs = "tabs2";
        this.paginNow--;
      } else if (this.tabs == "tabs2") {
        this.tabs = "tabs1";
        this.paginNow--;
      } else if (this.tabs == "tabs5") {
        this.tabs = "tabs4";
        this.paginNow--;
      } else if (this.tabs == "tabs6") {
        this.tabs = "tabs5";
        this.paginNow--;
      } else if (this.tabs == "tabs7") {
        this.tabs = "tabs6";
        this.paginNow--;
      } else if (this.tabs == "tabs8") {
        this.tabs = "tabs7";
        this.paginNow--;
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    async getData() {
      let body = {
        report_id: this.id,
      };

      this.loading = true;
      
      await this.$store.dispatch("formulir/getKesling", body).then((data) => {
        this.date = data.data.date;
        this.loading = false;
      });
    },
    saveFormulir(body) {
      this.tobeSent = body;
      this.temporary = false;
      this.dialogAlert = true;
    },

    switchStep(state) {
      if (state == "next") {
        if (this.step < 7) {
          this.step++;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      } else {
        if (this.step > 1) {
          this.step--;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
      }
    },
    // update data
    temporarySave(body) {
      this.loadTempo = true;
      this.$store
        .dispatch("formulir/updateDataFormulirCovid", body)
        .then((data) => {
          this.loadTempo = false;
          this.dialogAlert = true;
        })
        .catch((err) => {
          this.loadTempo = false;
          this.Swal("error", err.message);
          console.error(err);
        });
    },
    savePermanent() {
      this.loading = true;
      this.dialogAlert = false;
      this.$store
        .dispatch("formulir/saveKesling", this.tobeSent)
        .then((data) => {
          this.loading = false;
          this.dialogSuccess = true;
          setTimeout(() => {
            this.dialogSuccess = false;
            this.$router.push("/formulir");
          }, 1500);
        });
    },
    // SET UPPP FORMMMMM
    setupForm(draft) {
      let body = {
        report_id: this.id,
        data: [
          // form 1
          {
            name: "Pasokan air dari sumber yang layak disalurkan ke Fasyankes atau terletak di tempat",

            value:
              this.air.Pasokan_Air[
                "Pasokan_air_dari_sumber_yang_layak_disalurkan_ke_Fasyankes_atau_terletak_di_tempat"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Fasyankes ini memiliki persediaan air perpipaan di lokasi",

            value:
              this.air.Pasokan_Air[
                "Fasyankes_ini_memiliki_persediaan_air_perpipaan_di_lokasi"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Semua keran terhubung ke pasokan air yang tersedia dan berfungsi tanpa kebocoran pada pipa",

            value:
              this.air.Pasokan_Air_Leding[
                "Semua_keran_terhubung_ke_pasokan_air_yang_tersedia_dan_berfungsi_tanpa_kebocoran_pada_pipa"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Air tersedia selama jam pelayanan Fasyankes",

            value:
              this.air.Ketersediaan_Air[
                "Air_tersedia_selama_jam_pelayanan_Fasyankes"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Air tersedia pada saat penilaian WASH FIT dilakukan",

            value:
              this.air.Ketersediaan_Air[
                "Air_tersedia_pada_saat_penilaian_WASH_FIT_dilakukan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Air tersedia sepanjang tahun yaitu tidak terpengaruh oleh musim variabilitas cuaca peristiwa ekstrem atau kendala lainnya",

            value:
              this.air.Ketersediaan_Air[
                "Air_tersedia_sepanjang_tahun_yaitu_tidak_terpengaruh_oleh_musim_variabilitas_cuaca_peristiwa_ekstrem_atau_kendala_lainnya"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Sistem pasokan air utama berfungsi selama 3 bulan terakhir tanpa kerusakan besar",

            value:
              this.air.Ketersediaan_Air[
                "Sistem_pasokan_air_utama_berfungsi_selama_3_bulan_terakhir_tanpa_kerusakan_besar"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Sumber air layak tambahan diidentifikasi tersedia dan dapat diakses dan pelihara secara memadai jika sumber utama tidak berfungsi tersedia",
            value:
              this.air.Ketersediaan_Air[
                "Sumber_air_layak_tambahan_diidentifikasi_tersedia_dan_dapat_diakses_dan_pelihara_secara_memadai_jika_sumber_utama_tidak_berfungsi_tersedia"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Memiliki air dalam jumlah yang cukup untuk semua penggunaan",

            value:
              this.air.Ketersediaan_Air[
                "Memiliki_air_dalam_jumlah_yang_cukup_untuk_semua_penggunaan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Fasyankes ini memiliki tangki untuk menyimpan air jika terjadi gangguan pada pasokan utama",

            value:
              this.air.Ketersediaan_Air[
                "Fasyankes_ini_memiliki_tangki_untuk_menyimpan_air_jika_terjadi_gangguan_pada_pasokan_utama"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Sistem pemanenan air hujan (dengan penyimpanan yang aman) berfungsi dan menyimpan air dengan aman",

            value:
              this.air.Ketersediaan_Air[
                "Sistem_pemanenan_air_hujan__dengan_penyimpanan_yang_aman__berfungsi_dan_menyimpan_air_dengan_aman"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Strategi penghematan air digunakan untuk mengurangi pemborosan air",

            value:
              this.air.Konservasi_Air[
                "Strategi_penghematan_air_digunakan_untuk_mengurangi_pemborosan_air"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Air minum memiliki residu klorin bebas yang sesuai",

            value:
              this.air.Air_Minum[
                "Air_minum_memiliki_residu_klorin_bebas_yang_sesuai"
              ][0].dataPenyusun_desc,
          },

          {
            name: "Pasokan air menimbulkan risiko rendah atau tidak beresiko sama sekali terhadap kesehatan masyarakat",

            value:
              this.air.Air_Minum[
                "Pasokan_air_menimbulkan_risiko_rendah_atau_tidak_beresiko_sama_sekali_terhadap_kesehatan_masyarakat"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Air perpipaan diolah dengan pengelolaan air yang aman oleh penyedia layanan/otoritas kota atau air diolah secara teratur di tempat",

            value:
              this.air.Kualitas_Air[
                "Air_perpipaan_diolah_dengan_pengelolaan_air_yang_aman_oleh_penyedia_layanan_otoritas_kota_atau_air_diolah_secara_teratur_di_tempat"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Kualitas air dari semua pasokan air (persediaan primer cadangan dan tambahan) secara rutin diuji oleh staf",

            value:
              this.air.Kualitas_Air[
                "Kualitas_air_dari_semua_pasokan_air__persediaan_primer_cadangan_dan_tambahan__secara_rutin_diuji_oleh_staf"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Dispenser air minum dengan air minum yang aman tersedia dan berfungsi setiap saat di ruang tunggu utama",

            value:
              this.air.Air_Minum[
                "Dispenser_air_minum_dengan_air_minum_yang_aman_tersedia_dan_berfungsi_setiap_saat_di_ruang_tunggu_utama"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Setidaknya satu pancuran atau area pemandian tersedia per 40 pasien rawat inap atau per bangsal (mana yang lebih rendah) dan berfungsi serta dapat diakses",

            value:
              this.air.Shower[
                "Setidaknya_satu_pancuran_atau_area_pemandian_tersedia_per_40_pasien_rawat_inap_atau_per_bangsal__mana_yang_lebih_rendah__dan_berfungsi_serta_dapat_diakses"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Kamar mandi berfungsi untuk wanita yang privasi dan dapat dikunci tersedia di area persalinan dan persalinan",

            value:
              this.air.Shower[
                "Kamar_mandi_berfungsi_untuk_wanita_yang_privasi_dan_dapat_dikunci_tersedia_di_area_persalinan_dan_persalinan"
              ][0].dataPenyusun_desc,
          },

          // FORM 2
          {
            name: "Fasyakes memiliki jumlah toilet yang cukup untuk pasien",

            value:
              this.toilet.Toilet[
                "Fasyakes_memiliki_jumlah_toilet_yang_cukup_untuk_pasien"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Semua toilet pasien tersedia dan dapat digunakan",

            value:
              this.toilet.Toilet[
                "Semua_toilet_pasien_tersedia_dan_dapat_digunakan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Semua toilet memiliki tempat cuci tangan yang berfungsi dalam jarak 5 meter",

            value:
              this.toilet.Toilet[
                "Semua_toilet_memiliki_tempat_cuci_tangan_yang_berfungsi_dalam_jarak_5_meter"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Setidaknya satu toilet yang layak tersedia untuk staf dan toilet dipisahkan atau diberi label dengan jelas",

            value:
              this.toilet.Toilet[
                "Setidaknya_satu_toilet_yang_layak_tersedia_untuk_staf_dan_toilet_dipisahkan_atau_diberi_label_dengan_jelas"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Toilet yang layak dipisahkan diberi label dengan jelas untuk pria wanita yang memberikan privasi",

            value:
              this.toilet.Toilet[
                "Toilet_yang_layak_dipisahkan_diberi_label_dengan_jelas_untuk_pria_wanita_yang_memberikan_privasi"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Setidaknya satu toilet yang dapat digunakan untuk memenuhi kebutuhan manajemen kebersihan menstruasi",

            value:
              this.toilet.Toilet[
                "Setidaknya_satu_toilet_yang_dapat_digunakan_untuk_memenuhi_kebutuhan_manajemen_kebersihan_menstruasi"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Setidaknya satu toilet fungsional yang ditingkatkan memenuhi kebutuhan orang-orang dengan mobilitas terbatas",

            value:
              this.toilet.Toilet[
                "Setidaknya_satu_toilet_fungsional_yang_ditingkatkan_memenuhi_kebutuhan_orang_orang_dengan_mobilitas_terbatas"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Lumpur tinja sepenuhnya terkandung untuk kemudian pengosongan dan perawatan di luar lokasi atau sepenuhnya terkandung dan dirawat di tempat",

            value:
              this.toilet.Faecal_Sludge_Management[
                "Lumpur_tinja_sepenuhnya_terkandung_untuk_kemudian_pengosongan_dan_perawatan_di_luar_lokasi_atau_sepenuhnya_terkandung_dan_dirawat_di_tempat"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Toilets are connected without leaks to a public sewer system",

            value:
              this.toilet.Wastewater_Management[
                "Toilets_are_connected_without_leaks_to_a_public_sewer_system"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Lumpur tinja dari penampungan secara berkala dikosongkan tanpa tumpahan oleh personel terlatih dengan peralatan pelindung yang sesuai",

            value:
              this.toilet.Wastewater_Management[
                "Lumpur_tinja_dari_penampungan_secara_berkala_dikosongkan_tanpa_tumpahan_oleh_personel_terlatih_dengan_peralatan_pelindung_yang_sesuai"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Instalasi pengolahan air limbah yang dirancang dengan baik dan dikelola dengan baik",

            value:
              this.toilet.Wastewater_Management[
                "Instalasi_pengolahan_air_limbah_yang_dirancang_dengan_baik_dan_dikelola_dengan_baik"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Pabrik pengolahan lumpur tinja yang dirancang dengan baik dan dikelola dengan baik dengan catatan operasi yang tersedia untuk umum digunakan dan memenuhi standar kinerja",

            value:
              this.toilet.Faecal_Sludge_Management[
                "Pabrik_pengolahan_lumpur_tinja_yang_dirancang_dengan_baik_dan_dikelola_dengan_baik_dengan_catatan_operasi_yang_tersedia_untuk_umum_digunakan_dan_memenuhi_standar_kinerja"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Sistem drainase air hujan (yaitu air hujan) dan greywater tersedia yang mengalihkan air dari fasilitas ke drainase yang aman atau area ladang larut",

            value:
              this.toilet.Stormwater_Management[
                "Sistem_drainase_air_hujan__yaitu_air_hujan__dan_greywater_tersedia_yang_mengalihkan_air_dari_fasilitas_ke_drainase_yang_aman_atau_area_ladang_larut"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Greywater dan atau stormwater ditangkap dan digunakan kembali untuk mencuci membersihkan menyiram tanaman dan menyiram toilet",

            value:
              this.toilet.Stormwater_Management[
                "Greywater_dan_atau_stormwater_ditangkap_dan_digunakan_kembali_untuk_mencuci_membersihkan_menyiram_tanaman_dan_menyiram_toilet"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Greywater dari wastafel dan fasilitas binatu ditangkap dengan aman dan diarahkan ke saluran pembuangan ladang pelarut lubang rendam atau saluran air tertutup tanpa koneksi silang dengan pasokan air minum",

            value:
              this.toilet.Greywater_Management[
                "Greywater_dari_wastafel_dan_fasilitas_binatu_ditangkap_dengan_aman_dan_diarahkan_ke_saluran_pembuangan_ladang_pelarut_lubang_rendam_atau_saluran_air_tertutup_tanpa_koneksi_silang_dengan_pasokan_air_minum"
              ][0].dataPenyusun_desc,
          },

          // FORM 3
          {
            name: "Wadah pengumpulan limbah fungsional tersedia di dekat semua titik timbulan limbah untuk limbah non infeksius umum limbah infeksius dan limbah tajam",

            value:
              this.limbah_medis.Segregation[
                "Wadah_pengumpulan_limbah_fungsional_tersedia_di_dekat_semua_titik_timbulan_limbah_untuk_limbah_non_infeksius_umum_limbah_infeksius_dan_limbah_tajam"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Sampah dipilah dengan benar di semua titik timbulan sampah",

            value:
              this.limbah_medis.Segregation[
                "Sampah_dipilah_dengan_benar_di_semua_titik_timbulan_sampah"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Pengingat untuk pemilahan sampah yang benar terlihat jelas di semua titik timbulan sampah",

            value:
              this.limbah_medis.Segregation[
                "Pengingat_untuk_pemilahan_sampah_yang_benar_terlihat_jelas_di_semua_titik_timbulan_sampah"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Peralatan dan sumber daya pelindung yang sesuai untuk melakukan kebersihan tangan tersedia untuk semua staf yang bertanggung jawab untuk menangani limbah dan bertanggung jawab atas pengolahan dan pembuangan limbah",

            value:
              this.limbah_medis.Personnel[
                "Peralatan_dan_sumber_daya_pelindung_yang_sesuai_untuk_melakukan_kebersihan_tangan_tersedia_untuk_semua_staf_yang_bertanggung_jawab_untuk_menangani_limbah_dan_bertanggung_jawab_atas_pengolahan_dan_pembuangan_limbah"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Pengingat dan pelatihan tersedia untuk mempromosikan dan memantau penggunaan alat pelindung diri (APD) secara rasional",

            value:
              this.limbah_medis.Waste_Reduction[
                "Pengingat_dan_pelatihan_tersedia_untuk_mempromosikan_dan_memantau_penggunaan_alat_pelindung_diri__APD__secara_rasional"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Strategi untuk mengurangi jumlah limbah yang dihasilkan digunakan di seluruh fasilitas termasuk pengadaan barang menggunakan lebih sedikit kemasan dan kemasan yang lebih berkelanjutan",

            value:
              this.limbah_medis.Waste_Reduction[
                "Strategi_untuk_mengurangi_jumlah_limbah_yang_dihasilkan_digunakan_di_seluruh_fasilitas_termasuk_pengadaan_barang_menggunakan_lebih_sedikit_kemasan_dan_kemasan_yang_lebih_berkelanjutan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Limbah tidak berbahaya yang dapat didaur ulang dipisahkan dan dikirim ke pabrik daur ulang kota",

            value:
              this.limbah_medis.Waste_Reduction[
                "Limbah_tidak_berbahaya_yang_dapat_didaur_ulang_dipisahkan_dan_dikirim_ke_pabrik_daur_ulang_kota"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Tersedia area penyimpanan limbah khusus yang dipagari dan aman dan dengan kapasitas yang cukup di mana limbah tajam infeksius dan tidak menular disimpan secara terpisah",

            value:
              this.limbah_medis.Storage[
                "Tersedia_area_penyimpanan_limbah_khusus_yang_dipagari_dan_aman_dan_dengan_kapasitas_yang_cukup_di_mana_limbah_tajam_infeksius_dan_tidak_menular_disimpan_secara_terpisah"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Limbah infeksius disimpan tidak lebih lama dari batas aman (sebagaimana ditentukan oleh iklim) sebelum pengolahan pembuangan",

            value:
              this.limbah_medis.Storage[
                "Limbah_infeksius_disimpan_tidak_lebih_lama_dari_batas_aman__sebagaimana_ditentukan_oleh_iklim__sebelum_pengolahan_pembuangan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Teknologi pengolahan limbah (incinerator atau teknologi pengolahan alternatif) untuk pengolahan limbah infeksius dan tajam dibangun dengan standar yang sesuai terpelihara dengan baik fungsional dan memiliki kapasitas yang cukup untuk limbah yang dihasilkan",

            value:
              this.limbah_medis.Treatment[
                "Teknologi_pengolahan_limbah__incinerator_atau_teknologi_pengolahan_alternatif__untuk_pengolahan_limbah_infeksius_dan_tajam_dibangun_dengan_standar_yang_sesuai_terpelihara_dengan_baik_fungsional_dan_memiliki_kapasitas_yang_cukup_untuk_limbah_yang_dihasilkan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Limbah dikumpulkan untuk pengolahan di luar lokasi dengan aman dan teratur dan dikirim ke fasilitas pengolahan limbah berlisensi yang sesuai",

            value:
              this.limbah_medis.Treatment[
                "Limbah_dikumpulkan_untuk_pengolahan_di_luar_lokasi_dengan_aman_dan_teratur_dan_dikirim_ke_fasilitas_pengolahan_limbah_berlisensi_yang_sesuai"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Lubang pemakaman fungsional tempat pembuangan limbah berpagar atau penjemputan kota tersedia untuk pembuangan limbah tidak menular (tidak berbahaya umum)",

            value:
              this.limbah_medis.Disposal[
                "Lubang_pemakaman_fungsional_tempat_pembuangan_limbah_berpagar_atau_penjemputan_kota_tersedia_untuk_pembuangan_limbah_tidak_menular__tidak_berbahaya_umum_"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Lubang limbah dibangun untuk menahan peristiwa dan keadaan darurat terkait iklim (misalnya banjir) dan atau tempat penyimpanan limbah cadangan tersedia",

            value:
              this.limbah_medis.Disposal[
                "Lubang_limbah_dibangun_untuk_menahan_peristiwa_dan_keadaan_darurat_terkait_iklim__misalnya_banjir__dan_atau_tempat_penyimpanan_limbah_cadangan_tersedia"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Lubang abu khusus tersedia untuk membuang abu dari pembakaran",

            value:
              this.limbah_medis.Disposal[
                "Lubang_abu_khusus_tersedia_untuk_membuang_abu_dari_pembakaran"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Limbah anatomi patologis dimasukkan ke dalam lubang limbah patologis khusus dibakar di krematorium atau dikubur di kuburan",

            value:
              this.limbah_medis.Disposal[
                "Limbah_anatomi_patologis_dimasukkan_ke_dalam_lubang_limbah_patologis_khusus_dibakar_di_krematorium_atau_dikubur_di_kuburan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Limbah farmasi diolah dan dibuang dengan aman baik di fasilitas pengolahan dan pembuangan aman yang dikelola secara terpusat (yaitu di luar lokasi) dengan mengirim kembali ke pabrikan atau dengan pembakaran oleh industri yang menggunakan kiln suhu tinggi",

            value:
              this.limbah_medis.Pharmaceutical_Waste[
                "Limbah_farmasi_diolah_dan_dibuang_dengan_aman_baik_di_fasilitas_pengolahan_dan_pembuangan_aman_yang_dikelola_secara_terpusat__yaitu_di_luar_lokasi__dengan_mengirim_kembali_ke_pabrikan_atau_dengan_pembakaran_oleh_industri_yang_menggunakan_kiln_suhu_tinggi"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Seorang staf dilatih secara memadai untuk pengelolaan dan pengawasan limbah perawatan kesehatan dan melaksanakan tugas mereka dengan standar profesional yang sesuai",

            value:
              this.limbah_medis.Personnel[
                "Seorang_staf_dilatih_secara_memadai_untuk_pengelolaan_dan_pengawasan_limbah_perawatan_kesehatan_dan_melaksanakan_tugas_mereka_dengan_standar_profesional_yang_sesuai"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Staf yang menangani atau membuang limbah dan petugas perawatan kesehatan divaksinasi hepatitis B (dan memiliki vaksinasi lain yang direkomendasikan sesuai dengan pedoman nasional)",

            value:
              this.limbah_medis.Personnel[
                "Staf_yang_menangani_atau_membuang_limbah_dan_petugas_perawatan_kesehatan_divaksinasi_hepatitis_B__dan_memiliki_vaksinasi_lain_yang_direkomendasikan_sesuai_dengan_pedoman_nasional_"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Strategi untuk menangani limbah tambahan digunakan ketika permintaan meningkat",

            value:
              this.limbah_medis.Emergency_Preparedness[
                "Strategi_untuk_menangani_limbah_tambahan_digunakan_ketika_permintaan_meningkat"
              ][0].dataPenyusun_desc,
          },

          // FORM 4
          {
            name: "Sarana kebersihan tangan yang berfungsi tersedia di semua titik perawatan termasuk di ruang bersalin",

            value:
              this.tangan_bersih.Availability[
                "Sarana_kebersihan_tangan_yang_berfungsi_tersedia_di_semua_titik_perawatan_termasuk_di_ruang_bersalin"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Sarana kebersihan tangan yang berfungsi tersedia di semua ruang tunggu dan area umum lainnya dan di area pembuangan limbah",

            value:
              this.tangan_bersih.Availability[
                "Sarana_kebersihan_tangan_yang_berfungsi_tersedia_di_semua_ruang_tunggu_dan_area_umum_lainnya_dan_di_area_pembuangan_limbah"
              ][0].dataPenyusun_desc,
          },
          {
            name: "(Semua toilet memiliki tempat cuci tangan yang berfungsi dalam jarak 5 meter)",

            value:
              this.tangan_bersih.Availability[
                "_Semua_toilet_memiliki_tempat_cuci_tangan_yang_berfungsi_dalam_jarak_5_meter_"
              ][0].dataPenyusun_desc,
          },

          {
            name: "Materi promosi kebersihan tangan ditampilkan dan terlihat jelas di semua bangsal area perawatan",

            value:
              this.tangan_bersih.Hygiene_promotion[
                "Materi_promosi_kebersihan_tangan_ditampilkan_dan_terlihat_jelas_di_semua_bangsal_area_perawatan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Kegiatan kepatuhan kebersihan tangan dilakukan secara teratur (setidaknya setiap tahun)",

            value:
              this.tangan_bersih.Hygiene_Compliance[
                "Kegiatan_kepatuhan_kebersihan_tangan_dilakukan_secara_teratur__setidaknya_setiap_tahun_"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Audit rutin (setidaknya setiap tiga bulan) berbasis lingkungan dilakukan untuk menilai ketersediaan hand rub sabun handuk sekali pakai dan sumber daya kebersihan tangan lainnya",

            value:
              this.tangan_bersih.Supplies[
                "Audit_rutin__setidaknya_setiap_tiga_bulan__berbasis_lingkungan_dilakukan_untuk_menilai_ketersediaan_hand_rub_sabun_handuk_sekali_pakai_dan_sumber_daya_kebersihan_tangan_lainnya"
              ][0].dataPenyusun_desc,
          },

          // FORM 5
          {
            name: "Kebijakan atau protokol kebersihan fasyankes (atau lingkungan) yang jelas dan terperinci dipajang dengan jelas diterapkan dan dipantau",

            value:
              this.lingkung_bersih.Policies[
                "Kebijakan_atau_protokol_kebersihan_fasyankes__atau_lingkungan__yang_jelas_dan_terperinci_dipajang_dengan_jelas_diterapkan_dan_dipantau"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Catatan pembersihan tersedia untuk area perawatan pasien bangsal umum atau seluruh fasilitas dan ditandatangani oleh petugas kebersihan yang relevan setiap hari",

            value:
              this.lingkung_bersih.Monitoring[
                "Catatan_pembersihan_tersedia_untuk_area_perawatan_pasien_bangsal_umum_atau_seluruh_fasilitas_dan_ditandatangani_oleh_petugas_kebersihan_yang_relevan_setiap_hari"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Toilet dibersihkan setidaknya sekali setiap hari dan catatan pembersihan ditandatangani oleh petugas kebersihan dan ditampilkan secara nyata",

            value:
              this.lingkung_bersih.Monitoring[
                "Toilet_dibersihkan_setidaknya_sekali_setiap_hari_dan_catatan_pembersihan_ditandatangani_oleh_petugas_kebersihan_dan_ditampilkan_secara_nyata"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Jumlah staf kebersihan yang diperlukan atau staf dengan tanggung jawab pembersihan tersedia di bangsal fasilitas setiap hari atau ketika pembersihan diperlukan dan memiliki waktu yang didedikasikan untuk melakukan kegiatan pembersihan",

            value:
              this.lingkung_bersih.Personnel[
                "Jumlah_staf_kebersihan_yang_diperlukan_atau_staf_dengan_tanggung_jawab_pembersihan_tersedia_di_bangsal_fasilitas_setiap_hari_atau_ketika_pembersihan_diperlukan_dan_memiliki_waktu_yang_didedikasikan_untuk_melakukan_kegiatan_pembersihan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Semua staf yang bertanggung jawab untuk kebersihan telah menerima pelatihan tentang kebersihan",

            value:
              this.lingkung_bersih.Personnel[
                "Semua_staf_yang_bertanggung_jawab_untuk_kebersihan_telah_menerima_pelatihan_tentang_kebersihan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Kebijakan dan praktik untuk meningkatkan keselamatan kerja petugas kebersihan dan teknisi limbah perawatan kesehatan tersedia dan diterapkan",

            value:
              this.lingkung_bersih.Personnel[
                "Kebijakan_dan_praktik_untuk_meningkatkan_keselamatan_kerja_petugas_kebersihan_dan_teknisi_limbah_perawatan_kesehatan_tersedia_dan_diterapkan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Bahan yang sesuai dan terawat dengan baik misalnya deterjen pel ember untuk membersihkan berbagai area dan permukaan yang berbeda tersedia dan cukup",

            value:
              this.lingkung_bersih.Supplies[
                "Bahan_yang_sesuai_dan_terawat_dengan_baik_misalnya_deterjen_pel_ember_untuk_membersihkan_berbagai_area_dan_permukaan_yang_berbeda_tersedia_dan_cukup"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Anggaran tahunan untuk perlengkapan dan peralatan kebersihan lingkungan ada dan cukup untuk semua kebutuhan",

            value:
              this.lingkung_bersih.Supplies[
                "Anggaran_tahunan_untuk_perlengkapan_dan_peralatan_kebersihan_lingkungan_ada_dan_cukup_untuk_semua_kebutuhan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Area khusus untuk penyimpanan persiapan dan perawatan persediaan dan peralatan kebersihan ada area layanan pembersihan lingkungan dijaga kebersihannya dan dirawat dengan baik dan digunakan sesuai dengan tujuannya",

            value:
              this.lingkung_bersih.Supplies[
                "Area_khusus_untuk_penyimpanan_persiapan_dan_perawatan_persediaan_dan_peralatan_kebersihan_ada_area_layanan_pembersihan_lingkungan_dijaga_kebersihannya_dan_dirawat_dengan_baik_dan_digunakan_sesuai_dengan_tujuannya"
              ][0].dataPenyusun_desc,
          },
          {
            name: "APD yang memadai tersedia setiap saat dan dalam jumlah yang cukup untuk semua staf kebersihan",

            value:
              this.lingkung_bersih.Ppe[
                "APD_yang_memadai_tersedia_setiap_saat_dan_dalam_jumlah_yang_cukup_untuk_semua_staf_kebersihan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Staf tambahan (misalnya daftar nama) dan perlengkapan kebersihan tambahan tersedia untuk ditempatkan di fasilitas jika beban pasien meningkat",

            value:
              this.lingkung_bersih.Emergency_Preparedness[
                "Staf_tambahan__misalnya_daftar_nama__dan_perlengkapan_kebersihan_tambahan_tersedia_untuk_ditempatkan_di_fasilitas_jika_beban_pasien_meningkat"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Semua tempat tidur kasur memiliki penutup tahan air yang tanpa tanda tanda kerusakan robek sobek atau lubang",

            value:
              this.lingkung_bersih.Laundry[
                "Semua_tempat_tidur_kasur_memiliki_penutup_tahan_air_yang_tanpa_tanda_tanda_kerusakan_robek_sobek_atau_lubang"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Fasilitas binatu bersih terawat dengan baik dan mampu memenuhi permintaan (misalnya untuk mencuci linen dari tempat tidur pasien di antara setiap pasien)",

            value:
              this.lingkung_bersih.Laundry[
                "Fasilitas_binatu_bersih_terawat_dengan_baik_dan_mampu_memenuhi_permintaan__misalnya_untuk_mencuci_linen_dari_tempat_tidur_pasien_di_antara_setiap_pasien_"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Layanan binatu dengan air panas",

            value:
              this.lingkung_bersih.Laundry["Layanan_binatu_dengan_air_panas"][0]
                .dataPenyusun_desc,
          },
          {
            name: "Makanan disiapkan dan ditangani dengan aman (dengan tangan bersih pada permukaan bersih dan dengan peralatan bersih)",

            value:
              this.lingkung_bersih.Food_Hygiene[
                "Makanan_disiapkan_dan_ditangani_dengan_aman__dengan_tangan_bersih_pada_permukaan_bersih_dan_dengan_peralatan_bersih_"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Toko dapur dan makanan siap saji dilindungi dari lalat serangga dan tikus lainnya",

            value:
              this.lingkung_bersih.Food_Hygiene[
                "Toko_dapur_dan_makanan_siap_saji_dilindungi_dari_lalat_serangga_dan_tikus_lainnya"
              ][0].dataPenyusun_desc,
          },
          // FORM 6
          {
            name: "Fasyankes memiliki sumber listrik yang fungsional dan terawat dengan baik (misalnya jaringan listrik surya)",

            value:
              this.energi.Energy[
                "Fasyankes_memiliki_sumber_listrik_yang_fungsional_dan_terawat_dengan_baik__misalnya_jaringan_listrik_surya_"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Energi cukup untuk semua kebutuhan listrik fasilitas termasuk untuk penerangan dan perangkat yang berdiri sendiri (misalnya Program yang Diperluas pada rantai dingin Imunisasi)",

            value:
              this.energi.Energy[
                "Energi_cukup_untuk_semua_kebutuhan_listrik_fasilitas_termasuk_untuk_penerangan_dan_perangkat_yang_berdiri_sendiri__misalnya_Program_yang_Diperluas_pada_rantai_dingin_Imunisasi_"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Energi yang cukup tersedia untuk memompa air",

            value:
              this.energi.Energy[
                "Energi_yang_cukup_tersedia_untuk_memompa_air"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Energi yang cukup tersedia untuk memanaskan air",

            value:
              this.energi.Energy[
                "Energi_yang_cukup_tersedia_untuk_memanaskan_air"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Sumber energi cadangan fungsional (misalnya generator dengan bahan bakar yang memadai) ada jika sumber utama gagal",

            value:
              this.energi.Backup[
                "Sumber_energi_cadangan_fungsional__misalnya_generator_dengan_bahan_bakar_yang_memadai__ada_jika_sumber_utama_gagal"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Pencahayaan hemat energi digunakan dengan kontrol pencahayaan yang ditingkatkan dan lampu hemat energi",

            value:
              this.energi.Efficiency[
                "Pencahayaan_hemat_energi_digunakan_dengan_kontrol_pencahayaan_yang_ditingkatkan_dan_lampu_hemat_energi"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Ruang bersalin cukup terang termasuk di malam hari",

            value:
              this.energi.Adequacy[
                "Ruang_bersalin_cukup_terang_termasuk_di_malam_hari"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Kamar mandi cukup terang termasuk di malam hari",

            value:
              this.energi.Adequacy[
                "Kamar_mandi_cukup_terang_termasuk_di_malam_hari"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Jamban cukup terang termasuk di malam hari",

            value:
              this.energi.Adequacy[
                "Jamban_cukup_terang_termasuk_di_malam_hari"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Ventilasi lingkungan yang berfungsi cukup (alami atau mekanis) tersedia di area perawatan pasien",

            value:
              this.energi.Ventilation[
                "Ventilasi_lingkungan_yang_berfungsi_cukup__alami_atau_mekanis__tersedia_di_area_perawatan_pasien"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Tempat tidur memiliki kelambu yang dirawat insektisida untuk melindungi pasien dari penyakit bawaan nyamuk",

            value:
              this.energi.Vector_Control[
                "Tempat_tidur_memiliki_kelambu_yang_dirawat_insektisida_untuk_melindungi_pasien_dari_penyakit_bawaan_nyamuk"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Pengadaan berkelanjutan (menggunakan pendekatan siklus hidup) diterapkan di seluruh fasilitas",

            value:
              this.energi.Procurement[
                "Pengadaan_berkelanjutan__menggunakan_pendekatan_siklus_hidup__diterapkan_di_seluruh_fasilitas"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Tempat sampah umum tersedia di semua area publik",

            value:
              this.energi.Environment[
                "Tempat_sampah_umum_tersedia_di_semua_area_publik"
              ][0].dataPenyusun_desc,
          },

          // FORM 7
          {
            name: "Fasyankes memiliki tim peningkatan kualitas yang berfungsi IPC atau WASH FIT",

            value:
              this.kerja.Management[
                "Fasyankes_memiliki_tim_peningkatan_kualitas_yang_berfungsi_IPC_atau_WASH_FIT"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Fasyankes memiliki focal point atau tenaga teknis WASH khusus yang bekerja untuk program kerja yang disetujui dengan dukungan pimpinan senior",

            value:
              this.kerja.Personnel[
                "Fasyankes_memiliki_focal_point_atau_tenaga_teknis_WASH_khusus_yang_bekerja_untuk_program_kerja_yang_disetujui_dengan_dukungan_pimpinan_senior"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Kelompok perempuan disabilitas dan masyarakat adat serta pengguna dan staf khusus lainnya",

            value:
              this.kerja.Management[
                "Kelompok_perempuan_disabilitas_dan_masyarakat_adat_serta_pengguna_dan_staf_khusus_lainnya"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Diagram terbaru dari struktur manajemen fasilitas termasuk staf kebersihan terlihat jelas dan terbaca",

            value:
              this.kerja.Management[
                "Diagram_terbaru_dari_struktur_manajemen_fasilitas_termasuk_staf_kebersihan_terlihat_jelas_dan_terbaca"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Semua staf tambahan termasuk penangan limbah dan mereka yang membersihkan memiliki deskripsi pekerjaan yang jelas dan tertulis yang menguraikan tanggung jawab WASH dan IPC",

            value:
              this.kerja.Management[
                "Semua_staf_tambahan_termasuk_penangan_limbah_dan_mereka_yang_membersihkan_memiliki_deskripsi_pekerjaan_yang_jelas_dan_tertulis_yang_menguraikan_tanggung_jawab_WASH_dan_IPC"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Semua staf tambahan baru termasuk penangan limbah dan mereka yang membersihkan menerima pelatihan WASH dan IPC yang sesuai disesuaikan dengan fungsi pekerjaan mereka",

            value:
              this.kerja.Personnel[
                "Semua_staf_tambahan_baru_termasuk_penangan_limbah_dan_mereka_yang_membersihkan_menerima_pelatihan_WASH_dan_IPC_yang_sesuai_disesuaikan_dengan_fungsi_pekerjaan_mereka"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Staf secara teratur (setidaknya setiap tahun) dinilai berdasarkan kinerja mereka (misalnya kebersihan tangan)",

            value:
              this.kerja.Personnel[
                "Staf_secara_teratur__setidaknya_setiap_tahun__dinilai_berdasarkan_kinerja_mereka__misalnya_kebersihan_tangan_"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Protokol dan sistem yang efektif tersedia untuk operasi dan pemeliharaan infrastruktur yang berkelanjutan dan pengadaan persediaan yang diperlukan untuk operasi dan pemeliharaan",

            value:
              this.kerja.SOP[
                "Protokol_dan_sistem_yang_efektif_tersedia_untuk_operasi_dan_pemeliharaan_infrastruktur_yang_berkelanjutan_dan_pengadaan_persediaan_yang_diperlukan_untuk_operasi_dan_pemeliharaan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Anggaran tersedia untuk menutupi biaya pembersih dan staf pemeliharaan",

            value:
              this.kerja.Budget[
                "Anggaran_tersedia_untuk_menutupi_biaya_pembersih_dan_staf_pemeliharaan"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Kebijakan piagam keselamatan pasien di seluruh Fasyankes untuk meningkatkan kualitas perawatan ditulis terkini dan operasional",

            value:
              this.kerja.Policies[
                "Kebijakan_piagam_keselamatan_pasien_di_seluruh_Fasyankes_untuk_meningkatkan_kualitas_perawatan_ditulis_terkini_dan_operasional"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Kebijakan piagam ramah lingkungan di seluruh Fasyankes ditulis dan beroperasi",

            value:
              this.kerja.Policies[
                "Kebijakan_piagam_ramah_lingkungan_di_seluruh_Fasyankes_ditulis_dan_beroperasi"
              ][0].dataPenyusun_desc,
          },
          {
            name: "Rencana kesiapsiagaan dan tanggap darurat sudah ada dianggarkan dan diperbarui secara berkala",

            value:
              this.kerja.Emergency_Preparedness[
                "Rencana_kesiapsiagaan_dan_tanggap_darurat_sudah_ada_dianggarkan_dan_diperbarui_secara_berkala"
              ][0].dataPenyusun_desc,
          },
        ],
      };
      if (draft) {
        this.temporarySave(body);
      } else {
        this.saveFormulir(body);
      }
    },
  },
};
</script>

<style scoped>
.v-radio {
  align-items: start !important;
}

.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}

.header_tabs {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 20%;
  border-radius: 10px 10px 0 0;
}

.mini_tab {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 6%;
  border-radius: 10px 10px 0 0;
  border: 1px solid #ececec;
  border-bottom: none;
}

.active_tab {
  display: flex;
  justify-content: center;
  background: #00b4cc;
  width: 20%;
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}

.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}

.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}

.marlef {
  margin-right: 50px;
}

.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}

.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}

@media screen and (max-width: 600px) {
  .side_toolbar {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: end;
    z-index: 99 !important;
  }
  .item_toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px 40px 00px;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
  }
  .fasyankes .header_tab {
    width: 100% !important;
    max-width: 100%;
  }

  .fasyankes .v-tabs-bar,
  .fasyankes .v-slide-group__wrapper,
  .fasyankes .v-tabs-bar__content {
    width: 100%;
  }
  .active_tab {
    display: flex;
    justify-content: start;
    background: #00b4cc;
    width: 100%;
    color: #fff;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
  }
  .number_active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    color: black;
    font-size: small;
    margin-right: 3px;
  }
}
</style>