<template>
    <v-card flat color="#F6F6F6" class="rounded-lg pa-3 mt-2">
      <b>{{ title }}</b>
      <p v-html="description" class="ma-0 color_txt small_txt">
        
      </p>
      <v-row>
          <v-tooltip
            top
            v-model="tooltip_selected"
            max-width="100%"
          >
            <template v-slot:activator="{ on }">
              <div class="d-flex flex-row pa-2 mt-2 ml-1">
                <div
                  class="d-flex pa-0 align-center"
                  elevation="0"
                  min-width="0"
                  height="0"
                  v-on="on"
                >
                  <img
                    width="18"
                    height="18"
                    src="../../../../assets/img/info-icon.svg"
                    alt=""
                  />
                </div>
                <span
                  class="d-flex align-center ml-1 color_txt small_txt text-capitalize"
                  >informasi</span
                >
              </div>
            </template>
            <span v-html="tooltip_desc" class="small_txt"
              ></span
            >
          </v-tooltip>
      </v-row>

      <div class="d-flex w-100">
       
        <v-radio-group
            v-model="local_selected"
            dense
            class="justify-start"
        >
            <v-row class="d-flex flex-column">
                <v-col v-for="option in options" :key="option.label">
                    <v-card
                    class="pa-3 rounded-max"
                    height="100%"
                    outlined
                    >
                        <v-radio
                            
                            :label="option.label"
                            color="#00ccb6"
                            :value="option.value"
                        ></v-radio>
                    </v-card>
                </v-col>
            </v-row>
        </v-radio-group>
      </div>
    </v-card>
</template>
<script>
export default {
  name: "QuestionCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    selected: {},
    tooltipSelected: {},
    tooltip_desc: {
      type: String,
      default: "",}
  },
  data() {
    return {
        local_selected: this.selected,
        tooltip_selected: this.tooltipSelected,
    }
  },
  watch: {
    local_selected(value) {
      this.$emit("update:selected", value);
    },
  },
};
</script>